import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig, NbToastrService,
} from '@nebular/theme';
import {Employee} from '../../../models/Employee';
import {EmployeeData} from '../../../models/EmployeeData';
import {Department} from '../../../models/Department';
import {ExportService} from '../../../../../shared/exports/export.service';
import {ActivatedRoute} from '@angular/router';
import {EmployeeService} from '../../../services/employeeServices/employee.service';
import {DepartmentService} from '../../../services/departmentservices/department.service';

@Component({
  selector: 'ngx-employees-sub-department',
  templateUrl: './employees-sub-department.component.html',
  styleUrls: ['./employees-sub-department.component.scss'],
})
export class EmployeesSubDepartmentComponent implements OnInit {

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  current: number = 1;

  config: NbToastrConfig;
  title = 'Create Department';
  content = 'Operation achieved, reload your page';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;

  list: Employee[] = [];
  employees: EmployeeData[] = [];
  listd: Department[] = [];
  countries: any [] = [];


  pageSize: number = 4;

  NotAssignedYet: string = 'Not Assigned Yet.';

  searchbyfields: string;
  total: string | number;
  loading = false;
  subDepartmentId: string;
  departmentMap: Map<string, string> = new Map(); // Map to store department IDs and names


  constructor(private exportService: ExportService,
              private route: ActivatedRoute,
              private es: EmployeeService, private ds: DepartmentService,
              private toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.loading = true; // Set loading to true at the beginning
    this.route.params.subscribe({
      next: params => {
        this.subDepartmentId = params['subDepartmentId'];
        console.log('SubDepartment ID:', this.subDepartmentId);
        // If subDepartmentId is not defined or conversion to number results in NaN, do not proceed.
        if (!this.subDepartmentId) {
          console.error('No SubDepartment ID provided');
          this.loading = false;
          return;
        }
        // Fetch department data and load employees by sub-department
        this.fetchDepartmentData().then(() => this.loadEmployeesBySubDepartment(this.subDepartmentId));

      },

      error: err => {
        console.error('Error with route parameters:', err);
        this.loading = false;
      },
    });
  }

  fetchDepartmentData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.ds.findAllDepts().subscribe(
        (data: Department[]) => {
          data.forEach(department => {
            this.departmentMap.set(department.id, department.departmentName);
          });
          resolve();
        },
        error => {
          console.error('Error fetching departments:', error);
          this.toastrService.show(
            'Error fetching departments: ' + error,
            'Error',
            { status: 'danger', duration: this.duration },
          );
          reject();
        },
      );
    });
  }

  loadEmployeesBySubDepartment(subDepartmentId: string) {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        console.log('All Employees:', data);  // Log all data
        this.list = data.filter(employee => employee.subDepartment && employee.subDepartment.id === subDepartmentId && employee.roleEmployee === 'Employee');
        console.log('Filtered Departments:', this.list);  // Log filtered data
        this.loading = false;

      },
      error => {
        console.error('Error fetching departments:', error);
        this.loading = false;
        this.toastrService.show(
          'Error fetching departments: ' + error,
          'Error',
          { status: 'danger', duration: this.duration },
        );
      },
    );
  }
  /*loadEmployeesBySubDepartment(subDepartmentId: string) {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        console.log('All Employees:', data);  // Log all data
        this.list = data.filter(employee => {
          const matches = employee.subDepartment && employee.subDepartment.id === subDepartmentId && employee.roleEmployee === 'Employee';
          if (matches && employee.subDepartment && employee.subDepartment.department) {
            employee.subDepartment.department = this.departmentMap.get(employee.subDepartment.department);
          }
          return matches;
        });
        console.log('Filtered SubDepartments:', this.list);  // Log filtered data
        this.loading = false;

      },
      error => {
        console.error('Error fetching subdepartments:', error);
        this.loading = false;
        this.toastrService.show(
          'Error fetching subdepartments: ' + error,
          'Error',
          { status: 'danger', duration: this.duration },
        );
      },
    );
  }*/




  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.list, 'dataEmployees');
  }


}
