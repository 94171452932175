import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-showcase-dialog',
  templateUrl: 'showcase-dialog.component.html',
  styleUrls: ['showcase-dialog.component.scss'],
})
export class ShowcaseDialogComponent {

  @Input() title: string;
  @Input() message: string;

  constructor(protected ref: NbDialogRef<ShowcaseDialogComponent>,
              private router: Router) {}

  dismiss() {
    window.location.href = 'https://manajero.com';
  }
}
