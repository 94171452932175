<!-- Include CSS for Select2 -->
<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0/dist/css/select2.min.css" rel="stylesheet" />

<!-- Include jQuery and Select2 JS -->
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0/dist/js/select2.min.js"></script>

<div class="row">
  <div class="col-md-4">
    <h3 style="margin-bottom: 50px;">Competency matrix</h3>
  </div>
</div>
<table class="table table-bordered table-align">
  <thead>
  <tr>
    <th ></th>
    <th style="color: #315fe9" colspan="8">Employees</th>
  </tr>
  </thead>
  <!-- Existing table setup -->

  <tbody>
  <tr>
    <th style="color: #315fe9;">Actions</th>
    <th ></th>
    <!-- Générez chaque colonne de matrice -->
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i" class="edit-container">
        <i class="fas fa-edit edit-icon" aria-label="Edit" style="cursor: pointer; margin-right: 30px;" (click)="startEditing(i)"></i>
        <i class="fas fa-trash text-danger" aria-label="Delete" style="cursor: pointer; margin-top: 2px;
    margin-right: -40px; " (click)="open2(dialog)" ></i>
        <ng-template #dialog let-data let-ref="dialogRef">
          <nb-card>
            <nb-card-header>Confirm Delete Operation</nb-card-header>
            <nb-card-body>Are you sure you want to delete the competency matrix of {{matrice.employee}}</nb-card-body>
            <nb-card-footer>
              <div class="row">
                <button class="col-md-2" nbButton (click)="ref.close()">Close</button>
                <div class="col-md-8"></div>
                <a class="col-md-2" status="danger"   nbButton (click)="deleteMatrice(matrice, i); ref.close();">Delete</a>
              </div>
            </nb-card-footer>
          </nb-card>
        </ng-template>
      </div>
      <div *ngIf="editingIndex === i">
        <i class="fas fa-times edit-icon" aria-label="Cancel" style="color: red;" (click)="cancelEditing()"></i>
      </div>
    </th>
  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf;">Nom & Prénom</th>
    <!-- Générez chaque colonne de matrice -->
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i" class="edit-container">
        <div class="employee-name">{{ matrice.employee }}</div>
      </div>
      <div *ngIf="editingIndex === i">
        <select class="form-control" [(ngModel)]="matrices[i].employee">
          <option *ngFor="let employee of list" [value]="employee.employeeName">
            {{ employee.employeeName }}
          </option>
        </select>
      </div>
    </th>
    <th>
    <select class="form-control" [(ngModel)]="matriceData.employee">
      <option *ngFor="let employee of list" [value]="employee.employeeName">
        {{ employee.employeeName }}
      </option>
    </select>
    </th>
  </tr>


  <tr>
    <th></th>
    <th style="color: #70abcf;">Département</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.department }}
      </div>
      <div *ngIf="editingIndex === i">
        <select class="form-control" [(ngModel)]="matrices[i].department">
          <option *ngFor="let department of listD" [value]="department.departmentName">
            {{ department.departmentName }}
          </option>
        </select>
      </div>
    </th>
    <th>
      <select class="form-control" [(ngModel)]="matriceData.department">
        <option *ngFor="let department of listD" [value]="department.departmentName">
          {{ department.departmentName }}
        </option>
      </select>
    </th>
  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf;">Type de Contrat</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.contractType }}
      </div>
      <div *ngIf="editingIndex === i">
        <select class="form-control" [(ngModel)]="matrices[i].contractType">
          <option *ngFor="let contract of contracts" [value]="contract.contractType">
            {{ contract.contractType }}
          </option>
        </select>
      </div>
    </th>
    <th>
      <select class="form-control" [(ngModel)]="matriceData.contractType">
        <option *ngFor="let contract of contracts" [value]="contract.contractType">
          {{ contract.contractType }}
        </option>
      </select>
    </th>
  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf;">Niveau de Collaboration</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.niveauCollaboration }}
      </div>
      <div *ngIf="editingIndex === i">
        <select [(ngModel)]="matrices[i].niveauCollaboration" [ngStyle]="selectStyless[i]" (change)="onSelectionChangeNiveau($event.target.value, i)" class="form-control">
          <option value="Niveau 1" style="background-color: #008000; color: white;">Niveau 1</option>
          <option value="Niveau 0" style="background-color: #FF8C00; color: black;">Niveau 0</option>
          <option value="Niveau -1" style="background-color: rgb(183,48,48); color: black;">Niveau -1</option>
        </select>
      </div>
    </th>
    <th>
      <select [(ngModel)]="matriceData.niveauCollaboration" [ngStyle]="selectStyless[matrices.length]" (change)="onSelectionChangeNiveau($event.target.value, matrices.length)" class="form-control">
        <option value="Niveau 1" style="background-color: #008000; color: white;">Niveau 1</option>
        <option value="Niveau 0" style="background-color: #FF8C00; color: black;">Niveau 0</option>
        <option value="Niveau -1" style="background-color: rgb(183,48,48); color: black;">Niveau -1</option>
      </select>
  </tr>
    <th ></th>
    <th style="color: #315fe9" colspan="8">Compétences et expérience</th>
  <tr>
    <th style="color: #c5b5a2" scope="row">Département Administratif et Fiancier</th>
    <th style="color: #70abcf;" scope="row">Gestion Administrative</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.competence1 }}
      </div>
      <div *ngIf="editingIndex === i">
        <nb-radio-group [name]="'competence'" [(ngModel)]="matrices[i].competence1">
          <nb-radio value="Mauvaise" [class.selected-heat]="matrices[i].competence1 === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Moyenne" [class.selected-warm]="matrices[i].competence1 === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Élevée" [class.selected-cool]="matrices[i].competence1 === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
            <i class="custom-circle"></i>
          </nb-radio>
        </nb-radio-group>
      </div>
    </th>
    <th>
      <nb-radio-group [name]="'competence'" [(ngModel)]="matriceData['competence1']">
        <nb-radio value="Mauvaise" [class.selected-heat]="matriceData['competence1'] === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Moyenne" [class.selected-warm]="matriceData['competence1'] === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Élevée" [class.selected-cool]="matriceData['competence1'] === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
          <i class="custom-circle"></i>
        </nb-radio>
      </nb-radio-group>
  </tr>

  <tr>
    <th></th>
    <th style="color: #70abcf;" scope="row">Secretariat</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.competence2 }}
      </div>
      <div *ngIf="editingIndex === i">
        <nb-radio-group [name]="'competence'" [(ngModel)]="matrices[i].competence2">
          <nb-radio value="Mauvaise" [class.selected-heat]="matrices[i].competence2 === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Moyenne" [class.selected-warm]="matrices[i].competence2 === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Élevée" [class.selected-cool]="matrices[i].competence2 === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
            <i class="custom-circle"></i>
          </nb-radio>
        </nb-radio-group>
      </div>
    </th>
    <th>
      <nb-radio-group [name]="'competence'" [(ngModel)]="matriceData['competence2']">
        <nb-radio value="Mauvaise" [class.selected-heat]="matriceData['competence2'] === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Moyenne" [class.selected-warm]="matriceData['competence2'] === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Élevée" [class.selected-cool]="matriceData['competence2'] === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
          <i class="custom-circle"></i>
        </nb-radio>
      </nb-radio-group>
    </th>
  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf;" scope="row">Gestion Financiere</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.competence3 }}
      </div>
      <div *ngIf="editingIndex === i">
        <nb-radio-group [name]="'competence'" [(ngModel)]="matrices[i].competence3">
          <nb-radio value="Mauvaise" [class.selected-heat]="matrices[i].competence3 === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Moyenne" [class.selected-warm]="matrices[i].competence3 === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Élevée" [class.selected-cool]="matrices[i].competence3 === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
            <i class="custom-circle"></i>
          </nb-radio>
        </nb-radio-group>
      </div>
    </th>
    <th>
      <nb-radio-group [name]="'competence'" [(ngModel)]="matriceData['competence3']">
        <nb-radio value="Mauvaise" [class.selected-heat]="matriceData['competence3'] === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Moyenne" [class.selected-warm]="matriceData['competence3'] === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Élevée" [class.selected-cool]="matriceData['competence3'] === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
          <i class="custom-circle"></i>
        </nb-radio>
      </nb-radio-group>
    </th>
  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf;" scope="row">Gestion des Ressources Humaines</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.competence4 }}
      </div>
      <div *ngIf="editingIndex === i">
        <nb-radio-group [name]="'competence'" [(ngModel)]="matrices[i].competence4">
          <nb-radio value="Mauvaise" [class.selected-heat]="matrices[i].competence4 === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Moyenne" [class.selected-warm]="matrices[i].competence4 === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Élevée" [class.selected-cool]="matrices[i].competence4 === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
            <i class="custom-circle"></i>
          </nb-radio>
        </nb-radio-group>
      </div>
    <th>
      <nb-radio-group [name]="'competence'" [(ngModel)]="matriceData['competence4']">
        <nb-radio value="Mauvaise" [class.selected-heat]="matriceData['competence4'] === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Moyenne" [class.selected-warm]="matriceData['competence4'] === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Élevée" [class.selected-cool]="matriceData['competence4'] === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
          <i class="custom-circle"></i>
        </nb-radio>
      </nb-radio-group>
    </th>
  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf;" scope="row">Comptabilité</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.competence5 }}
      </div>
      <div *ngIf="editingIndex === i">
        <nb-radio-group [name]="'competence'" [(ngModel)]="matrices[i].competence5">
          <nb-radio value="Mauvaise" [class.selected-heat]="matrices[i].competence5 === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Moyenne" [class.selected-warm]="matrices[i].competence5 === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Élevée" [class.selected-cool]="matrices[i].competence5 === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
            <i class="custom-circle"></i>
          </nb-radio>
        </nb-radio-group>
      </div>
    </th>
    <th>
      <nb-radio-group [name]="'competence'" [(ngModel)]="matriceData['competence5']">
        <nb-radio value="Mauvaise" [class.selected-heat]="matriceData['competence5'] === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Moyenne" [class.selected-warm]="matriceData['competence5'] === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Élevée" [class.selected-cool]="matriceData['competence5'] === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
          <i class="custom-circle"></i>
        </nb-radio>
      </nb-radio-group>
    </th>
  </tr>
  <tr>
    <th style="color: #c5b5a2" scope="row">Département Téchnique</th>
    <th style="color: #70abcf;" scope="row">Maitrise Technique</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.competence6 }}
      </div>
      <div *ngIf="editingIndex === i">
        <nb-radio-group [name]="'competence'" [(ngModel)]="matrices[i].competence6">
          <nb-radio value="Mauvaise" [class.selected-heat]="matrices[i].competence6 === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Moyenne" [class.selected-warm]="matrices[i].competence6 === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Élevée" [class.selected-cool]="matrices[i].competence6 === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
            <i class="custom-circle"></i>
          </nb-radio>
        </nb-radio-group>
      </div>
    </th>
    <th>
      <nb-radio-group [name]="'competence'" [(ngModel)]="matriceData['competence6']">
        <nb-radio value="Mauvaise" [class.selected-heat]="matriceData['competence6'] === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Moyenne" [class.selected-warm]="matriceData['competence6'] === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Élevée" [class.selected-cool]="matriceData['competence6'] === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
          <i class="custom-circle"></i>
        </nb-radio>
      </nb-radio-group>
    </th>
  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf;" scope="row">Maintenance</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.competence7 }}
      </div>
      <div *ngIf="editingIndex === i">
        <nb-radio-group [name]="'competence'" [(ngModel)]="matrices[i].competence7">
          <nb-radio value="Mauvaise" [class.selected-heat]="matrices[i].competence7 === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Moyenne" [class.selected-warm]="matrices[i].competence7 === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Élevée" [class.selected-cool]="matrices[i].competence7 === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
            <i class="custom-circle"></i>
          </nb-radio>
        </nb-radio-group>
      </div>
    </th>
    <th>
      <nb-radio-group [name]="'competence'" [(ngModel)]="matriceData['competence7']">
        <nb-radio value="Mauvaise" [class.selected-heat]="matriceData['competence7'] === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Moyenne" [class.selected-warm]="matriceData['competence7'] === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Élevée" [class.selected-cool]="matriceData['competence7'] === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
          <i class="custom-circle"></i>
        </nb-radio>
      </nb-radio-group>
    </th>

  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf;" scope="row">Reparation de Base</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.competence8 }}
      </div>
      <div *ngIf="editingIndex === i">
        <nb-radio-group [name]="'competence'" [(ngModel)]="matrices[i].competence8">
          <nb-radio value="Mauvaise" [class.selected-heat]="matrices[i].competence8 === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Moyenne" [class.selected-warm]="matrices[i].competence8 === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
            <i class="custom-circle"></i>
          </nb-radio>
          <nb-radio value="Élevée" [class.selected-cool]="matrices[i].competence8 === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
            <i class="custom-circle"></i>
          </nb-radio>
        </nb-radio-group>
      </div>
    </th>
    <th>
      <nb-radio-group [name]="'competence'" [(ngModel)]="matriceData['competence8']">
        <nb-radio value="Mauvaise" [class.selected-heat]="matriceData['competence8'] === 'Mauvaise'" [nbTooltip]="'Mauvaise'" placement="top" nbTooltipStatus="danger">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Moyenne" [class.selected-warm]="matriceData['competence8'] === 'Moyenne'" [nbTooltip]="'Moyenne'" placement="top" nbTooltipStatus="warning">
          <i class="custom-circle"></i>
        </nb-radio>
        <nb-radio value="Élevée" [class.selected-cool]="matriceData['competence8'] === 'Élevée'" [nbTooltip]="'Élevée'" placement="top" nbTooltipStatus="success">
          <i class="custom-circle"></i>
        </nb-radio>
      </nb-radio-group>
    </th>

  </tr>
  <tr>
  </tr>
  <tr>
  <th ></th>
  <th style="color: #315fe9" colspan="8">Renseignements démographiques</th>
  <tr>
  <tr>
    <th ></th>
    <th style="color: #70abcf" colspan="8">Genre</th>
  <tr>
  <tr>
    <th></th>
    <th style="color: #70abcf" scope="row">Masculin</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        <ng-container *ngIf="matrice.genre === 'Masculin'">{{ matrice.genre }}</ng-container>
      </div>
      <div *ngIf="editingIndex === i">
        <div class="form-check form-check-inline">
          <!-- Ensure consistent naming for the group, independent of the index -->
          <input class="form-check-input" type="radio" id="inlineRadio10" name="genderMasculin" value="Masculin" [(ngModel)]="matrices[i].genre">
        </div>
      </div>
    </th>
    <th>
      <!-- This seems to be a global control, ensure it has a unique name if separate from the rows -->
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="inlineRadio1" name="globalGenderMasculin" value="Masculin" [(ngModel)]="matriceData.genre">
      </div>
    </th>
  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf" scope="row">Féminin</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        <ng-container *ngIf="matrice.genre === 'Féminin'">{{ matrice.genre }}</ng-container>
      </div>
      <div *ngIf="editingIndex === i">
        <div class="form-check form-check-inline">
          <!-- Ensure consistent naming for the group, independent of the index -->
          <input class="form-check-input" type="radio" id="inlineRadio9" name="genderFeminin" value="Féminin" [(ngModel)]="matrices[i].genre">
        </div>
      </div>
    </th>
    <th>
      <!-- This seems to be a global control, ensure it has a unique name if separate from the rows -->
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="globalGenderFeminin" id="inlineRadio2" value="Féminin" [(ngModel)]="matriceData.genre">
      </div>
    </th>
  </tr>

  <tr>
    <th></th>
    <th style="color: #70abcf" colspan="8">Âge</th>
  <tr>
  <tr>
    <th></th>
    <th style="color: #70abcf" scope="row">De 18 à 30</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        <ng-container *ngIf="matrice.age === '18 à 30'">{{ matrice.age }}</ng-container>
      </div>
      <div class="form-check form-check-inline" *ngIf="editingIndex === i">
        <input class="form-check-input" type="radio" name="ageGroup" value="18 à 30" [(ngModel)]="matrices[i].age">
      </div>
    </th>
    <th>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="inlineRadio3" value="18 à 30" [(ngModel)]="matriceData.age">
      </div>
    </th>
  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf" scope="row">De 31 à 40</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        <ng-container *ngIf="matrice.age === '31 à 40'">{{ matrice.age }}</ng-container>
      </div>
      <div class="form-check form-check-inline" *ngIf="editingIndex === i">
        <input class="form-check-input" type="radio" name="ageGroup" value="31 à 40" [(ngModel)]="matrices[i].age">
      </div>
    </th>
    <th>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="inlineRadio4" value="31 à 40" [(ngModel)]="matriceData.age">
      </div>
    </th>

  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf" scope="row">De 41 à 50</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        <ng-container *ngIf="matrice.age === '41 à 50'">{{ matrice.age }}</ng-container>
      </div>
      <div class="form-check form-check-inline" *ngIf="editingIndex === i">
        <input class="form-check-input" type="radio" name="ageGroup" value="41 à 50" [(ngModel)]="matrices[i].age">
      </div>
    </th>
    <th>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="inlineRadio5" value="41 à 50" [(ngModel)]="matriceData.age">
      </div>
    </th>

  </tr> <tr>
    <th></th>
    <th style="color: #70abcf" scope="row">51 et plus</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        <ng-container *ngIf="matrice.age === '51 et plus'">{{ matrice.age }}</ng-container>
      </div>
      <div class="form-check form-check-inline" *ngIf="editingIndex === i">
        <input class="form-check-input" type="radio" name="ageGroup" value="51 et plus" [(ngModel)]="matrices[i].age">
      </div>
    </th>
    <th>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="inlineRadio6" value="51 et plus" [(ngModel)]="matriceData.age">
      </div>
    </th>
  </tr>
  <tr>
    <th ></th>
    <th style="color: #70abcf" colspan="8">Rénumération</th>
  <tr>
  <tr>
    <th></th>
    <th style="color: #70abcf" scope="row">Mensuelle ou par Commission</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.renumeration }}
      </div>
      <div *ngIf="editingIndex === i">
        <select class="form-control" [(ngModel)]="matrices[i].renumeration">
          <option value="Mensuelle">Mensuelle</option>
          <option value="Par Commission">Par Commission</option>
        </select>
      </div>
    </th>
    <th><select class="form-control" [(ngModel)]="matriceData.renumeration">
      <option value="Mensuelle">Mensuelle</option>
      <option value="Par Commission">Par Commission</option>
    </select></th>
  </tr>
  <tr>
    <th></th>
    <th style="color: #70abcf" scope="row">Salaire Inférieur ou Supérieur au SMIG</th>
    <th style="color: white;" *ngFor="let matrice of matrices; let i = index">
      <div *ngIf="editingIndex !== i">
        {{ matrice.salaire }}
      </div>
      <div *ngIf="editingIndex === i">
        <select class="form-control" [(ngModel)]="matrices[i].salaire">
          <option value="Supérieur ou égal au SMIG">Supérieur ou égal au SMIG</option>
          <option value="Inférieur au SMIG">Inférieur au SMIG</option>
        </select>
      </div>
    </th>
    <th><select class="form-control" [(ngModel)]="matriceData.salaire">
      <option value="Supérieur ou égal au SMIG">Supérieur ou égal au SMIG</option>
      <option value="Inférieur au SMIG">Inférieur au SMIG</option>
    </select></th>
  </tr>
  </tbody>
</table>
<div class="row ">
  <div *ngFor="let matrice of matrices; let i = index" class="button-container">
    <div *ngIf="editingIndex === i">
      <button class="btn btn-success" (click)="saveMatrice(i)">Save changes</button>
    </div>
  </div>
  <div class="button-container">
    <button class="btn btn-primary" (click)="onAddMatrice()">Create New</button>
  </div>
</div>
