import { Component, OnInit } from '@angular/core';
import { Department } from '../../../models/Department';
import { Employee } from '../../../models/Employee';
import { Release } from '../../../models/Release';
import { DepartmentService } from '../../../services/departmentservices/department.service';
import { EmployeeService } from '../../../services/employeeServices/employee.service';
import { ReleaseService } from '../../../services/releaseServices/release.service';
import {forkJoin} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import {ExportService} from '../../../../../shared/exports/export.service';
import {SubDepartment} from '../../../models/SubDepartment';
import {SubDepartmentService} from '../../../services/departmentservices/subDepartmentService/sub-department.service';

@Component({
  selector: 'ngx-grid-releases',
  templateUrl: './grid-releases.component.html',
  styleUrls: ['./grid-releases.component.scss'],
})
export class GridReleasesComponent implements OnInit {

  releases: Release [] = [];
  employees: Employee [] = [];
  loading = false;
  departments: Department[] = [];
  subDepartments: SubDepartment[] = [];
  searchbyfields: string = '';
  pageSize = 4;
  current = 1;
  total: string | number;
  constructor(private releaseService: ReleaseService
            , private employeeService: EmployeeService
            , private ds: SubDepartmentService,  private exportService: ExportService) { }

  ngOnInit(): void {
    this.loading = true; // Start loading

    forkJoin({
      releases: this.releaseService.findAll(),
      employees: this.employeeService.findAll(),
      subDepartments: this.ds.findAll(),
    }).pipe(
      finalize(() => this.loading = false), // Stop loading regardless of result
    ).subscribe({
      next: (results) => {
        this.releases = results.releases;
        this.employees = results.employees;
        this.subDepartments = results.subDepartments;
      },
      error: (error) => {
        console.error('Failed to load data', error);
      },
    });
  }
  getReleases() {
    this.releaseService.findAll().subscribe(
      (data: Release[]) => {
        this.releases = data.filter(r => r.releaseStatus !== 'Draft');
      },
      (err) => { console.log(err); },
    );
  }


  getReleasesAsc() {
    this.releaseService.findAllASC().subscribe(
      (data: Release[]) => {
        this.releases = data.filter(d => d.releaseStatus !== 'Draft');
      },
      (err) => { console.log(err); },
    );
  }


  getReleasesDesc() {
    this.releaseService.findAllDesc().subscribe(
      (data: Release[]) => {
        this.releases = data.filter(d => d.releaseStatus !== 'Draft');
      },
      (err) => { console.log(err); },
    );
  }


  getEmployees() {
    this.employeeService.findAll().subscribe(
      (data: Employee[]) => {this.employees = data.filter((e) => e.isArchived === 'No'); },
      (err) => {console.log(err); },
    );
  }

  /*getDepartments() {
    this.departmentService.findAllDepts().subscribe(
      (data: Department[]) => {this.departments = data.filter((d) => d.isArchived === 'No'); },
      (err) => {console.log(err); },
    );
  }*/


  filterByEmployee(e) {
    this.releaseService.findAll().subscribe(
      (data: Release[]) => {
        this.releases = [];
        this.releases = data.filter(
          (d =>
              // @ts-ignore
              d?.employee?.id === e && d.releaseStatus === 'Pending'
              || d.releaseStatus === 'Accepted'
              || d.releaseStatus === 'Rejected'
          ),
        );
      }, (err) => {
        return err;
      });
  }

  filtreSubDepartment(subDeptId: string) {
    if (subDeptId === 'All') {
      // If 'All' is selected, reset and display all leave requests
      this.getReleases();
      return;
    }
    this.releaseService.findAll().subscribe(
      (data: Release[]) => {
        this.releases = data.filter(
          release => release.employee && release.employee.subDepartment && release.employee.subDepartment.id === subDeptId,
        );
      },
      (err) => {
        console.error('Error filtering by sub-department:', err);
      },
    );
  }
  getSubDepartments() {
    this.ds.findAll().subscribe(
      (data: SubDepartment[]) => {
        console.log('SubDepartments:', data);  // Check the output in the console
        this.subDepartments = data;  // Populate listd with fetched sub-departments
      },
      (err) => {
        console.error('Error fetching sub-departments:', err);
      },
    );
  }

  filterByStatus(e) {
    this.releaseService.findAll().subscribe(
      (data: Release[]) => {
        this.releases = [];
        this.releases = data.filter(
          (d =>
              // @ts-ignore
             d.releaseStatus === e
          ),
        );
      }, (err) => {
        return err;
      });
  }

  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.releases, 'dataReleases');
  }


}
