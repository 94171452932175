
<h3>View Global Calendar</h3>

<div class="row text-center mt-3">
    <div class="col-md-4">
      <div class="btn-group">
        <button
          nbButton
          status="basic"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
           <nb-icon icon="arrow-ios-back-outline"></nb-icon> Prev
        </button>
        &nbsp;
        <button
          nbButton
          status="primary"
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          Today 
        </button>
        &nbsp;
        <button
          nbButton
          status="basic"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          Next <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
        </button>
      </div>
    </div>
    <div class="col-md-4">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          Month
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          Week
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          Day
        </div>
      </div>
    </div>
  </div>
  <br />
  <nb-card>
    <div [ngSwitch]="view">
            <mwl-calendar-month-view
            style="color: black;"
            *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-month-view>

        
        
        <mwl-calendar-week-view
        style="color: black;"
        *ngSwitchCase="CalendarView.Week"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
        style="color: black;"
        *ngSwitchCase="CalendarView.Day"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-day-view>
    </div>
  </nb-card>
 