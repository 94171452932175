<div class="row">
    <div class="col-md-6">
        <h1>Update Sub Department </h1>
    </div>
    <div class="col-md-3"></div>
    <div class="col-md-3 mt-4">
        <a status="info" nbButton [routerLink]="['/hr/subdepartment/subList']">View Sub Department List</a>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <form [formGroup]="subForm">
            <label for="">Sub Department Name</label><br>
            <input class="form-control" fullWidth
            fieldSize="large"
            formControlName="subDepartmentName"
            name="subDepartmentName"
            [(ngModel)]="subDept.subDepartmentName"
            id="subDepartmentName" type="text" nbInput/>  <br>
            <label for="">Department</label><br>
            <nb-select [selected]="selectedItem"
                        [(ngModel)]="subDept.department"
                        formControlName="department"
                        name="department"
                        id="department"
                        fullWidth
                        fieldSize="large"
                        placeholder="select department"
                        (selectedChange)="getDepartmentSelected($event)">
                <nb-option *ngFor="let x of list_dept" value="{{x.id}}">{{x.departmentName}}</nb-option>
        
            </nb-select>
            
            <nb-tag-list class="mt-2">
                <nb-tag status="basic" appearance="filled" text="Department Selected : {{subDept?.department?.departmentName}}"></nb-tag>

              </nb-tag-list>

            <div style="float: right;">
                <button status="success" nbButton [disabled]="subForm.invalid" (click)="updateSubDepartment()"  class=" mt-2">
                    Save new data 
                </button>
            </div>
                
            
        </form>
    </div>
  
</div>

