import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrConfig, NbToastrService } from '@nebular/theme';
import { Department } from '../../../models/Department';
import { Employee } from '../../../models/Employee';
import { Level } from '../../../models/Level';
import { DepartmentService } from '../../../services/departmentservices/department.service';
import { EmployeeService } from '../../../services/employeeServices/employee.service';
import { LevelService } from '../../../services/levelServices/level.service';
import {GlobalBudgetServiceService} from '../../../services/globalBudgetService/global-budget-service.service';

@Component({
  selector: 'ngx-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.scss'],
})
export class EditDepartmentComponent implements OnInit {

  config: NbToastrConfig;
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  department: Department;
  id: string;
  listDepts: Department [] = [];
  listEmps: Employee[] = [];
  // listLvls: Level [] = [];
  selectedItem: any [] = [];
  employeeSelected: string = '';
  disableBudget = false;
  disablePercentage = false;
  emp: Employee = new Employee();
  // levelSelected: string = '';
  totalBudget = 0;
  // level_: Level = new Level();

  constructor(private ls: LevelService, private es: EmployeeService,
              private ds: DepartmentService, private toastrService: NbToastrService,
              private route: ActivatedRoute,
              private router: Router,
              private globalBudgetService: GlobalBudgetServiceService) {

  }

  ngOnInit(): void {
    this.loadBudgets();
    this.loadDataEmps();
    // this.loadDataLvls();
    this.department = new Department();
    this.id = this.route.snapshot.params['id'];

    this.ds.getDepartment(this.id).subscribe(data => {
      console.log(data);
      this.department = data;
    }, error => console.log(error),
    );
  }

  loadBudgets() {
    this.globalBudgetService.findAllGlobalBudget().subscribe({
      next: (budgets) => {
        if (budgets.length > 0) {
          const budget = budgets[0];
          this.totalBudget = budget.totalBudget;
          console.log('Total Budget Loaded: ', this.totalBudget);
        }
      },
    });
  }
  getEmployeeSelected(event) {
    this.employeeSelected = event;
  }
  /* getLevelSelected(event) {
    this.levelSelected = event;
  }

  loadDataLvls() {
    this.ls.findAll().subscribe(
      (data: Level[]) => {
        this.listLvls = data;
      } , (err) => {
          console.log(err);
      },
    );
  } */

  loadDataEmps() {
    this.es.findAllEmployeesAsc().subscribe(
      (data: Employee[]) => {
        this.listEmps = data.filter((e) => e.roleEmployee === 'MANAGER');
      } , (err) => {
          console.log(err);
      },
    );
  }
  onPercentageChange(value: string) {
    if (value) {
      const percentage = parseFloat(value);
      if (!isNaN(percentage) && percentage >= 0 && percentage <= 100) {
        this.department.budget = (this.totalBudget * percentage) / 100;
        this.disableBudget = true;  // Disable the budget input
      }
    } else {
      this.disableBudget = false; // Enable the budget input if percentage is cleared
    }
  }

  onBudgetChange(value: string) {
    if (value) {
      const budget = parseFloat(value);
      if (!isNaN(budget) && budget >= 0) {
        this.department.pourcentageBudget = (budget / this.totalBudget) * 100;
        this.disablePercentage = true; // Disable the percentage input
      }
    } else {
      this.disablePercentage = false; // Enable the percentage input if budget is cleared
    }
  }

  editDept() {
    const pourcentageInput = document.getElementById('pourcentageBudget') as HTMLInputElement;
    const budgetInput = document.getElementById('budget') as HTMLInputElement;

    const newPercentage = parseFloat(pourcentageInput.value);
    const newBudget = parseFloat(budgetInput.value);

    // Ensure at least one valid input is provided
    if (isNaN(newPercentage) && isNaN(newBudget)) {
      this.showToast('danger', 'FAILURE', 'Invalid inputs. Please enter a valid budget or percentage.');
      return;
    }

    // Get the current department details again to ensure data accuracy
    this.ds.getDepartment(this.id).subscribe(departmentData => {
      this.department = departmentData;

      const currentDeptPercentage = this.department.pourcentageBudget || 0; // Use updated department data

      if (!isNaN(newBudget) && newBudget > 0) {
        this.department.budget = newBudget;
        this.department.pourcentageBudget = (newBudget / this.totalBudget) * 100;
      } else if (!isNaN(newPercentage) && newPercentage > 0) {
        this.department.budget = this.totalBudget * (newPercentage / 100);
        this.department.pourcentageBudget = newPercentage;
      }

      // Update employee only if a new employee is selected
      if (this.employeeSelected) {
        this.department.employee = this.employeeSelected;
      } else {
        delete this.department.employee; // Ensure employeeId is not sent if not selected
      }

      this.ds.getTotalPercentage().subscribe(totalPercentage => {

        // Adjusted total now correctly uses the most up-to-date department percentage
        const effectiveTotal = totalPercentage - currentDeptPercentage + newPercentage;

        if (effectiveTotal > 100) {
          this.showToast('danger', 'FAILURE', `Cannot update department. Budget exceeds 100%. Only ${100 - (totalPercentage - currentDeptPercentage)}% available.`);
        } else {
          this.ds.updateDepartment(this.id, this.department).subscribe(
            res => {
              const message = effectiveTotal === 100 ?
                'Department updated successfully, and the total budget is now fully allocated.' :
                'Updated Successfully. Budget remaining: ' + (100 - effectiveTotal) + '%.';
              this.showToast('success', 'SUCCESS', message);
              this.router.navigate(['/hr/department/list']);
            },
            err => {
              console.error(err);
              this.showToast('danger', 'FAILURE', 'Could not update department despite correct data.');
            },
          );
        }
      });
    }, error => {
      console.log('Error fetching current department data:', error);
      this.showToast('danger', 'FAILURE', 'Failed to fetch current department data.');
    });
  }


  onSubmit() {
    this.editDept();
  }
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? `. ${title}` : '';
    this.toastrService.show(
      body,
      `Toast ${titleContent}`,
      config);
  }


}
