import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-empty-notification',
  templateUrl: './empty-notification.component.html',
  styleUrls: ['./empty-notification.component.scss'],
})
export class EmptyNotificationComponent {
  constructor(public dialogRef: NbDialogRef<EmptyNotificationComponent>) {}

}
