import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {NbDialogService, NbIconComponent} from '@nebular/theme';
import {AuthService} from '../../../../auth/service/auth.service';
import {Auth} from '../../../../auth/model/Auth';
import {Users} from '../../../../auth/model/Users';
import {TokenStorageService} from '../../../../auth/service/token/token.service';
import {ExportService} from '../../../../../shared/exports/export.service';

@Component({
  selector: 'ngx-admin-organigram',
  templateUrl: './admin-organigram.component.html',
  styleUrls: ['./admin-organigram.component.scss'],
})
export class AdminOrganigramComponent implements OnInit {
  isLoading = true;
  currentUser: Auth = new Auth();
  selectedUser = null;
  @Input() dataUser: Auth = null;
  refDialog = null;
  flippedState: boolean = false;
  searchbyfields: string;
  list: Auth[] = [];
  loading = false;
  filteredList: Auth[] = [];
  selectedFilter: string = 'email';
  searchTerm: string = '';
  pageSize: number = 4;
  current: number = 1;
  getRole = localStorage.getItem('userrole');
  constructor(
    private dialogService: NbDialogService,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    private exportService: ExportService,
  ) {}

  ngOnInit(): void {
      this.getUsersAdmins();
    }

  getUsersAdmins() {
    this.authService.getAllUsers().subscribe({
      next: (data: any) => {
        // Filter the list to include only users where 'archiver' is false
        this.list = (data || []).filter(user => user.archiver === false);

        // Prepare the filtered list for use in the UI or further logic
        this.filteredList = [...this.list];
        this.applyFilter();
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  applyFilter(): void {
    this.filteredList = this.list.filter((user) => {
      const searchTerm = this.searchTerm.toLowerCase();
      const value = user[this.selectedFilter].toLowerCase();
      return value.includes(searchTerm);
    });
  }
  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.list, 'dataEmployees');
  }
}
