
  <nb-layout-column>
    <div>
      <div class="shape"></div>
      <div class="shape"></div>
    </div>
    <form [formGroup]="testForm" (ngSubmit)="submitTest()" >
      <div class="header-container">
        <h4 style="color: #fcfcfc; flex-grow: 1;" >Test</h4>
      </div>

      <div class="apps-scroll-container">
        <div class="label-input-container">
          <label>Duration :</label>
          <input type="text"
                 nbInput
                 fullWidth
                 placeholder="mm:ss"
                 style="background-color: white;"
                 [nbTimepicker]="timepicker"
                 formControlName="dureeTest">
          <nb-timepicker #timepicker="nbTimepicker" [twelveHoursFormat]="false" hoursText="mm" minutesText="ss"></nb-timepicker>
        </div>
        <div class="label-input-container">
          <label style="margin-bottom: 10px;">Type :</label>
          <input style="margin-bottom: 10px;" formControlName="type" placeholder="Type of the test">
        </div>
        <div class="label-input-container">
          <label style="margin-bottom: 10px;">Name :</label>
          <input formControlName="nomTest" placeholder="Name of the test">
        </div>
        <div formArrayName="questions">
          <div *ngFor="let questionControl of questions.controls; let qIndex = index" [formGroupName]="qIndex">
            <div class="question-container">
              <input style="margin-top: 20px;" formControlName="questionText" placeholder="Question">
              <div class="icon-buttons">
                <nb-icon icon="plus-circle-outline" (click)="addResponse(qIndex)"></nb-icon>
                <nb-icon icon="trash-2-outline" (click)="removeQuestion(qIndex)"></nb-icon>
              </div>
            </div>


            <div formArrayName="responses">

              <div *ngFor="let responseControl of getResponseArray(qIndex).controls; let rIndex = index" [formGroupName]="rIndex" class="response-container">
                <input class="input-radio" type="radio" [checked]="responseControl.get('correct').value" (change)="setCorrectResponse(qIndex, rIndex)">
                <input style="width: 85%;" formControlName="responseText" placeholder="Response">
                <div class="trash">
                  <nb-icon icon="trash-2-outline" (click)="removeResponse(qIndex, rIndex)"></nb-icon>
                </div>
              </div>

            </div>

          </div>


        </div>
        <div class="button-container">
          <!-- Prevents default form submission behavior -->
          <button nbButton status="primary" type="button" (click)="addQuestion()">Add Question</button>

          <!-- This button is intended to submit the form -->
          <button nbButton status="success" type="submit">Submit Test</button>

          <!-- Prevents default form submission behavior -->
          <button nbButton status="danger" type="button" (click)="closeDialog()">Close</button>
        </div>


      </div>
    </form>

  </nb-layout-column>

