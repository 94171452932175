import { Employee } from './Employee';
import { Level } from './Level';

export class Department {
    id: string;
    departmentReference: string;
    departmentName: string;
    employee: string;
    isArchived: string;
    user: string;
    budget: number;
    pourcentageBudget: number;
}
