import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {NbToastrService } from '@nebular/theme';
import {AuthService} from '../../service/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'ngx-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss'],
})
export class VerificationCodeComponent implements  OnInit {
  VerifCodeForm: FormGroup;
  formRegister: UntypedFormGroup;
  isValid = true;
  errorMessage = '';
  isCodeVerified = false;
  _email: string;
  token: string;
  codeVerification = '';
  resetMethod: string;

  @ViewChild('input0') input0: ElementRef;
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;
  @ViewChild('input5') input5: ElementRef;
  @ViewChild('input6') input6: ElementRef;

  constructor(
    private fb: FormBuilder,
    private us: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: NbToastrService,
  ) {
  }

  get email() {
    return this.formRegister.get('email');
  }

  showToast(message, position, status) {
    this.toastrService.show(status || 'Success', message, {position, status});
  }

  // fixed

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.activatedRoute.queryParamMap.subscribe(params => {
      this._email = params.get('email');
      this.resetMethod = params.get('method');
    });
    this.VerifCodeForm = this.fb.group({});
  }

  onKeyup(event: any, index: number) {
    const currentElement: HTMLInputElement = event.target;
    const previousElement: HTMLInputElement = currentElement.previousElementSibling as HTMLInputElement;

    // Si ce n'est pas le premier input et que le champ précédent est vide, replacer le focus sur le champ précédent
    if (index > 0 && (!previousElement || !previousElement.value)) {
      previousElement.focus();
      return; // Sortir de la fonction pour ne pas traiter d'autres logiques
    }

    const validKeys = /^[a-zA-Z0-9]$/; // Regex pour les caractères valides (lettres et chiffres)

    // Vérifier si la touche pressée est un caractère valide
    if (validKeys.test(event.key)) {
      // Construire le code de validation complet
      this.codeVerification = '';
      for (let i = 0; i <= 5; i++) {
        this.codeVerification += (document.getElementById(`input${i}`) as HTMLInputElement).value;
      }

      if (this.codeVerification.length === 6) {
        this.verifyCode(); // Appeler la fonction de vérification lorsque le code a 6 chiffres
      } else {
        // Se focaliser sur le prochain champ s'il est valide et disponible
        const nextElement: HTMLInputElement = currentElement.nextElementSibling as HTMLInputElement;
        if (nextElement) {
          nextElement.readOnly = false; // Rendre le prochain champ modifiable
          nextElement.focus(); // Mettre le focus sur le prochain input
        }
      }

      // Rendre le champ actuel readonly après la saisie
      currentElement.readOnly = true;
    }
  }

  verifyCode() {
    this.us.verifyCode(this.codeVerification).subscribe({
      next: (response) => {
        console.log('Code verified successfully', response);
        this.isCodeVerified = true;
        if (this.token) {
          this.router.navigate(['/auth/reset_pwd', this.token]);
        } else {
          console.error('Token is undefined');
          // Gérer le cas où le token est indéfini
        }
      },
      error: (error) => {
        console.error('Verification failed', error);
        this.isCodeVerified = false;
        if (error.error && error.error.errorCode) {
          this.errorMessage = this.getErrorMessage(error.error.errorCode); // Utiliser errorCode pour déterminer le message
          if (error.error.errorCode === 'EXPIRED_CODE') {
            this.toastrService.warning(this.errorMessage, 'EXPIRED CODE');
          } else {
            // Utiliser toastr.error pour les autres types d'erreurs
            this.toastrService.danger(this.errorMessage, 'INVALID CODE');
          }
        } else {
          this.toastrService.danger('Erreur inattendue. Veuillez réessayer plus tard.'); // Message d'erreur par défaut
        }
        this.resetInputs();
      },
    });
  }


  getErrorMessage(errorCode: string): string {
    switch (errorCode) {
      case 'INVALID_CODE':
        return 'the verification code is invalid. Please check and try again.';
      case 'EXPIRED_CODE':
        return 'the verification code has expired. Please request a new one.';
      default:
        return 'Unexpected error. Please try again later.';
    }
  }

  resetInputs() {
    for (let i = 0; i <= 5; i++) {
      const input = (document.getElementById(`input${i}`) as HTMLInputElement);
      input.value = ''; // Effacer la valeur
      input.readOnly = i !== 0; // Seul le premier champ est modifiable
    }
    this.input0.nativeElement.focus(); // Mettre le focus sur le premier champ de saisie
  }

  regeneraterCode() {
    console.log(this.resetMethod);
    if (this.resetMethod === 'phone') {
      // Logique pour régénérer le code via SMS
      if (this.token ) { // Assurez-vous que _phone est défini et contient le numéro de téléphone
        this.us.regenerateCodeWithSMS(this.token).subscribe({
          next: (response) => {
            console.log(response); // Log la réponse du serveur
            this.toastrService.show('New verification code generated successfully. Please check your Phone.', 'Success', {status: 'success'});
          },
          error: (error) => {
            console.error(error); // Log les erreurs
            this.toastrService.show('Error generating new code with sms', 'Erreur', {status: 'danger'});
          },
        });
      } else {
        this.toastrService.show('Token or phone number not available', 'Erreur', {status: 'danger'});
      }
    } else if (this.resetMethod === 'email') {
      if (this.token) {
        this.us.regenerateVerificationCode(this.token).subscribe({
          next: (response) => {
            console.log(response); // Log la réponse du serveur
            this.toastrService.show('New verification code generated successfully. Please check your email.', 'Success', {status: 'success'});
          },
          error: (error) => {
            console.error(error); // Log les erreurs
            this.toastrService.show('Error generating new code', 'Erreur', {status: 'danger'});
          },
        });
      } else {
        this.toastrService.show('token not available', 'Erreur', {status: 'danger'});
      }
    }
  }
}
