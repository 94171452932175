<div class="container" *ngIf="release">
    <div class="row">
      <div class="col-md-5 col-sm-12">
        <h2 class="more-vertical">
          Update Release Request 
        </h2>
      </div>
      <div align="right" class="col-md-7">
          <a align="right" nbButton status="basic" shape="round" [routerLink]="['/hr/release/grid']">
            <nb-icon icon="corner-up-right-outline"></nb-icon>
        </a>
      </div>
  
    
  
    </div>
    <div class="row mt-3">
      <div class="col-md-4 mb-3">
        <nb-card>
          <nb-card-body class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <div class="row">
  
                <img alt="" src="{{release?.employee?.employeeProfileImage}}" style="width: 150px;height: 150px;border-radius: 100%;">
              </div>
  
              <div class="mt-3">
                <h4>{{release?.employee?.employeeName}}</h4>
                <div class="row">
                  
                  <div class="col-md-12">
                       <nb-tag-list>
                    <nb-tag [text]="release?.employee?.roleEmployee" status="primary" appearance="filled"></nb-tag>
                  </nb-tag-list>
                  </div>
                </div>
           
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      
     
      </div>
      <form class="col-md-8" [formGroup]="release_form">
      <div class="">
        <nb-accordion>
          <nb-accordion-item expanded="false">
            <nb-accordion-item-header>Employee</nb-accordion-item-header>
            <nb-accordion-item-body>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Employee Name</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <p>{{release?.employee?.employeeName}}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Email Address</p>
                </div>
                <div class="col-sm-9 text-secondary">
                    <p>{{release?.employee?.employeeEmail}}</p>
                </div>
              </div>
              <hr>
                <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Gender</p>
                </div>
                <div class="col-sm-9 text-secondary">
                    <p>{{release?.employee?.employeeGender}}</p>
                </div>
              </div>
              <hr>

              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Employee Cell Phone</p>
                </div>
                <div class="col-sm-9 text-secondary">
                    <p>{{release?.employee?.employeeCellPhone}}</p>
                </div>
              </div>             
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion>
          <nb-accordion-item expanded="true">
            <nb-accordion-item-header>Release Information</nb-accordion-item-header>
            <nb-accordion-item-body>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Start Time</p>
                </div>
                <div class="col-sm-9 text-secondary">
                   <p>{{release?.startTime}}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">End Date</p>
                </div>
                <div class="col-sm-9 text-secondary">
                   <p>{{release?.endTime}}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Recent Status</p>
                </div>
                <div class="col-sm-9 text-secondary">
                    <div class="row">
                        <div class="col-md-5">
                            <p>{{release?.releaseStatus}}</p>
                        </div>
                        <div class="col-md-7">
                            <nb-toggle [hidden]="release?.releaseStatus === 'Validate' || release?.releaseStatus === 'Rejected' " [disabled]="clicked" (change)="validateRelease(); clicked = true" status="success">Approve</nb-toggle>
                            <nb-toggle [hidden]="release?.releaseStatus === 'Validate' || release?.releaseStatus === 'Rejected' " [disabled]="clicked" (change)="rejectRelease(); clicked = true" class="mx-3" status="danger">Reject</nb-toggle>
                        </div>
                    </div>
                </div>
              </div>
              
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
        <br>

        
        <br>
      </div>
       </form>
    </div>
  
  
  
  
  </div>