<div class="notification-dialog">
  <nb-card class="notification-card">
    <nb-card-header>
      <nb-icon icon="bell-outline" class="header-icon"></nb-icon>
      <span class="header-title">No Notifications</span>
    </nb-card-header>
    <nb-card-footer class="footer-center">
      <button nbButton status="danger" (click)="dialogRef.close()">Close</button>
    </nb-card-footer>
  </nb-card>
</div>
