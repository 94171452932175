<div class="row">
    <div class="col-md-6 col-sm-12">
      <div class="row">
        <h3 class="more-vertical">
          Contracts History <nb-icon nbTooltip="Click here to reload your data" nbTooltipStatus="primary" class="ion-loop ng-star-inserted text-primary"></nb-icon> </h3>
      </div>

    </div>
    <div class="col-md-6 col-sm-8">
        <input class="w-75 mx-2"  nbTooltip="Fill this field to find contract" nbTooltipStatus="primary" type="text"  fieldSize="large" nbInput placeholder="search by fields" >
        <button [disabled]="contracts.length === 0" nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" ><i class="fas fa-file-excel text-white"></i>  </button>
        &nbsp;


        <br>
  </div>
<div class="page-content container note-has-grid mt-4">

    <div class="row">
      <div class="col-md-2">
        <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
            <nb-select  (selectedChange)="filtreByContratType($event,'contractType')" selected="0" class="w-100 mt-3">
              <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Contract</nb-option>
              <nb-option value="All" (click)="getAllContracts()">&nbsp;&nbsp;&nbsp;&nbsp; Default</nb-option>
              <nb-option value="CDD" >&nbsp;&nbsp;&nbsp;&nbsp;CDD</nb-option>
              <nb-option value="CDI" >&nbsp;&nbsp;&nbsp;&nbsp;CDI</nb-option>

            </nb-select>
          </nb-form-field>
          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
            <nb-select  (selectedChange)="filtreByStatus($event,'status')" selected="0" class="w-100 mt-3">
              <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Status</nb-option>
              <nb-option value="All" (click)="getAllContracts()">&nbsp;&nbsp;&nbsp;&nbsp; Default</nb-option>
              <nb-option value="In Trial Period" >&nbsp;&nbsp;&nbsp;&nbsp;In Trial Period</nb-option>
              <nb-option value="Validate Trial Period" >&nbsp;&nbsp;&nbsp;&nbsp;Validate Trial Period</nb-option>
              <nb-option value="Renewed Trial Period" >&nbsp;&nbsp;&nbsp;&nbsp;Renewed Trial Period</nb-option>
              <nb-option value="Failure Trial Period" >&nbsp;&nbsp;&nbsp;&nbsp;Failure Trial Period</nb-option>


            </nb-select>
          </nb-form-field>
          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
            <nb-select  (selectedChange)="filtreByHoursPlan($event,'hourlyDistribution')" selected="0" class="w-100 mt-3">
              <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Hourly Distribution</nb-option>
              <nb-option value="All" (click)="getAllContracts()">&nbsp;&nbsp;&nbsp;&nbsp; Default</nb-option>
              <nb-option value="40" >&nbsp;&nbsp;&nbsp;&nbsp; 40 Hours</nb-option>
              <nb-option value="48" >&nbsp;&nbsp;&nbsp;&nbsp; 48 Hours</nb-option>

            </nb-select>
          </nb-form-field>
          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
            <nb-select  selected="0" class="w-100 mt-3">
              <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Hiring Date</nb-option>
              <nb-option (click)="getContractsAscHiringDate()" value="" >Ascending</nb-option>
              <nb-option (click)="getContractsDescHiringDate()" value="" >Descending</nb-option>


            </nb-select>
          </nb-form-field>
          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
            <nb-select  selected="0" class="w-100 mt-3">
              <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; End Date</nb-option>
              <nb-option (click)="getContractsAscEndDate()" value="" >Ascending</nb-option>
              <nb-option (click)="getContractsDescEndDate()" value="" >Descending</nb-option>

            </nb-select>
          </nb-form-field>
          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
            <nb-select  selected="0" class="w-100 mt-3">
              <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Official Signature</nb-option>
              <nb-option (click)="getContractsAscOfficialSignature()" value="" >Ascending</nb-option>
              <nb-option (click)="getContractsDescOfficialSignature()" value="" >Descending</nb-option>

            </nb-select>
          </nb-form-field>
          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
            <nb-select  selected="0" class="w-100 mt-3">
              <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Gross Annual Salary</nb-option>
              <nb-option (click)="getContractsAscGrossAnnualSalary()" value="" >Ascending</nb-option>
              <nb-option (click)="getContractsDescGrossAnnualSalary()" value="" >Descending</nb-option>

            </nb-select>
          </nb-form-field>
          <div class="mt-3">
            <label>Item Per Page</label>
            <input class="w-100" [disabled]="contracts.length === 0" type="number" nbInput [(ngModel)]="pageSize" min="9">

          </div>

    </div>

        <div class="col-md-10">
          <div class="row w-100" *ngIf="!contracts || contracts.length === 0">
            <nb-alert outline="danger" class="w-100 mt-3"> No Data Found.</nb-alert>
        </div>
        <div [hidden]="contracts.length === 0" class="row w-100" *ngIf="(contracts | filter : search).length === 0">
            <nb-alert outline="danger" fullWidth class="col-lg-12 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
        </div>
            <table  class="table mb-0 mt-3" id="contracts">
                <thead class="bg-light">
                <tr>
                  <th>Employee</th>
                  <th>Hiring </th>
                  <th>End </th>
                  <th>Type</th>
                  <th>Signature</th>
                  <th>Status</th>
                  <th>Position</th>
                  <th>Annual Salary</th>
                  <th class="print-none">Actions</th>
                </tr>
                </thead>
                <tr *ngFor="let contract of contracts | filter : search | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: total}">
                  <td>
                    <p> <nb-user size="medium" [name]="contract?.employee?.employeeName"
                                  [picture]="contract?.employee?.employeeProfileImage"></nb-user> </p>
                 </td>
                 <td><p class="mt-2">{{contract.hiringDate | date : 'YYYY MMM dd'}}</p></td>
                 <td><p class="mt-2">{{contract.endDate | date : 'YYYY MMM dd'}}</p></td>
                 <td>

                  <nb-tag-list>
                    <nb-tag class="mt-2" status="success" size="small" [text]="contract.contractType"></nb-tag>
                </nb-tag-list>
                 </td>
                 <td><p  class="mt-2">{{contract.officialSignature | date : 'YYYY MMM dd'}}</p></td>
                 <td>
                   <p class="mt-2">{{contract.status}}</p>
                </td>
                 <td><p class="mt-2">{{contract?.employee?.roleEmployee}}</p></td>
                 <td><p class="mt-2">{{contract.grossAnnualSalary}}</p></td>
                <td class="print-none">
                    <button class="mx-2" shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <nb-icon icon="more-horizontal-outline"></nb-icon>
                      </button>
                      <mat-menu #menu="matMenu">

                        <a (click)="deleteContract(contract.id)" style="text-decoration:none" nbTooltip="Click here to delete data" nbTooltipStatus="primary">
                            <button mat-menu-item>
                              <span ><nb-icon class="text-danger" icon="trash-outline"></nb-icon>&nbsp; Delete</span>
                            </button>
                          </a>


                        <a (click)="restoreContract(contract,contract.id)" nbTooltip="Click here to archive data" nbTooltipStatus="primary">
                          <button mat-menu-item>
                            <span><nb-icon class="text-secondary" icon="refresh-outline"></nb-icon>&nbsp; Restore</span>
                          </button>
                        </a>

                      </mat-menu>
                   </td>

                </tr>
                </table>
          <div class="mt-4 d-flex justify-content-end">
            <nb-card class="" style="height: 35px;margin-left: 0; border-radius: 25px;">
              <pagination-controls class="pt-1 text-center" (pageChange)="current = $event"></pagination-controls>
            </nb-card>
          </div>

        </div>


    </div>
</div>
</div>
