import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-edit-evaluation',
  templateUrl: './edit-evaluation.component.html',
  styleUrls: ['./edit-evaluation.component.scss'],
})
export class EditEvaluationComponent {

  constructor() { }


}
