export class Users {
    id: string;
    username: string;
    email: string;
    password: string;
    resetPassword: string;
    matriculate: string;
    roles: string[];
    failedAttempts: number;
    lastFailedAttempt: Date;
    answer1: string;
    answer2: string;
    answer3: string;
    userProfileImage: string;
    company: any;
    phone: String;
    role: String;

}
