import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Department } from '../../../models/Department';
import { SubDepartment } from '../../../models/SubDepartment';
import { DepartmentService } from '../../../services/departmentservices/department.service';
import { SubDepartmentService } from '../../../services/departmentservices/subDepartmentService/sub-department.service';
import {Level} from '../../../models/Level';
import {LevelService} from '../../../services/levelServices/level.service';
import {DocumentService } from '../../../services/document.service';

declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {Browser} from 'leaflet';
import {ExportService} from '../../../../../shared/exports/export.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {Router} from '@angular/router';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'ngx-view-organigram',
  templateUrl: './view-organigram.component.html',
  styleUrls: ['./view-organigram.component.scss'],
})
export class ViewOrganigramComponent implements OnInit {


  @ViewChild('htmlData') htmlData!: ElementRef;
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;

  public config_ = {
    printMode: 'template-popup',
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'Organigram',
    stylesheets: [{rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'}],
  };

  constructor(private exportService: ExportService, private router: Router,
              private ls: LevelService, private sds: SubDepartmentService, private ds: DepartmentService,private documentService : DocumentService) {
  }

  list: Department[] = [];
  subList: SubDepartment [] = [];
  lvlsList: Level [] = [];
  subDepartmentsMap: Map<string, SubDepartment[]> = new Map();

  nodes: any [] = [];

  ngOnInit(): void {
    this.getAllLvls();
    this.getDepartments();
    this.getAllSubDepartments();

  }
  navigateToSubDepartment(subDepartmentId: string) {
    this.router.navigate(['hr/organigram/employees/', subDepartmentId]);
  }
  navigateToDepartment(departmentId: string) {
    this.router.navigate(['hr/organigram/', departmentId]);
  }

  /*navigateToAdmins() {
    this.router.navigate(['hr/organigram/admin']);
  }*/

  /*navigateToAdmins() {
    this.router.navigate(['hr/organigram/admin']);
  }*/

  getDepartments() {
    this.ds.findAllDepts().subscribe(
      (data: Department[]) => {
        this.list = data;

      }, (err) => {
        console.log(err);
      },
    );
  }

  getAllSubDepartments() {
    this.sds.findAll().subscribe(
      (data: SubDepartment[]) => {
        this.subList = data;
      }, (err) => {
        console.log(err);
      },
    );
  }

  getAllLvls() {
    this.ls.findAll().subscribe(
      (data: Level[]) => {
        this.lvlsList = data;

      }
      , (err) => {
        console.log(err);
      },
    );
  }

  public openPDF(): void {

    const DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      const fileWidth = 400;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('l', 'mm', 'a3');
      const position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('organigram.pdf');
      const trace = {
        fileName: 'Organigram.pdf',
        contentType: 'application/pdf',
        module: 'HCM',
        createdAt: new Date().toISOString()
      };
  
      this.documentService.saveTrace(trace).subscribe({
        next: () => console.log('Trace saved successfully'),
        error: (err) => console.error('Error saving trace', err)
      });
    });

  }



}
