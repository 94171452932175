<div class="row">
    <div class="col-md-3 col-sm-12">
            <h3>
                Expenses
            </h3>
    </div>
    <div class="col-md-5">
        <input class="" fullWidth [(ngModel)]="searchbyfields"  nbTooltip="Search expense" nbTooltipStatus="primary" [disabled]="dataExpenses.length === 0" type="text"  fieldSize="large" nbInput placeholder="Search expense">
    </div>
    <div class="col-md-4 col-sm-8 mt-1">
        <a nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" [routerLink]="['/hr/expenses/grid']" nbButton status="primary"> <nb-icon icon="grid-outline"></nb-icon>   </a>
        &nbsp;
        <a nbTooltip="Visualize data on dimensions grid 3x3" nbTooltipStatus="primary" [routerLink]="['/hr/expenses/keypad']" nbButton status="warning"> <nb-icon icon="keypad-outline"></nb-icon>   </a>
        &nbsp;
        <a [routerLink]="['/hr/expenses/table']" nbTooltip="Visualize data in table" nbTooltipStatus="primary" nbButton status="basic"> <nb-icon icon="list-outline"></nb-icon>   </a>
        &nbsp;
        <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" (click)="exportAsXLSX()"><i class="fas fa-file-excel text-white"></i>  </button>
        &nbsp;

        <a [routerLink]="['/hr/expenses/create']" nbTooltip="Create expense" nbTooltipStatus="primary" nbButton status="info">
            <nb-icon icon="plus-outline"></nb-icon>
            Create new
        </a>

          <br>
    </div>



</div>
<br>
<div class="row w-100" *ngIf="loading">
  <nb-alert [nbSpinner]="true" nbSpinnerSize="giant" nbSpinnerStatus="primary" class="w-100 mt-6" style="background-color: transparent !important;"></nb-alert>
</div>
<div class="row" *ngIf="!loading && (!dataExpenses || dataExpenses?.length === 0)">
    <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
</div>
<div [hidden]="dataExpenses.length === 0" class="row" *ngIf="(dataExpenses | filter : searchbyfields).length === 0">
    <nb-alert outline="danger" class="w-100 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
</div>

<div class="row">
<div class="col-md-3 mt-3">

  <div class="row ">
    <div class="col-lg-9">
      <nb-form-field>
        <nb-icon nbPrefix class="mt-1" icon="funnel-outline"></nb-icon>
        <nb-select selected="0" (selectedChange)="filterByEmployee($event)"  fullWidth>
          <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Employee</nb-option>
          <nb-option value="All" (click)="getAllExpenses()"> &nbsp;&nbsp;&nbsp;All</nb-option>
          <nb-option *ngFor="let employee of employees" value="{{employee.id}}">
            &nbsp;&nbsp;&nbsp; <nb-user [name]="employee.employeeName" [picture]="employee.employeeProfileImage"></nb-user>
          </nb-option>

        </nb-select>
      </nb-form-field>

    </div>
  </div>
  <div class="row ">
    <div class="col-lg-9 mt-3">
      <nb-form-field>
        <nb-icon nbPrefix class="mt-1" icon="funnel-outline"></nb-icon>
        <nb-select selected="0" (selectedChange)="filterByStatus($event)"  fullWidth>
          <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Status</nb-option>
          <nb-option value="All" (click)="getAllExpenses()"> &nbsp;&nbsp;&nbsp;All</nb-option>
          <nb-option value="PENDING"> Pending
            &nbsp;&nbsp;&nbsp;
          </nb-option>
          <nb-option value="Validated"> Approved
            &nbsp;&nbsp;&nbsp;
          </nb-option>
          <nb-option value="Rejected"> Rejected
            &nbsp;&nbsp;&nbsp;
          </nb-option>
        </nb-select>
      </nb-form-field>

    </div>
  </div>

  <div class="row mt-3">
    <div class="col-lg-9">
      <nb-form-field>
        <nb-icon nbPrefix class="mt-1" icon="flip-outline"></nb-icon>
        <nb-select selected="0" fullWidth>
          <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Name</nb-option>
          <nb-option value="All" (click)="getAllExpenses()"> &nbsp;&nbsp;&nbsp;All</nb-option>
          <nb-option (click)="getExpensesAsc()" value="Ascending">&nbsp;&nbsp;&nbsp; Ascending </nb-option>
          <nb-option (click)="getExpensesDesc()" value="Descending">&nbsp;&nbsp;&nbsp; Descending </nb-option>
        </nb-select>
      </nb-form-field>

    </div>
  </div>

</div>
    <div class="col-md-9">
        <div class="row mt-3">

         <table class="table">
                <thead class="bg-light">
                    <tr>
                      <th>Employee</th>
                        <th>Year</th>
                        <th>Day</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Starting city</th>
                        <th>Ending city</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tr *ngFor="let expense of dataExpenses | filter : searchbyfields | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: dataExpenses?.length}">
                  <td><nb-user [name]="expense.employee?.employeeName"  picture="{{expense.employee?.employeeProfileImage}}"></nb-user></td>
                  <td><p>{{expense.expenseDate | date : "YYYY"}}</p></td>
                    <td><p>{{expense.expenseDate | date : "dd MMM"}}</p></td>
                    <td><p>{{expense.expenseName}}</p></td>

                    <td>
                        <p>{{expense.status}}</p>
                    </td>
                    <td>
                        <p>{{expense.startingCity}}</p>
                    </td>
                    <td>
                        <p>{{expense.endingCity}}</p>
                    </td>

                    <td>
                        <button shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <nb-icon icon="more-horizontal-outline"></nb-icon>
                          </button>
                          <mat-menu #menu="matMenu">

                              <!--<a style="text-decoration:none" nbTooltip="Show Details" nbTooltipStatus="primary">
                                <button mat-menu-item>
                                  <span ><nb-icon class="text-info" icon="eye-outline"></nb-icon>&nbsp; Details</span>
                                </button>
                              </a>-->

                              <a style="text-decoration:none" nbTooltip="Edit Data" nbTooltipStatus="primary">
                                <button mat-menu-item>
                                  <span ><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
                                </button>
                              </a>

                              <a nbTooltip="Click here to archive data" nbTooltipStatus="primary">
                                <button mat-menu-item (click)="onArchiveConfirm(expense,expense.id)">
                                  <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>
                                </button>
                              </a>

                          </mat-menu>
                    </td>
                </tr>
         </table>
        </div>
      <div class="mt-4 d-flex justify-content-end">
        <nb-card class="" style="height: 35px;margin-left: 0; border-radius: 25px;">
          <pagination-controls class="pt-1" (pageChange)="current = $event"></pagination-controls>
        </nb-card>
      </div>
    </div>
</div>
