<div class="container">
    <h3>Recruitment Workflow</h3>
<nb-card size="medium" style="margin-top: 30px;">
    <nb-card-body>
      <nb-stepper orientation="horizontal">
        <nb-step label="Job Offer">
          <h4>Creating and Publishing a Job Offer</h4>
          <p class="lorem" style="margin-top: 30px;">
            The first step in recruitment involves drafting a detailed job description that outlines the essential skills and qualifications needed. This description is then used to create a compelling job offer that is published on various platforms to reach a broad audience of potential candidates.
          </p>
          <p class="lorem" style="margin-top: 30px;">
            Effective job offers clearly communicate the role's requirements, the company culture, and the benefits provided, ensuring that they attract the right fit for the organization.
          </p>
          <p class="lorem" style="margin-top: 30px; margin-bottom: 40px;">
            Job postings are strategically released during optimal hiring periods to maximize visibility and attract a diverse pool of candidates, ensuring a wide range of skills and experiences to choose from.
          </p>
          <div class="row">
            <div class="col-md-2">
              <button nbButton disabled nbStepperNext>prev</button>
            </div>
            <div class="col-md-8">

            </div>
            <div class="col-md-2">
              <button nbButton nbStepperNext>next</button>
            </div>
          </div>
        </nb-step>
        <nb-step label="Applications">
          <h4>Reviewing Applications</h4>
          <p class="lorem" style="margin-top: 30px;">
            Once the job offer is published, candidates submit their applications, often including resumes and cover letters. Each application is reviewed to assess how well the candidate's qualifications and experiences align with the job requirements.
          </p>
          <p class="lorem" style="margin-top: 30px;">
            This stage is crucial for filtering candidates to ensure that only the most suitable are moved forward to the interview stage.
          </p>
          <p class="lorem" style="margin-top: 30px; margin-bottom: 40px;">
            Applications are processed using advanced screening software which helps in identifying the top candidates by matching keywords and necessary skills from the job description.
          </p>
          <div class="row">
            <div class="col-md-2">
              <button nbButton nbStepperPrevious>prev</button>
            </div>
            <div class="col-md-8">

            </div>
            <div class="col-md-2">
              <button nbButton nbStepperNext>next</button>
            </div>
        </div>
        </nb-step>
        <nb-step label="Interviews">
          <h4>Conducting Interviews</h4>
          <p class="lorem" style="margin-top: 30px;">
            The interview process is designed to evaluate the candidates further through one-on-one or panel interviews. These discussions help ascertain the candidates' problem-solving abilities, interpersonal skills, and suitability for the organizational culture.
          </p>
          <p class="lorem" style="margin-top: 30px;">
            Depending on the role, candidates may undergo several rounds of interviews, including technical assessments and meetings with senior leadership.
          </p>
          <p class="lorem" style="margin-top: 30px; margin-bottom: 40px;">
            Each interview is structured to delve deeper into the candidate's experience and motivations, with a focus on behavioral and situational responses that reveal key competencies required for the position.
          </p>
          <div class="row">
            <div class="col-md-2">
              <button nbButton nbStepperPrevious>prev</button>
            </div>
            <div class="col-md-8">

            </div>
            <div class="col-md-2">
              <button nbButton nbStepperNext>next</button>
            </div>
        </div>
        </nb-step>
      </nb-stepper>
    </nb-card-body>
  </nb-card>
</div>
