import {Component, EventEmitter, OnInit, Output, TemplateRef} from '@angular/core';
import {
  NbComponentStatus, NbDialogService,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig, NbToastrService,
  NbWindowService,
} from '@nebular/theme';
import {Department} from '../../../models/Department';
import {SubDepartment} from '../../../models/SubDepartment';
import {ExportService} from '../../../../../shared/exports/export.service';
import {SubDepartmentService} from '../../../services/departmentservices/subDepartmentService/sub-department.service';
import {DepartmentService} from '../../../services/departmentservices/department.service';
import {Router} from '@angular/router';
import {forkJoin} from 'rxjs';
import {count, finalize} from 'rxjs/operators';
import {EmployeeService} from '../../../services/employeeServices/employee.service';
import {GlobalBudgetServiceService} from '../../../services/globalBudgetService/global-budget-service.service';
import {GlobalBudget} from '../../../models/GlobalBudget';
@Component({
  selector: 'ngx-grid-department',
  templateUrl: './grid-department.component.html',
  styleUrls: ['./grid-department.component.scss'],
})
export class GridDepartmentComponent implements OnInit {

  current: number = 1;

  config: NbToastrConfig;
  title = 'Create Department';
  content = 'Operation achieved, reload your page';
  duration = 2000;
  totalBudget = 0;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  hasBudget = false;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  loading = false;
  count;
  searchbyfields: string;

  id;

  list: Department[] = [];
  subList: SubDepartment [] =  [];
  @Output() addDep = new EventEmitter<Department>();
  total: string | number;
  constructor(private exportService: ExportService,
              private sds: SubDepartmentService,
              private ds: DepartmentService, private windowService: NbWindowService,
              private router: Router,
              private employeeService: EmployeeService,
              private dialogService: NbDialogService,
              private toastrService: NbToastrService, private globalBudgetService: GlobalBudgetServiceService) { }


  ngOnInit(): void {
    this.loading = true;  // Enable loading spinner at the start
    forkJoin({
      departments: this.ds.findAllDepts(),
      subDepartments: this.sds.findAll(),
      count: this.ds.countDepts(),
    }).pipe(
      finalize(() => this.loading = false) , // Disable loading spinner when all data is fetched
    ).subscribe({
      next: (results) => {
        this.list = results.departments.filter(department => department.isArchived === 'No');
        this.loadBudgets();
        this.count = count;
        this.subList = results.subDepartments;
        this.loadEmployeeDetails();
      },
      error: (err) => {
        console.error('Error loading data', err);
        this.toastrService.show('Error loading data', 'Data Load Error', { status: 'danger' });
      },
    });
  }
  calculateBudget(department: Department): number {
    return this.totalBudget * department.pourcentageBudget / 100;
  }
  loadEmployeeDetails() {
    this.list.forEach(department => {
      if (department.employee) {
        this.employeeService.getEmployee(department.employee).subscribe(employee => {
          department['employeeDetails'] = employee;
        });
      }
    });
  }
  loadBudgets() {
    this.globalBudgetService.findAllGlobalBudget().subscribe({
      next: (budgets) => {
        this.loading = false;
        if (budgets.length > 0) {
          const budget = budgets[0];
          this.totalBudget = budget.totalBudget;
          this.id = budget.id;
          this.hasBudget = true;  // Assuming you add 'hasBudget' to track if a budget exists
        } else {
          this.hasBudget = false;
        }
      },
      error: (error) => {
        console.error('Failed to load budgets', error);
        this.showToast('danger', 'Error', 'Failed to load budgets');
        this.loading = false;
      },
    });
  }
  createBudget() {
    const newBudget = new GlobalBudget();
    newBudget.totalBudget = this.totalBudget;
    this.globalBudgetService.createGlobalBudget(newBudget).subscribe({
      next: (budget) => {
        this.id = budget.id;
        this.showToast('success', 'Success', 'Budget created successfully');
        this.hasBudget = true;  // Update flag after creation
      },
      error: (error) => {
        console.error('Failed to create budget', error);
        this.showToast('danger', 'Error', 'Failed to create budget');
      },
    });
  }
  updateBudget() {
    if (!this.id) {
      console.log('No ID present, cannot update');
      return;
    }
    const budget = new GlobalBudget();
    budget.id = this.id;
    budget.totalBudget = this.totalBudget;
    this.globalBudgetService.updateGlobalBudget(this.id, budget).subscribe({
      next: () => this.showToast('success', 'Success', 'Budget updated successfully'),
      error: (error) => {
        console.error('Failed to update budget', error);
        this.showToast('danger', 'Error', 'Failed to update budget');
      },
    });
  }
  getDepartments() {
    this.ds.findAllDepts().subscribe(
      (data: Department[]) => { this.list = data.filter(department => department.isArchived === 'No');  } , (err) => {
        console.log(err);
      },
    );
  }

  getAllSubDepartments() {
    this.sds.findAll().subscribe(
      (data: SubDepartment[]) => { this.subList = data; }
      , (err) => {console.log(err); },
    );
  }

  openWindow(contentTemplate) {
    this.windowService.open(
      contentTemplate,
      {
        title: 'Create new department',
      },
    );

  }

  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(
      dialog,
      { context: 'Are you sure to delete this item ?' });
  }

  add_dep(d: Department) {
    this.addDep.emit(d);
  }

  delete_dep(department: Department) {
    this.ds.deleteDept(department).subscribe(
      (result) => {
        this.showToast('success', 'SUCESS', 'Deleted Successfuly');
      }, (err) => {
        this.showToast('danger', 'FAILURE', 'Could not delete department');
        console.log(err);
      },
    );
  }

  refresh(event: Department): void {
    console.log(event);
    this.list = [...this.list, event];
  }
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? `. ${title}` : '';
    this.toastrService.show(
      body,
      `Toast ${titleContent}`,
      config);
  }

  deleteSubDepartment(subDepartment: SubDepartment) {
    this.sds.deleteSubDepartment(subDepartment).subscribe(
      () => {
        this.showToast('success', 'SUCESS', 'Deleted Successfuly');
        // this.router.navigate(['/hr/department/list']).then(r => console.log(r));
        window.location.reload();
        this.getDepartments();
      },
    );
  }

  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.list, 'dataDepartments');
  }

  updateIsDepartmentArchived(department: Department, id: string) {
    this.ds.updateIsArchived(department, id).subscribe(
      (res) => {
        this.showToast('success', 'SUCESS', 'Item Is Archived');
        this.router.navigate(['/hr/department/grid']).then(() => {
          this.getDepartments();
         });
      }, (err) => {
        this.showToast('danger', 'Danger', err.data);
      },
    );
  }


}
