<div class="row">
  <div class="col-md-3 col-sm-12">
    <h3>
      Trainings History
    </h3>
  </div>
  <div class="col-md-5">
    <input class="" fullWidth [(ngModel)]="searchbyfields"  nbTooltip="Search Training" nbTooltipStatus="primary" [disabled]="trainings.length === 0" type="text"  fieldSize="large" nbInput placeholder="Search Training">
  </div>
  <div class="col-md-4 col-sm-8 mt-1">
    <button [disabled]="trainings.length === 0" nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" (click)="exportAsXLSX()"><i class="fas fa-file-excel text-white"></i>  </button>
    &nbsp;


    <br>
  </div>



</div>
<br>

<div class="row" *ngIf="!trainings || trainings.length === 0">
  <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
</div>
<div [hidden]="trainings.length === 0" class="row" *ngIf="(trainings | filter : searchbyfields).length === 0">
  <nb-alert outline="danger" class="w-100 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
</div>

<div class="row">

  <div class="col-md-3">
    <div class="row ">
      <div class="col-lg-9">
        <nb-form-field>
          <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <nb-select selected="0" (selectedChange)="filterByEmployee($event)"  fullWidth>
            <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Employee</nb-option>
            <nb-option value="All" (click)="getTrainingsClick()"> &nbsp;&nbsp;&nbsp;All</nb-option>
            <nb-option *ngFor="let employee of employees" value="{{employee.id}}">
              &nbsp;&nbsp;&nbsp; <nb-user [name]="employee.employeeName" [picture]="employee.employeeProfileImage"></nb-user>
            </nb-option>

          </nb-select>
        </nb-form-field>

      </div>
    </div>

    <div class="row ">
      <div class="col-lg-9">
        <nb-form-field>
          <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <nb-select selected="0" (selectedChange)="filterByObjects($event)"  fullWidth>
            <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Object</nb-option>
            <nb-option value="All" (click)="getTrainingsClick()"> &nbsp;&nbsp;&nbsp;All</nb-option>
            <nb-option *ngFor="let tr of trainings" value="{{tr.object}}">
              &nbsp;&nbsp;&nbsp; {{tr.object}}
            </nb-option>

          </nb-select>
        </nb-form-field>

      </div>
    </div>


    <div class="row mt-3">
      <div class="col-lg-9">
        <nb-form-field>
          <nb-icon nbPrefix class="mt-1" icon="funnel-outline"></nb-icon>
          <nb-select selected="0" placeholder="Cost" fullWidth>
            <nb-option value="0"> &nbsp;&nbsp;&nbsp;Cost</nb-option>
            <nb-option value="All" (click)="getTrainingsClick()"> &nbsp;&nbsp;&nbsp;All</nb-option>
            <nb-option (click)="filterByCostLess200()" value="Less 200 Then 200 DT"> Less Then 200 DT </nb-option>
            <nb-option (click)="filterByCostBetwwen200AND400()" value="Between 200 And 400 DT"> Between 200 And 400 DT </nb-option>
            <nb-option (click)="filterByCostBetwwen400AND800()" value="Between 400 And 800 DT"> Between 400 And 800 DT </nb-option>
            <nb-option (click)="filterByCostMoreThen800()" value="More Then 800 DT"> More Then 800 DT </nb-option>

          </nb-select>
        </nb-form-field>

      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <nb-form-field>
          <nb-icon nbPrefix class="mt-1" icon="flip-outline"></nb-icon>
          <nb-select selected="0" fullWidth>
            <nb-option value="0"> &nbsp;&nbsp;&nbsp;Object</nb-option>
            <nb-option value="All" (click)="getTrainingsClick()"> &nbsp;&nbsp;&nbsp;All</nb-option>
            <nb-option (click)="getAscObject()" value="Ascending"> Ascending </nb-option>
            <nb-option (click)="getDescObject()" value="Descending"> Descending </nb-option>
          </nb-select>
        </nb-form-field>

      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <nb-form-field>
          <nb-icon nbPrefix class="mt-1" icon="flip-outline"></nb-icon>
          <nb-select selected="0" fullWidth>
            <nb-option value="0"> &nbsp;&nbsp;&nbsp;Hours</nb-option>
            <nb-option value="All" (click)="getTrainingsClick()"> &nbsp;&nbsp;&nbsp;All</nb-option>
            <nb-option (click)="getAscHours()" value="Ascending"> Ascending </nb-option>
            <nb-option (click)="getDescHours()" value="Descending"> Descending </nb-option>
          </nb-select>
        </nb-form-field>

      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <nb-form-field>
          <nb-icon nbPrefix class="mt-1" icon="flip-outline"></nb-icon>
          <nb-select selected="0" placeholder="Cost" fullWidth>
            <nb-option value="0"> &nbsp;&nbsp;&nbsp;Cost</nb-option>
            <nb-option value="All" (click)="getTrainingsClick()"> &nbsp;&nbsp;&nbsp;All</nb-option>
            <nb-option (click)="getAscCost()" value="Ascending"> Ascending </nb-option>
            <nb-option (click)="getDescCost()" value="Descending"> Descending </nb-option>
          </nb-select>
        </nb-form-field>

      </div>
    </div>
  </div>
  <div class="col-md-9">
    <div class="row mt-3">

      <table class="table">
        <thead class="bg-light">
        <tr>
          <th>Employee</th>
          <th>Year</th>
          <th>Day</th>
          <th>Object</th>
          <th>Category</th>
          <th>Cost</th>
          <th>Estimated</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tr *ngFor="let training of trainings | filter : searchbyfields | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: total}">
          <td><nb-user [name]="training.employee?.employeeName"  picture="{{training.employee?.employeeProfileImage}}"></nb-user></td>
          <td><p>{{training.startDate | date : "YYYY"}}</p></td>
          <td><p>{{training.startDate | date : "dd MMM"}}</p></td>
          <td><p>{{training.object}}</p></td>
          <td><p>{{training.category}}</p></td>
          <td>
            <button size="small" nbButton shape="round" status="warning">
              {{training.cost}} {{"DT"}}
            </button>
          </td>
          <td>
            <button size="small" nbButton shape="round" status="primary">
              {{training.budget}} {{"DT"}}
            </button>
          </td>
          <td>
            <button shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <nb-icon icon="more-horizontal-outline"></nb-icon>
            </button>
            <mat-menu #menu="matMenu">

              <a nbTooltip="Click here to archive data" nbTooltipStatus="primary" (click)="TrainingRestored(training, training.id)">
                <button mat-menu-item>
                  <span><nb-icon class="text-secondary" icon="refresh-outline"></nb-icon>&nbsp; Restore</span>
                </button>
              </a>


            </mat-menu>
          </td>
        </tr>
      </table>

    </div>
    <!-- Pagination -->
    <div class="mt-4 d-flex justify-content-end">
      <nb-card style="height: 35px; border-radius: 25px; width: auto;">
        <pagination-controls class="pt-1 text-center" (pageChange)="current = $event"></pagination-controls>
      </nb-card>
    </div>
  </div>
</div>
