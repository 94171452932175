import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class FileServiceService {


  constructor(private http: HttpClient) { }

  getFiles(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.recruitment_hcm}api/files`);
  }

  downloadFile(id: string): Observable<Blob> {
    return this.http.get(`${environment.recruitment_hcm}api/files/download/${id}`, { responseType: 'blob' });
  }
}
