import {Company} from './Company';

export class Auth {
  token: string;
  type = 'Bearer';
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  resetPassword: string;
  password: string;
  matriculate: string;
  companyData: any;
  company: string;
  roles: any[];
  enabled: boolean;
  accountNonLocked: boolean;
  role: any;
  enableStatus: string;
  lockedStatus: string;
  accountEnabled: boolean;
  nonLocked: boolean;
  archiver: boolean;
  tempLocked: boolean;
  status: string;
}
