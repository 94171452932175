import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-recruitment-worflow',
  templateUrl: './recruitment-worflow.component.html',
  styleUrls: ['./recruitment-worflow.component.scss'],
})
export class RecruitmentWorflowComponent {

  constructor() { }
}
