import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbDialogConfig, NbDialogRef,
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbToastrService,
} from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {TokenStorageService} from '../../../modules/auth/service/token/token.service';
import {ConnectionService} from 'ng-connection-service';
import {EmployeeService} from '../../../modules/humain-capital-management/services/employeeServices/employee.service';
import { AuthService } from 'app/modules/auth/service/auth.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Users } from 'app/modules/auth/model/Users';
import {NotificatonComponent} from '../../../modules/humain-capital-management/components/notification/notificaton.component';
import {NotificationService} from '../../../modules/humain-capital-management/services/notificationService/notification.service';
import {Notifications} from '../../../modules/humain-capital-management/models/Notifications';
import {EmptyNotificationComponent} from '../../../modules/humain-capital-management/components/empty-notification/empty-notification.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  isConnected = true;
  downloadURL: Observable<string>;
  loading: boolean = false ;
  noInternetConnection: boolean;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  img: any;
  user1: Users;
  userid: String;
  notifications: Notifications[] = []; // Use the correct type here
  private dialogRef: NbDialogRef<any>; // Reference to the dialog

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];
  badgeText = '';
  currentTheme = 'default';

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private tokenStorageService: TokenStorageService,
              private toastrService: NbToastrService,
              private connectionService: ConnectionService,
              private emplService: EmployeeService,
              private dialogService: NbDialogService,
              private authService: AuthService,
              private storage: AngularFireStorage,
              private notificationService: NotificationService,


  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.noInternetConnection = false;
      } else {
        this.noInternetConnection = true;
      }
    });
  }

  ngOnInit() {
    this.notificationService.notifications$.subscribe(notifications => {
      this.notifications = notifications;
      this.updateNotificationBadge(); // This method should update the notification count/badge
    });
    this.currentTheme = this.themeService.currentTheme;

    this.user = this.tokenStorageService.getUser();
    this.userid = this.user.id;
    this.getUserDetails();


    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

  }
  updateNotificationBadge() {
    if (this.notifications && this.notifications.length > 0) {
      // Update the badge text with the number of notifications
      this.badgeText = this.notifications.length.toString();
    } else {
      // If there are no notifications, clear the badge text
      this.badgeText = this.notifications.length.toString();
    }
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  getUserDetails(): void {
    this.authService.getUser(this.userid)
      .subscribe((data: Users) => {
        this.user1 = data;
        if (this.user.role !== 'ROLE_SUPER_ADMIN') {
          if (this.user1.userProfileImage && this.user1.userProfileImage !== '') {
            this.img = this.user1.userProfileImage.toString();
          } else {
            this.img = 'assets/images/defaultuser.png';
          }
        } else {
          this.img = 'assets/images/logo/Manajero-logo.png';
        }
      });
  }
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  showToast(message, position, status) {
    this.toastrService.show(status || 'Success', message, { position, status });
  }





  notificationPopupOpen = false;

  notification: String[] = [

    'update sprint',

    'modify user story from user mohamed',

  ];

  /*showNotificationPopup() {

    // const dialogConfig: NbDialogConfig = {

    //   context: {

    //     // Contenu contextuel pour le dialogue

    //   },

    //   Width: '500px', // Définir la largeur maximale du dialogue

    // };

    this.dialogService.open(NotificatonComponent);

    // const notificationModalRef = this.modalService.show(NotificatonComponent, {

    //   animated: true,

    //   backdrop: 'static', // Clicking outside the modal will not close it

    //   keyboard: false, // Esc key will not close the modal

    // });




    // You can pass data to the notification component if needed

    // notificationModalRef.content.content.header = 'Nouvelle notification';

    // notificationModalRef.content.content =

    //   'Vous avez une nouvelle notification importante.';

  }*/


  showNotificationPopup() {
    if (this.dialogRef) {
      this.dialogRef.close(); // Close if already open
      this.dialogRef = null;
    } else {
      if (this.notifications.length === 0) {
        // If there are no notifications, show a message
        const dialogConfig: Partial<NbDialogConfig> = {
          context: { message: 'No notifications available.' },
          hasBackdrop: false,
          autoFocus: false,
          closeOnEsc: true,
          closeOnBackdropClick: true,
          dialogClass: 'notification-dialog',
        };

        this.dialogRef = this.dialogService.open(EmptyNotificationComponent, dialogConfig);
      } else {
        const dialogConfig: Partial<NbDialogConfig> = {
          context: {},
          hasBackdrop: false, // Disable backdrop
          autoFocus: false, // Prevent auto-focusing the dialog
          closeOnEsc: true, // Allow closing on ESC key
          closeOnBackdropClick: true, // Enable closing when clicking outside the dialog
        };

        this.dialogRef = this.dialogService.open(NotificatonComponent, dialogConfig);
      }
      this.dialogRef.onClose.subscribe(() => {
        this.dialogRef = null; // Reset the dialog reference when closed
      });
    }
  }


  closeNotificationPopup() {

    this.notificationPopupOpen = false;

  }

  getNotifications() {

    this.notificationService.getAllNotif().subscribe(

      (data) => {

        this.notifications = data;

      },

      (error) => {

        console.error('Une erreur s\'est produite lors de la récupération des notifications : ', error);

      },

    );

  }

}

