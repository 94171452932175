<div class="row">
  
  <div class="col-12">
    
    
    <div class="row">
        <div class="col-4">
            <h3>Recommended Training</h3>
        </div>
        <div class="col-6">
          <input type="text" fullWidth nbInput fieldSize="large">
        </div>
        <div class="col-2">
          <button status="danger"nbButton>
            <nb-icon icon="printer-outline"></nb-icon>
          </button>
        </div>
    </div>
    
    <div class="row">
      <div class="col-3">
          
      </div>
      <div class="col-9">
            <table class="table">
               
            </table>
      </div>
    </div>
    
    
  </div>
  
  
</div>