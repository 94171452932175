<nb-layout>
  <nb-layout-column>
    <div class="background">
      <div class="shape"></div>
      <div class="shape"></div>
    </div>

    <form [formGroup]="testForm" id="testForm" >

      <div class="header-container">
        <div class="count-down-timer">
          <div class="times">
            <span #minutes>mm</span>:<span #seconds>ss</span>
          </div>
        </div>
        <h4 style="color: #fcfcfc; flex-grow: 1; margin-left: -90px" >{{ currentTestType }} Knowledge Test</h4>
      </div>

      <div class="apps-scroll-container">
        <div id ="htmlData">
        <div *ngIf="testDetails">
          <!-- Example that might cause the error if `responseCandidat` is not properly initialized -->

          <div class="questions-container" formArrayName="questions">
            <div *ngFor="let questionControl of testForm.get('questions')['controls']; let qIndex = index" [formGroupName]="qIndex">
              <label class="question-label">{{ questionControl.get('questionText').value }}?</label>
              <div *ngFor="let response of questionControl.get('responses')['controls']; let rIndex = index" [formGroupName]="rIndex" class="response-container">
                <input class="input-radio" type="radio" [attr.name]="'responseCandidat' + qIndex" [value]="response.get('responseText').value"
                       (change)="questionControl.get('responseCandidat').setValue(response.get('responseText').value)">
                <label class="response-label">{{ response.get('responseText').value }}</label>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div style="display: block; width: 30%; margin: 20px auto;">
          <button (click)="submitTest()" nbButton status="primary" hero>
            Submit Test
          </button>
        </div>
      </div>


    </form>

  </nb-layout-column>
</nb-layout>
