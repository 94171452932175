import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Test} from '../../models/Test';
import {map} from 'rxjs/operators';


const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }),
  'responseType': 'text as json',
};
@Injectable({
  providedIn: 'root',
})
export class TestService {

  private testDetailsSource = new BehaviorSubject<Test>(null);

  currentTestDetails = this.testDetailsSource.asObservable();

  private apiUrl = `${environment.recruitment_hcm}test/`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };


  constructor(private httpclient: HttpClient) { }

  // In TestService
  getTestDetailsByType(type: string): Observable<Test> {
    return this.httpclient.get<Test>(`${this.apiUrl}getByType/${type}`);
  }


  updateTestDetails(test: Test) {
    console.log('ServiceTest: Received test', test);  // Confirm this logs
    this.testDetailsSource.next(test);
  }

  findAllTests(): Observable<Test[]> {
    return this.httpclient.get<Test[]>(this.apiUrl + 'all');
  }

  getAllTestTypes(): Observable<string[]> {
    return this.findAllTests().pipe(
      map(tests => {
        const types = tests.map(test => test.type);
        return [...new Set(types)]; // Return only unique types
      }),
    );
  }

  createTest(test: Test): Observable<Test> {
    return this.httpclient.post<Test>(this.apiUrl + 'create', test, this.httpOptions);
  }

  updateTest(candidatId: string, type: string, test: Test): Observable<Test> {
    return this.httpclient.put<Test>(this.apiUrl + `update/${candidatId}/${type}`, test, this.httpOptions);
  }

  deleteTestById(id: string): Observable<string> {
    return this.httpclient.delete<string>(this.apiUrl + `delete/${id}`, this.httpOptions);
  }


  checkTestExistence(candidatId: string, testType: string): Observable<boolean> {
    return this.httpclient.get<boolean>(`${this.apiUrl}exists?candidatId=${candidatId}&type=${testType}`);
  }

  canUpdateTest(candidatId: string, testType: string): Observable<boolean> {
    return this.httpclient.get<boolean>(`${this.apiUrl}canUpdateTest?candidatId=${candidatId}&type=${testType}`);

  }
  checkTestExistenceByType(testType: string): Observable<boolean> {
    return this.httpclient.get<boolean>(`${this.apiUrl}existsByType?type=${testType}`);
  }

  getTestsByCandidateId(candidateId: string): Observable<Test[]> {
    return this.httpclient.get<Test[]>(`${this.apiUrl}candidate/${candidateId}`);
  }

  uploadPdf(candidatId: string, type: string, file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('pdf', file, file.name);
    formData.append('candidatId', candidatId);
    formData.append('type', type);

    return this.httpclient.post<any>(`${this.apiUrl}uploadPdf`, formData);
  }
  getTestByCandidateAndType(candidateId: string, type: string): Observable<Test> {
    return this.httpclient.get<Test>(`${this.apiUrl}getByType/${type}?candidateId=${candidateId}`);
  }

  downloadPdf(pdfUrl: string): Observable<Blob> {
    return this.httpclient.get(pdfUrl, { responseType: 'blob' });
  }


}
