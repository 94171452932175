<div class="container">
    <div class="row">
        <div class="col-md-11">
            <h3>Add New Skill</h3>
        </div>
        <div class="col-md-1">
            <a style="float: right;" nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/hr/skills/grid']" nbButton>
                <nb-icon icon="corner-up-right-outline"></nb-icon>
         </a>
        </div>
    </div>

    <form [formGroup]="formSkills">
    <div class="row mt-3">
        <div class="col-md-12">
            <label for="">Skill Name</label>
        </div>
        <div class="col-md-12">
            <input [(ngModel)]="skill.skillName" name="skillName" id="skillName" formControlName="skillName" type="text" nbInput fullWidth fieldSize="large"/>
        </div>
      
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <label for="">Experience</label>
        </div>
        <div class="col-md-12">
            <input [(ngModel)]="skill.experience" id="experience" name="experience" formControlName="experience" type="text" nbInput fullWidth fieldSize="large"/>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <label for="">Score</label>
        </div>
        <div class="col-md-12">
            <input [(ngModel)]="skill.score" id="score" name="score" formControlName="score" type="text" nbInput fullWidth fieldSize="large"/>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <label for="">Employee</label>
        </div>
        <div class="col-md-12">
            <nb-select [selected]="selectedItem"
            formControlName="employee"
            name="employee"
            nbTooltip="Select the right employee" nbTooltipStatus="primary"
            id="employee"
            fullWidth
            size="large"
            placeholder="select Employee"
            (selectedChange)="getEmployeeSelected($event)">
    <nb-option *ngFor="let x of list" value="{{x.id}}">
        <nb-user [picture]="x.employeeProfileImage" [name]="x.employeeName"></nb-user>
    </nb-option>

</nb-select>

        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3" align="right">
            <button (click)="addNew()" [disabled]="formSkills.invalid" nbButton status="success">
                <nb-icon icon="checkmark-circle-outline"></nb-icon>   Save Data 
            </button>
        </div>
    </div>
    </form>

</div>