import {Component, OnInit, TemplateRef} from '@angular/core';
import {
  NbComponentStatus, NbDialogService,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig, NbToastrService,
  NbWindowService,
} from '@nebular/theme';
import {NgxPrintElementService} from 'ngx-print-element';
import {Employee} from '../../../models/Employee';
import {Department} from '../../../models/Department';
import {EmployeeService} from '../../../services/employeeServices/employee.service';
import {DepartmentService} from '../../../services/departmentservices/department.service';
import GoogleCountries from '../../../services/googlecountries.json';
import {ExportService} from '../../../../../shared/exports/export.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {SubDepartmentService} from '../../../services/departmentservices/subDepartmentService/sub-department.service';
import {SubDepartment} from '../../../models/SubDepartment';

@Component({
  selector: 'ngx-table-employees',
  templateUrl: './table-employees.component.html',
  styleUrls: ['./table-employees.component.scss'],
})
export class TableEmployeesComponent implements OnInit {

  myDate = new Date().toDateString();

  public config_ = {
    printMode: 'template-popup',
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'Employees Data',
    templateString: `<header>Employees Data \n : Date ${this.myDate} </header>{{printBody}}`,
    stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],
    styles: ['.table { color: black; }', '.table td { color: black; }' , '.table .printbtn {height:25px;width:25px;}'],
  };

  pageSize: number = 10;

  config: NbToastrConfig;
  title = 'Create SubDepartment';
  content = 'Operation achieved, reload your page';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;

  current = 1;
  list: Employee[] = [];


  listd: SubDepartment[] = [];
  countries: any [] = [];

  NotAssignedYet: string = 'Not Assigned';

  searchbyfields: string;
  loading = false;
  constructor(private router: Router, private datePipe: DatePipe,
              private exportService: ExportService, private es: EmployeeService,
              private ds: SubDepartmentService, private ws: NbWindowService ,
              public print: NgxPrintElementService ,
              private dialogService: NbDialogService , private toastrService: NbToastrService) {
    this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

  }
  ngOnInit(): void {
    this.loading = true;  // Activate spinner at the start of data fetching
    forkJoin({
      employees: this.es.findAll(),  // Assuming findAll returns Observable<Employee[]>
      subDepartments: this.ds.findAll() , // Assuming findAllDepts returns Observable<Department[]>
    }).pipe(
      finalize(() => this.loading = false),  // Deactivate spinner once all requests are done
    ).subscribe({
      next: (results) => {
        this.countries = GoogleCountries;
        this.list = results.employees;  // Assign fetched employees to list
        this.listd = results.subDepartments;  // Assign fetched departments to listd
      },
      error: (err) => {
        console.error('Error loading data', err);
        this.toastrService.show('Error loading data', 'Data Load Error', { status: 'danger' });
      },
    });
  }
  getAllEmps() {
    this.es.findAll().subscribe(
      (data: Employee[]) => { this.list = data;  }, (err) => {
        console.log(err);
      },
    );
  }

  filtre(e, x) {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        this.list = [];
        this.list = data.filter(
          (empl =>
              x === 'Gender' ? empl.employeeGender === e : empl.employeeCountry === e
          ),

        );
      }, (err) => {
        return err;
      });
  }
  filtreRole(e, x) {
    this.es.findAllEmployeesAsc().subscribe(
      (data: Employee[]) => {
        this.list = [];
        console.log(e);
        this.list = data.filter(
          (empl =>
              x === 'Role' ? empl.roleEmployee === e : null
          ),

        );
      }, (err) => {
        return err;
      });
  }

  filtreSubDepartment(d) {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        this.list = [];
        this.list = data.filter(
          (empl =>
              // @ts-ignore
              ( empl?.subDepartment?.id === d   )
          ),

        );
      }, (err) => {
        return err;
      });
  }
  getEmpsByGender(e, x) {
    this.filtre(e, x);
  }

  getEmpsByRole(e, x) {
    this.filtreRole(e, x);
  }

  getEmpsBySubDepartment(d) {
    this.filtreSubDepartment(d);
  }


  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(
      dialog,
      { context: 'Are you sure to delete this item ?' });
  }

  deleteEmp(employee: Employee) {
    this.es.deleteEmployeeById(employee).subscribe(
      (result) => {
        this.showToast('success', 'SUCESS', 'Deleted Successfuly');
        window.location.reload();
      }, (err) => {
        this.showToast('danger', 'FAILURE', 'Could not delete Employee');
        console.log(err);
      },
    );
  }

  refresh(): void {
    window.location.reload();
  }
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? `. ${title}` : '';
    this.toastrService.show(
      body,
      `Toast ${titleContent}`,
      config);
  }

  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.list, 'dataEmployees');
  }



  sortByNamesAsc() {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        this.list = data.sort((a, b) => a['employeeName']
          .localeCompare(b['employeeName'])); }, (err) => {
        console.log(err);
      },
    );
  }

  sortByNamesDesc() {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        this.list = data.sort((a, b) => b['employeeName']
          .localeCompare(a['employeeName'])); }, (err) => {
        console.log(err);
      },
    );
  }

  updateIsEmployeeArchived(employee: Employee, id: string) {
    this.es.updateIsArchived(employee, id).subscribe(
      (res) => {
        this.showToast('success', 'SUCESS', 'Item Is Archived');
        this.router.navigate(['/hr/employee/table']).then(() => {
          this.getAllEmps();
         });
      }, (err) => {
        this.showToast('danger', 'Danger', err.data);
      },
    );
  }



}
