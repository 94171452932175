import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from 'app/modules/auth/service/auth.service';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../modules/auth/service/token/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authservice: AuthService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let isLoggedIn: boolean = false;

     if (this.tokenStorageService.getToken() != null) { isLoggedIn = true; } else {
       this.router.navigate(['/auth/login']);
       isLoggedIn = false;
      }
    return isLoggedIn;
  }
}
