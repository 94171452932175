import { Component, OnInit } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrConfig, NbToastrService } from '@nebular/theme';
import { Employee } from '../../../models/Employee';
import { Training } from '../../../models/Training';
import { EmployeeService } from '../../../services/employeeServices/employee.service';
import { TrainingService } from '../../../services/trainingServices/training.service';
import {Router} from '@angular/router';
import { forkJoin } from 'rxjs';
import {ExportService} from '../../../../../shared/exports/export.service';

import { finalize } from 'rxjs/operators';
@Component({
  selector: 'ngx-grid-trainings',
  templateUrl: './grid-trainings.component.html',
  styleUrls: ['./grid-trainings.component.scss'],
})
export class GridTrainingsComponent implements OnInit {

  employees: Employee[] = [];
  searchbyfields = '';
  DT: string = 'Hrs';
  config: NbToastrConfig;
  title = 'Data Loaded Successfuly';
  content = 'Operation achieved';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  loading: boolean = false;

  trainings: Training[] = [];
  pageSize = 4;
  current = 1;

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? ` ${title}` : '';
    this.toastrService.show(
      body,
      ` ${titleContent}`,
      config);
  }

  constructor(private es: EmployeeService, private trainingService: TrainingService,
              private toastrService: NbToastrService, private exportService: ExportService,
              private router: Router) { }

  ngOnInit(): void {
    this.loading = true; // Start loading
    forkJoin([
      this.es.findAll(),
      this.trainingService.findAll(),
    ]).pipe(
      finalize(() => this.loading = false), // Stop loading
    ).subscribe({
      next: ([employees, trainings]) => {
        this.employees = employees;
        this.trainings = trainings.filter((e) => e.isArchived === 'No');
        this.showToast('success', 'SUCCESS', 'Data Loaded Successfully');
      },
      error: (err) => {
        console.error('Error loading data', err);
        this.showToast('danger', 'ERROR', 'Error while retrieving data');
      },
    });
  }

  getTrainings() {
    this.trainingService.findAll().subscribe(
      (data: Training[]) => {
        // Filter data to include only non-archived trainings
        this.trainings = data.filter((e) => e.isArchived === 'No');

        // Optional: Log the filtered list or the complete data
        console.log('Filtered List:', this.trainings);
        console.log('Complete Data:', data);
      },
      (err) => {
        // Log the error in the console
        console.log('Error While Loading Data:', err);

        // Show toast message on error
        this.showToast('danger', 'DANGER', 'Error While Loading Data');
      },
    );
  }

  getAllEmps() {
    this.es.findAll().subscribe(
      (data: Employee[]) => { this.employees = data; }, (err) => {
        console.log(err);
      },
    );
  }


  getTrainingsClick() {
    this.trainingService.findAll().subscribe((data: Training[]) => {
      this.trainings = data;
      this.showToast('success', 'SUCESS', 'Data Loaded Successfuly');
    }, () => {
      this.showToast('danger', 'DANGER', 'Error While Loading Data');
    });
  }

  filterByEmployee(e) {
    this.trainingService.findAll().subscribe(
      (data: Training[]) => {
        this.trainings = [];
        this.trainings = data.filter(
          (d =>
              // @ts-ignore
              d?.employee?.id === e
          ),
        );
          this.showToast('success', 'SUCCESS', 'You have launched a filtering operation');
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }

  filterByCostLess200() {
    this.trainingService.findAll().subscribe(
      (data: Training[]) => {
        this.trainings = [];
        this.trainings = data.filter(
            (d =>
                d.cost <= 200
            ),
          );


          this.showToast('success', 'SUCCESS', 'You have launched a filtering operation');
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }

  filterByCostBetwwen200AND400() {
    this.trainingService.findAll().subscribe(
      (data: Training[]) => {
        this.trainings = [];
        this.trainings = data.filter(
            (d =>
                d.cost >= 200 && d.cost <= 400
            ),
          );


          this.showToast('success', 'SUCCESS', 'You have launched a filtering operation');
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }

  filterByCostBetwwen400AND800() {
    this.trainingService.findAll().subscribe(
      (data: Training[]) => {
        this.trainings = [];
        this.trainings = data.filter(
            (d =>
                d.cost >= 400 && d.cost <= 800
            ),
          );


          this.showToast('success', 'SUCCESS', 'You have launched a filtering operation');
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }

  filterByCostMoreThen800() {
    this.trainingService.findAll().subscribe(
      (data: Training[]) => {
        this.trainings = [];
        this.trainings = data.filter(
            (d =>
                d.cost >= 800
            ),
          );


          this.showToast('success', 'SUCCESS', 'You have launched a filtering operation');
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }


  filterByStartDate(e) {
    this.trainingService.findAll().subscribe(
      (data: Training[]) => {
        this.trainings = [];
        this.trainings = data.filter(
            (d =>
                d.startDate === e
            ),
          );


          this.showToast('success', 'SUCCESS', 'You have launched a filtering operation');
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }

  filterByObjects(e) {
    console.log(e);

    this.trainingService.findAll().subscribe(
      (data: Training[]) => {
        this.trainings = [];
        this.trainings = data.filter(
            (d =>
                // @ts-ignore
                d.object === e
            ),
          );


          this.showToast('success', 'SUCCESS', 'You have launched a filtering operation');
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }

  filterByHours(e) {
    this.trainingService.findAll().subscribe(
      (data: Training[]) => {
        this.trainings = [];
        this.trainings = data.filter(
            (d =>
                d.nbHours === e
            ),
          );


          this.showToast('success', 'SUCCESS', 'You have launched a filtering operation');
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }


  getAscObject() {
    this.trainings = this.trainings.sort(
      (a, b) =>
        a['object'].localeCompare(b['object']),
      );
  }

  getDescObject() {
    this.trainings = this.trainings.sort(
      (a, b) =>
       b['object'].localeCompare(a['object']),
      );
  }



  getAscHours() {
    this.trainings = this.trainings.sort(
      (a, b) =>
        a.nbHours - b.nbHours,
      );
  }

  getDescHours() {
    this.trainings = this.trainings.sort(
      (a, b) =>
        b.nbHours - a.nbHours,
      );
  }


  getAscCost() {
    this.trainings = this.trainings.sort(
      (a, b) =>
        a.nbHours - b.nbHours,
      );
  }

  getDescCost() {
    this.trainings = this.trainings.sort(
      (a, b) =>
        b.nbHours - a.nbHours,
      );
  }
  TrainingArchived(training: Training, id: string) {
    this.trainingService.archive(training, id).subscribe(
      (res) => {
        this.showToast('success', 'SUCESS', 'Item Is Archived');
        this.router.navigate(['/hr/trainings/grid']).then(() => {
          this.getTrainings();
        });
      }, (err) => {
        this.showToast('danger', 'Danger', err.data);
      },
    );
  }
  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.trainings, 'Trainings');
  }

}
