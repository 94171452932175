
    <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="row">
            <h2>
              Employees History
                <nb-icon nbTooltip="Click here to reload data" nbTooltipStatus="primary" nbButton status="info"  shape="round" class="ion-loop ng-star-inserted" (click)="refresh()"></nb-icon>
          </h2>

          </div>

        </div>

        <div class="col-md-8" align="right">
          <input type="text" class="w-75" size="large" [disabled]="list.length === 0" nbInput  [(ngModel)]="searchbyfields"   placeholder="Search Employee">
          &nbsp;
            &nbsp;
          <button [disabled]="list.length === 0" nbTooltip="Export to Excel file" nbTooltipStatus="primary" nbButton status="success"  (click)="exportAsXLSX()"><i class="fas fa-file-excel text-white"></i>  </button>
          &nbsp;



        </div>



      <div class="row">
        <div class="row w-100" *ngIf="!list || list.length === 0">
          <nb-alert outline="danger" fullWidth class="w-100 mx-5 mt-3">No Data Found.</nb-alert>
        </div>
        <div [hidden]="list.length === 0" class="row w-100" *ngIf="(list | filter : searchbyfields).length === 0">
          <nb-alert outline="danger"  class="col-lg-12 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
        </div>
      <div class="input-group mt-3">


            <div class="col-lg-2 col-md-2 mt-1">
              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                <nb-select (selectedChange)="getEmpsByGender($event,'country')"  selected="0" class="w-100 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp;Country</nb-option>
                  <nb-option value="All" (click)="getEmpsAsc()">All</nb-option>
                  <nb-option value="{{country.country_name}}" *ngFor="let country of countries">{{country.country_name}}</nb-option>

                </nb-select>
              </nb-form-field>


              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                <nb-select (selectedChange)="getEmpsByRole($event, 'Role')" selected="0" class="w-100 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp;Role</nb-option>
                  <nb-option value="All" (click)="getEmpsAsc()">All</nb-option>
                  <nb-option value="Commercial">&nbsp;&nbsp;&nbsp;Commercial</nb-option>
                  <nb-option value="Manager">&nbsp;&nbsp;&nbsp;Manager</nb-option>
                  <nb-option value="Employee">&nbsp;&nbsp;&nbsp;Employee</nb-option>
                </nb-select>
              </nb-form-field>



              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                <nb-select (selectedChange)="getEmpsByGender($event, 'Gender')" selected="0" class="w-100 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp;Gender</nb-option>
                  <nb-option value="Homme" >&nbsp;&nbsp;&nbsp;Homme</nb-option>
                  <nb-option value="Femme" >&nbsp;&nbsp;&nbsp;Femme</nb-option>

                </nb-select>
              </nb-form-field>



              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                <nb-select  (selectedChange)="getEmpsByDepartment($event, 'Department')" selected="0" class="w-100 mt-3" >
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp;Department</nb-option>
                  <nb-option value="" *ngFor="let dep of listd">&nbsp;&nbsp;&nbsp;{{dep.departmentName}}</nb-option>

                </nb-select>
              </nb-form-field>

              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
                <nb-select  selected="0" class="w-100 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp;Name</nb-option>
                  <nb-option value="1" (click)="getEmpsAsc()">&nbsp;&nbsp;&nbsp;Names (A-Z)</nb-option>
                  <nb-option value="2" (click)="getEmpsDesc()">&nbsp;&nbsp;&nbsp;Names (Z-A)</nb-option>
                </nb-select>
              </nb-form-field>


              <div class="row mt-3">
                <div class="col-lg-12">
                   <label>Items Per Page &nbsp; : &nbsp; </label> <br> <input class="w-100" [disabled]="list.length === 0" type="number" nbInput [(ngModel)]="pageSize" min="10">
                </div>
              </div>
            </div>


        <div class="col-md-9">
          <div class="row mt-3">
                  <table class="table" id="employees" style="width: 100%; min-width: 1000px;">
                    <thead class="bg-light">
                      <tr>
                        <th>Name</th>
                        <th>Cell Phone</th>
                        <th>Gender</th>
                        <th>Country</th>
                        <th>Department</th>
                        <th>Position</th>
                      <th class="print-none">Actions</th>
                    </tr>
                    </thead>
                  <tbody>
                      <tr class="col-md-10 col-sm-10" *ngFor="let emp of list | filter : searchbyfields | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: total}">

                        <td>
                          <div class="d-flex align-items-center">
                            <nb-user [name]="emp.employeeName" onlyPicture  picture="{{emp.employeeProfileImage}}"></nb-user>
                            <div class="ms-3">
                              <p class=" mb-1">{{emp.employeeName}}</p>
                              <p class=" mb-0">{{emp.employeeEmail}}</p>
                            </div>
                          </div>
                        </td>
                        <td><p class="fw-normal mb-1">{{emp.employeeCellPhone}}</p> </td>
                        <td><p class="fw-normal mb-1">{{emp.employeeGender}}</p> </td>
                        <td><p class="fw-normal mb-1">{{emp.employeeCountry}}</p></td>
                          <td>

                                                <nb-tag-list>
                                                  <nb-tag *ngIf="emp?.department?.departmentName !== null ; else data" status="basic" size="small" [text]="emp?.department?.departmentName"></nb-tag>
                                                   <ng-template #data>&nbsp;
                                                  <nb-tag  status="warning" size="small" [text]="NotAssignedYet"></nb-tag>
                                                  </ng-template>
                                                </nb-tag-list>

                                          </td>
                                          <td>
                                                <nb-tag-list>
                                                  <nb-tag *ngIf="emp?.roleEmployee !== null ;" status="primary" size="small" [text]="emp?.roleEmployee"></nb-tag>

                                                </nb-tag-list>
                                          </td>
                                    <td class="print-none">
                                      <button class="printbtn" shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <nb-icon icon="more-horizontal-outline"></nb-icon>
                                      </button>
                                      <mat-menu #menu="matMenu">



                                         <a  (click)="deleteEmp(emp.id)">
                                            <button mat-menu-item>
                                                <span><nb-icon class="text-danger" icon="trash-outline"></nb-icon>&nbsp; Delete</span>

                                            </button>
                                        </a>
                                        <a>
                                            <button mat-menu-item (click)="updateIsEmployeeRestored(emp, emp.id)">
                                                <span><nb-icon class="text-secondary" icon="refresh-outline"></nb-icon>&nbsp; Restore</span>

                                            </button>
                                        </a>
                                       <!--  <ng-template #dialog let-data let-ref="dialogRef">
                                            <nb-card>
                                              <nb-card-header>Confirm Delete Operation</nb-card-header>
                                              <nb-card-body>Are you sure you want to delete an employee having reference {{emp.employeeReference}}</nb-card-body>
                                              <nb-card-footer>
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <button  nbButton (click)="ref.close()">
                                                      <nb-icon icon="close-outline"></nb-icon>
                                                      Close</button>
                                                  </div>
                                                  <div class="col-md-5"></div>
                                                  <div class="col-md-3">
                                                    <button status="danger" nbButton (click)="deleteEmp(emp.id)">
                                                      <nb-icon icon="trash-outline"></nb-icon>
                                                      Delete</button>
                                                  </div>
                                                </div>
                                              </nb-card-footer>
                                            </nb-card>
                                          </ng-template> -->
                                      </mat-menu>
                                    </td>
                      </tr>
                  </tbody>
                  </table>
          </div>
        <!-- Modifie cette partie pour la pagination -->
          <div class="mt-4 d-flex justify-content-end">
            <nb-card class="" style="height: 35px;margin-left: 0; border-radius: 25px;">
              <pagination-controls class="pt-1 text-center" (pageChange)="current = $event"></pagination-controls>
            </nb-card>
          </div>
              </div>

      </div>
    </div>
      </div>
