<div class="row">
  <div class="col-lg-12">
    <h3>HR Dashboard</h3>
  </div>
</div>
<div class="card mt-2">
  <iframe title="HR Dashboard" width="1200" height="550"
          src="https://app.powerbi.com/reportEmbed?reportId=71880428-2107-4c1d-9d04-56ce856bf42e&autoAuth=true&ctid=09d826a0-1227-49a3-9ca2-0a4f60d5a923&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWFmcmljYS1ub3J0aC1hLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&fbclid=IwAR34UzWB52hujSSNWuKw5IR42mGUfaga-wNyN4q24BRHkDqRJl-ZKfEyszU"
          frameborder="0" allowFullScreen="true"></iframe>
</div>
