import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Matrice} from '../../models/Matrice';
import {environment} from '../../../../../environments/environment';

const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }),
  'responseType': 'text as json',
};

@Injectable({
  providedIn: 'root',
})
export class MatriceServiceService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private httpclient: HttpClient) { }

  findAllMatrice(): Observable<[Matrice]> {
    return this.httpclient.get<[Matrice]>(`${environment.employees_hcm}matrice/all`);
  }

  addMatrice(matrice: {
    renumeration: string;
    competence9: string;
    salaire: string;
    niveauCollaboration: string;
    competence3: string;
    competence4: string;
    competence1: string;
    competence2: string;
    competence7: string;
    competence8: string;
    genre: string;
    competence5: string;
    competence6: string;
    age: string;
    contractType: string;
    department: string;
    employee: string
  }) {
    return this.httpclient.post<Matrice>(
      `${environment.employees_hcm}matrice/create`, matrice, this.httpOptions);
  }

  updateMatrice(id: string, matrice: Matrice): Observable<Matrice> {
    const path = `${environment.employees_hcm}matrice/update/` + id ;
    // @ts-ignore
    return this.httpclient.put<Matrice>(path , matrice , this.httpOptions);
  }
  deleteMatrice(matrice: Matrice | string): Observable<string> {
    const id = typeof matrice === 'string' ? matrice : matrice.id;
    console.log('Matrice ID:', id);  // Check what ID is being passed
    const path = `${environment.employees_hcm}matrice/delete/${id}`;
    console.log('Delete URL:', path);
    // @ts-ignore
    return this.httpclient.delete<string>(path, httpOptionsPlain);
  }

}
