import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  NbComponentStatus, NbDialogService,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig, NbToastrService,
  NbWindowService,
} from '@nebular/theme';
import {Employee} from '../../../models/Employee';
import {EmployeeData} from '../../../models/EmployeeData';
import {Department} from '../../../models/Department';
import {ExportService} from '../../../../../shared/exports/export.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EmployeeService} from '../../../services/employeeServices/employee.service';
import {DepartmentService} from '../../../services/departmentservices/department.service';
import {forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';
import GoogleCountries from '../../../services/googlecountries.json';
import {SubDepartment} from '../../../models/SubDepartment';
import {SubDepartmentService} from '../../../services/departmentservices/subDepartmentService/sub-department.service';

@Component({
  selector: 'ngx-employees-department',
  templateUrl: './employees-department.component.html',
  styleUrls: ['./employees-department.component.scss'],
})
export class EmployeesDepartmentComponent implements OnInit {

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  current: number = 1;

  config: NbToastrConfig;
  title = 'Create Department';
  content = 'Operation achieved, reload your page';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  departmentsMap: Map<string, string> = new Map(); // Map to store department IDs and names
  list: Employee[] = [];
  employees: EmployeeData[] = [];
  listd: Department[] = [];
  countries: any [] = [];


  pageSize: number = 4;

  NotAssignedYet: string = 'Not Assigned Yet.';

  searchbyfields: string;
  total: string | number;
  loading = false;
  departmentId: string;
  subDepartmentId: string;

  constructor(private exportService: ExportService, private router: Router,
              private route: ActivatedRoute,
              private es: EmployeeService, private ds: DepartmentService,
              private sub: SubDepartmentService,
              private ws: NbWindowService , private dialogService: NbDialogService ,
              private toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.loading = true; // Set loading to true at the beginning
    this.route.params.subscribe({
      next: params => {
        this.departmentId = params['departmentId'];
        console.log('Department ID:', this.departmentId);
        this.loadEmployeesByDepartment( this.departmentId);
        // If subDepartmentId is not defined or conversion to number results in NaN, do not proceed.
        if (!this.departmentId) {
          console.error('No Department ID provided');
          this.loading = false;
          return;
        }
      },

      error: err => {
        console.error('Error with route parameters:', err);
        this.loading = false;
      },
    });
  }
 /* loadEmployeesByDepartment(departmentId: string) {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        console.log('All Employees:', data);  // Log all data
        // Correctly filter employees by comparing department IDs
        this.list = data.filter(employee => employee.subDepartment.department && employee.subDepartment.department.id === departmentId && employee.roleEmployee === 'Employee');
        console.log('Filtered Employees:', this.list);  // Log filtered data
      },
      error => {
        console.error('Error fetching employees:', error);
        this.toastrService.show(
          'Error fetching employees: ' + error,
          'Error',
          { status: 'danger', duration: this.duration },
        );
      },
    );
  }*/

  loadEmployeesByDepartment(departmentId: string) {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        console.log('All Employees:', data);  // Log all data
        this.list = data.filter(employee => employee.subDepartment?.department?.id === departmentId && employee.roleEmployee === 'MANAGER');
        console.log('Filtered Departments:', this.list);  // Log filtered data

        // Fetch employee details for each manager found
        this.list.forEach(manager => {
          if (manager.id) {  // Assuming 'id' is the correct property to reference the employee uniquely
            this.es.getEmployee(manager.id).subscribe(
              (employeeDetails: Employee) => {
                manager['employeeDetails'] = employeeDetails;  // Store additional details in a property on the manager
              },
              error => {
                console.error('Error fetching employee details:', error);
              },
            );
          }
        });

        this.loading = false;
      },
      error => {
        console.error('Error fetching departments:', error);
        this.loading = false;
        this.toastrService.show(
          'Error fetching departments: ' + error,
          'Error',
          { status: 'danger', duration: this.duration },
        );
      },
    );
  }





  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.list, 'dataEmployees');
  }


}
