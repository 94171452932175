<div class="row">
    <div class="col-md-8">
        <h3>Create New </h3>
    </div>
    <div class="col-md-4">

        <a class="mx-3
        " style="float: right;" nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/hr/department/list']" nbButton>
            <nb-icon icon="corner-up-right-outline"></nb-icon>
        </a>

        <a style="float: right;" nbTooltip="Go to sub departments" nbTooltipStatus="primary"  status="info" [routerLink]="['/hr/subdepartment/subList']" nbButton>
            Sub Departments
        </a>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <form [formGroup]="subForm">
            <label for="">Name</label><br>
            <input class="form-control" fullWidth
            fieldSize="large"
            nbTooltip="Enter your sub department name" nbTooltipStatus="primary"
            formControlName="subDepartmentName"
            name="subDepartmentName"
            [(ngModel)]="subDept.subDepartmentName"
            id="subDepartmentName" type="text" nbInput/>  <br>
            <label>Department</label><br>
            <nb-select [selected]="selectedItem"
                        [(ngModel)]="subDept.department"
                        formControlName="department"
                        name="department"
                        nbTooltip="Select the right department" nbTooltipStatus="primary"
                        id="department"
                        fullWidth
                        fieldSize="large"
                        placeholder="select department"
                        (selectedChange)="getDepartmentSelected($event)">
                <nb-option *ngFor="let x of list_dept" value="{{x.id}}">{{x.departmentName}}</nb-option>

            </nb-select>


            <div style="float: right;" class="mt-2">
                <button nbTooltip="Click here to create a new sub department" nbTooltipStatus="primary"
                  nbButton [disabled]="subForm.invalid" (click)="storeSubDepartment()" status="success">
                  <nb-icon icon="checkmark-circle-outline"></nb-icon>  Save new data
                </button>
            </div>


        </form>
    </div>

</div>

