import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-confirmation-mail-profile',
  templateUrl: './confirmation-mail-profile.component.html',
  styleUrls: ['./confirmation-mail-profile.component.scss'],
})
export class ConfirmationMailProfileComponent {

  constructor() { }

}
