<div class="row">
  <div class="col-md-3 col-sm-12">
      <h3 class="more-vertical">
        Leaves Requests
      </h3>
  </div>
  <div class="col-md-4">
    <input fullWidth class=" mx-2"  nbTooltip="Fill this field to find department" nbTooltipStatus="primary" type="text"  fieldSize="large" nbInput placeholder="search by fields" >
  </div>
  <div class="col-md-3 col-sm-8" align="right">
      <a nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" [routerLink]="['/hr/absences/allLeavesRequestsGridView']" nbButton status="primary"> <nb-icon icon="grid-outline"></nb-icon>   </a>
      &nbsp;
      <a nbTooltip="Visualize data on dimensions grid 3x3" nbTooltipStatus="primary" [routerLink]="['/hr/absences/allLeavesRequestsKeypadView']" nbButton status="warning"> <nb-icon icon="keypad-outline"></nb-icon>   </a>
      &nbsp;
      <a [routerLink]="['/hr/absences/allLeavesRequestsTableView']" nbTooltip="Visualize data in table" nbTooltipStatus="primary" nbButton status="basic"> <nb-icon icon="list-outline"></nb-icon>   </a>
      &nbsp;
    <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" (click)="exportAsXLSX()" ><i class="fas fa-file-excel text-white"></i>  </button>
      &nbsp;


      <br>
</div>
<div class="col-md-2 mt-1">
  <nb-tag text={{calculateSumDuration()}} status="basic" appearance="filled"></nb-tag>
</div>
</div>
<div class="page-content container note-has-grid mt-4">

    <div class="row">
      <div class="col-md-3">
        <div class="row">
          <div class="col-lg-9">
            <nb-form-field>
              <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
              <nb-select (selectedChange)="filterByEmployee($event)"
               class="w-100 mt-3" selected="0">
               <nb-option  value="0" disabled>&nbsp;&nbsp;&nbsp; Employee</nb-option>
               <nb-option value="All" (click)="getAll()" >&nbsp;&nbsp;&nbsp; All</nb-option>
                <nb-option *ngFor="let employee of employees" value="{{employee.id}}">&nbsp;&nbsp;&nbsp; {{employee.employeeName}}</nb-option>

              </nb-select>
            </nb-form-field>
          </div>
          <div class="col-lg-9">
            <nb-form-field>
              <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
              <nb-select selected="0" (selectedChange)="filtreSubDepartment($event)" class="w-100 mt-3">
                <nb-option  value="0" disabled>&nbsp;&nbsp;&nbsp; SubDepartment</nb-option>
                <nb-option value="All" (click)="getAll()">&nbsp;&nbsp;&nbsp; All</nb-option>
                <nb-option value="{{dep.id}}" *ngFor="let dep of listd" >&nbsp;&nbsp;&nbsp;{{dep.subDepartmentName}}</nb-option>                </nb-select>
            </nb-form-field>

          </div>
          <div class="col-lg-9">
            <nb-form-field>
              <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
              <nb-select (selectedChange)="getRequestByStatus($event)"  selected="0" class="w-100 mt-3">
                <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Status</nb-option>
                <nb-option value="All" (click)="getAll()">All</nb-option>
                <nb-option value="Validate" >Validate</nb-option>
                <nb-option value="Rejected" >Rejected</nb-option>
                <nb-option value="Await For Validation" >Await For Validation</nb-option>
              </nb-select>
            </nb-form-field>
          </div>

          <div class="col-lg-9">
            <nb-form-field>
              <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
              <nb-select (selectedChange)="getRequestByStart($event)"  selected="0" class="w-100 mt-3">
                <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Start</nb-option>
                <nb-option value="All" (click)="getAll()">&nbsp;&nbsp;&nbsp; All</nb-option>
                <nb-option value="In The Morning" >In The Morning</nb-option>
                <nb-option value="Afternoon" >Afternoon</nb-option>

              </nb-select>
            </nb-form-field>
          </div>
          <div class="col-lg-9">
            <nb-form-field>
              <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
              <nb-select (selectedChange)="getRequestByEnd($event)"  selected="0" class="w-100 mt-3">
                <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; End</nb-option>
                <nb-option value="All" (click)="getAll()">&nbsp;&nbsp;&nbsp; All</nb-option>
                <nb-option value="In The Morning" >In The Morning</nb-option>
                <nb-option value="Afternoon" >Afternoon</nb-option>
              </nb-select>
            </nb-form-field>
          </div>
          <div class="col-lg-9">
            <nb-form-field>
              <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
              <nb-select  selected="0" class="w-100 mt-3">
                <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Start Date</nb-option>
                <nb-option value="All" (click)="getAll()">&nbsp;&nbsp;&nbsp; All</nb-option>
                <nb-option value="Ascending" (click)="getAllAsc()">&nbsp;&nbsp;&nbsp; Ascending</nb-option>
                <nb-option value="Descending" (click)="getAllDesc()">&nbsp;&nbsp;&nbsp; Descending</nb-option>

              </nb-select>
            </nb-form-field>
          </div>
          <div class="col-lg-9">
            <nb-form-field>
              <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
              <nb-select  selected="0" class="w-100 mt-3">
                <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; End Date</nb-option>
                <nb-option value="All" (click)="getAll()">&nbsp;&nbsp;&nbsp; All</nb-option>
                <nb-option value="Ascending" (click)="getAllAsc()" >&nbsp;&nbsp;&nbsp; Ascending</nb-option>
                <nb-option value="Descending" (click)="getAllDesc()">&nbsp;&nbsp;&nbsp; Descending</nb-option>

              </nb-select>
            </nb-form-field>
          </div>
          <div class="col-lg-9">
            <div class="mt-3">
              <label for="">Item Per Page</label>
              <input class="w-100" type="number" nbInput  [(ngModel)]="pageSize" min="11"/>
            </div>
          </div>
        </div>

      </div>
        <div class="col-md-9">
            <table  class="table mb-0 mt-3" id="departments">
                <thead class="bg-light">
                <tr>
                  <th>Employee</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Duration</th>
                  <th>Actions</th>

                </tr>
                </thead>
                <tr *ngFor="let request of leaves | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: total}">
                   <td>
                        <div class="row">
                          <div class="col-md-2">
                            <nb-user [name]="request?.employee?.employeeName" size="medium" onlyPicture [picture]="request?.employee?.employeeProfileImage"></nb-user>
                          </div>
                          <div class="col-md-10">
                            <p class="mt-2 mx-1">{{request?.employee?.employeeName}}</p>
                          </div>
                        </div>
                   </td>
                   <td><p>{{request.startDate | date : "dd MMM YYYY"}}</p></td>
                   <td><p>{{request.endDate | date : "dd MMM YYYY"}}</p></td>
                  <td>
                    <p class="mx-2" *ngIf="request.requestStatus === 'Await For Validation'">
                      <nb-tag status="basic" [text]="request.requestStatus"></nb-tag>
                    </p>
                    <p class="mx-2" *ngIf="request.requestStatus === 'Validate'">
                      <nb-tag status="success" [text]="request.requestStatus"></nb-tag>
                    </p>
                    <p class="mx-2" *ngIf="request.requestStatus === 'Rejected'">
                      <nb-tag status="danger" [text]="request.requestStatus"></nb-tag>
                    </p>
                  </td>
                   <td><p>{{request.duration}} day(s)</p></td>
                   <td>
                     <button class="mx-2" shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <nb-icon icon="more-horizontal-outline"></nb-icon>
                      </button>
                      <mat-menu #menu="matMenu">

                        <a [routerLink]="['/hr/absences/edit-request/',request.id]" style="text-decoration:none" nbTooltip="Click here to edit data" nbTooltipStatus="primary">
                          <button mat-menu-item>
                            <span ><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
                          </button>
                        </a>

                        <!--<a>
                                         <button mat-menu-item>
                                             <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>

                                         </button>
                                     </a>-->

                      </mat-menu>
                   </td>

                </tr>


                </table>

          <div class="mt-4 d-flex justify-content-end">
            <nb-card class="" style="height: 35px;margin-left: 0; border-radius: 25px;">
              <pagination-controls class="pt-1" (pageChange)="current = $event"></pagination-controls>
            </nb-card>
          </div>

        </div>

    </div>




</div>
