import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <!-- Le header est masqué par défaut et affiché seulement si showHeader est vrai -->
      <nb-layout-header *ngIf="showHeader" fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" *ngIf="showHeader" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  showHeader = false; // Masquez le header par défaut

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // Mettez à jour showHeader selon les routes où le header doit être affiché
      // Par exemple, si le header doit être affiché sur la route home (/), ajustez la condition
      this.showHeader = !(event.urlAfterRedirects.includes('/models') || event.urlAfterRedirects.includes('/auth/login'));
    });
  }
}