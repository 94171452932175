<div class="row">
  <div class="col-md-3 col-sm-12">
    <h3>
      Employees
    </h3>
  </div>
  <div class="col-md-3 col-sm-8">
    <input type="text" fieldSize="large" [disabled]="list.length === 0" nbInput fullWidth [(ngModel)]="searchbyfields"   placeholder="Search Employee">
  </div>
  <div class="col-md- mt-1" style="margin-left: 350px;">
    &nbsp;&nbsp;
    <button nbTooltip="Export to Excel file" nbTooltipStatus="primary" nbButton status="success"  (click)="exportAsXLSX()"><i class="fas fa-file-excel text-white"></i>  </button>
    &nbsp; <a nbTooltip="Back to Organigram" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/hr/organigram/view']" nbButton>
    <nb-icon icon="corner-up-right-outline"></nb-icon>
  </a>

  </div>
  <div class="row w-100">
    <div class="row w-100" *ngIf="loading">
      <nb-alert [nbSpinner]="true" nbSpinnerSize="giant" nbSpinnerStatus="primary" class="w-100 mx-5 mt-3" style="background-color: transparent !important;"></nb-alert>
    </div>
    <div class="row w-100" *ngIf="!loading && (!list || list.length === 0)">
      <nb-alert outline="danger" fullWidth class="w-100 mx-5 mt-3">No Data Found.</nb-alert>
    </div>
    <div [hidden]="list.length === 0" class="row w-100 mx-3" *ngIf="(list | filter : searchbyfields).length === 0">
      <nb-alert outline="danger"  class="col-lg-12 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
    </div>
    <div class="input-group mt-6 w-100">
      <nb-card class="mt-5 w-100">

        <table class="table" id="employees">
          <thead class="bg-light ">
          <tr>
            <th>Name</th>
            <th>Cell Phone</th>
            <th>Country</th>
            <th>Department</th>
            <th>SubDepartment</th>
            <th>Position</th>
          </tr>
          </thead>
          <tbody>
          <tr class="col-md-10 col-sm-10" *ngFor="let emp of list | filter : searchbyfields | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: list.length}" [hidden]="emp.isArchived === 'Yes'">

            <td>
              <div class="d-flex align-items-center">
                <nb-user [name]="emp.employeeName" onlyPicture  picture="{{emp.employeeProfileImage}}"></nb-user>
                <div class="ms-3">
                  <p class=" mb-1">{{emp.employeeName}}</p>
                  <p class=" mb-0">{{emp.employeeEmail}}</p>
                </div>
              </div>
            </td>
            <td><p class="fw-normal mb-1">{{emp.employeeCellPhone}}</p> </td>
            <td><p class="fw-normal mb-1">{{emp.employeeCountry}}</p></td>
            <td>

              <nb-tag-list>
                <nb-tag *ngIf="emp?.subDepartment?.department?.departmentName !== null ; else data" status="basic" size="small" [text]="emp?.subDepartment?.department?.departmentName "></nb-tag>
                <ng-template #data>&nbsp;
                  <nb-tag  status="warning" size="small" [text]="NotAssignedYet"></nb-tag>
                </ng-template>
              </nb-tag-list>

            </td>
            <td>

              <nb-tag-list>
                <nb-tag *ngIf="emp?.subDepartment?.subDepartmentName !== null ; else data" status="basic" size="small" [text]="emp?.subDepartment?.subDepartmentName "></nb-tag>
                <ng-template #data>&nbsp;
                  <nb-tag  status="warning" size="small" [text]="NotAssignedYet"></nb-tag>
                </ng-template>
              </nb-tag-list>

            </td>
            <td>
              <nb-tag-list>
                <nb-tag *ngIf="emp?.roleEmployee !== null ;" status="primary" size="small" [text]="emp?.roleEmployee"></nb-tag>
              </nb-tag-list>
            </td>
          </tr>
          </tbody>
        </table>
      </nb-card>
    </div>
    <nb-card class="card ml-auto mr-auto" style="height: 35px;margin-left: 200px; border-radius: 25px;">
      <pagination-controls class="pt-1" (pageChange)="current = $event"></pagination-controls>
    </nb-card>
  </div>
</div>
