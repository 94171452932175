import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'ngx-human-dashboard',
  templateUrl: './human-dashboard.component.html',
  styleUrls: ['./human-dashboard.component.scss'],
})
export class HumanDashboardComponent implements OnInit {

  constructor() {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }



}
