import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NbToastrConfig,
  NbComponentStatus,
  NbGlobalPosition,
  NbGlobalPhysicalPosition,
  NbToastrService,
  NbDialogService,
} from '@nebular/theme';
import { Application } from '../../../../models/Application';
import { ApplicationServiceService } from '../../../../services/recruitment/applicationService/application-service.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TestService} from '../../../../services/testServices/test.service';
import {Test} from '../../../../models/Test';
import {TestRecruterComponent} from '../test-recruter/test-recruter.component';

@Component({
  selector: 'ngx-screen-application',
  templateUrl: './screen-application.component.html',
  styleUrls: ['./screen-application.component.scss'],
})
export class ScreenApplicationComponent implements OnInit {
  config: NbToastrConfig;
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  application: Application;
  positions = NbGlobalPhysicalPosition;
  id: string;
  clicked = false;
  applications: Application[] = [];
  pdfData: string = '';
  selectedTestType: string;
  testTypes: string[] = [];
  testsSent: Test[] = []; // List of tests sent
  testDetails: Test;

  userId: string;
  constructor(private router: Router, private appService: ApplicationServiceService,
              private toastrService: NbToastrService, private route: ActivatedRoute,
              private fb: FormBuilder, private testService: TestService,
              private dialogService: NbDialogService,
              private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.loadTestTypes();
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('id'); // Ensure 'id' matches the route configuration parameter

      this.testService.currentTestDetails.subscribe(testDetails => {
        if (testDetails) {
          console.log('screen-application.ts: Received test details', testDetails);
        }
      });
      this.fetchTestsSentToCandidate(); // Fetch the tests sent to the candidate
    });

    this.application = new Application();
    this.id = this.route.snapshot.params['id'];

    this.appService.getApplication(this.id).subscribe(data => {
      // this.showToast('success', 'SUCCESS', 'Data Loaded Successfuly');
      this.application = data;
      this.pdfData = this?.application?.curriculumVitae;
      console.log(this.application.curriculumVitae.blink());

    }, err => console.log(err) );
    this.getApplicationsWithScores();
  }

  loadTestTypes() {
    this.testService.getAllTestTypes().subscribe({
      next: (types) => {
        this.testTypes = types;
        console.log('Loaded test types:', this.testTypes);
      },
      error: (error) => {
        console.error('Error fetching test types:', error);
        this.toastrService.show('Failed to load test types', 'Error', { status: 'danger' });
      },
    });
  }
  fetchTestsSentToCandidate() {
    this.testService.getTestsByCandidateId(this.userId).subscribe({
      next: (tests) => {
        this.testsSent = tests;
        console.log('Fetched tests sent to candidate:', this.testsSent);
      },
      error: (error) => {
        console.error('Error fetching tests sent to candidate:', error);
        this.toastrService.show('Failed to load tests', 'Error', { status: 'danger' });
      },
    });
  }
  getApplicationsWithScores() {
    this.appService.findAllWithScores().subscribe({
      next: (data) => {
        this.applications = data;
        console.log('Applications with scores: ', this.application);
        console.log('Applications with scores:', this.applications);
        // Add a check to see if there are unexpected duplicates
        console.log('Number of applications:', this.applications.length);
        // this.showToast('success', 'SUCCESS', 'Data loaded successfully');
      },
      error: (err) => {
        console.error('Error retrieving data', err);
        this.showToast('danger', 'ERROR', 'Error while retrieving data');
      },
    });
  }
  screenStep() {
    this.appService.screen(this.application, this.id).subscribe({
      next: () => {
        this.toastrService.show('Screening updated successfully', 'SUCCESS', { status: 'success' });
        this.application.status = 'SCREENING';  // Ensure status is updated without resetting other fields
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? `. ${title}` : '';
    this.toastrService.show(
      body,
      `Toast ${titleContent}`,
      config);
  }

  sendTestType(): void {
    console.log('Sending test type to service: ', this.selectedTestType); // Check the type here
    this.appService.changeTestType(this.selectedTestType);
  }


  sendTest(): void {
    console.log('screen-application.ts: sendTest', this.selectedTestType, this.userId);
    if (!this.selectedTestType) {
      this.showToast('warning', 'Warning', 'Please select a test to send');
      return;
    }
    // Check if the test already exists
    this.testService.checkTestExistence(this.userId, this.selectedTestType).subscribe({
      next: (exists) => {
        if (exists) {
          this.showToast('danger', 'ERROR', 'You already sent this test to this candidate');
          console.error('Test sent failed: Test already exists for this candidate and type');
        } else {
          // Fetch the test details by type if the test doesn't already exist
          this.testService.getTestDetailsByType(this.selectedTestType).subscribe({
            next: (testDetails) => {
              console.log('Test details fetched by type', testDetails);

              testDetails.candidatId = this.userId;

              // Now create the test with the modified details
              this.testService.createTest(testDetails).subscribe({
                next: (createdTest) => {
                  console.log('Test created successfully', createdTest);

                  // Send the created test details
                  this.appService.sendTest(this.userId, this.selectedTestType).subscribe({
                    next: () => {
                      this.showToast('success', 'SUCCESS', 'Test sent successfully');
                      this.testsSent.push(createdTest);
                    },
                    error: (err) => {
                      if (err.status === 404) {
                        this.showToast('danger', 'ERROR', 'Email does not exist in our records, please check');
                      } else {
                        this.showToast('danger', 'ERROR', 'An error occurred, please try again later');
                      }
                    },
                  });
                },
                error: (err) => {
                  this.showToast('danger', 'ERROR', 'Failed to create test');
                  console.error('Test creation failed', err);
                },
              });
            },
            error: (err) => {
              this.showToast('danger', 'ERROR', 'Failed to fetch test details');
              console.error('Fetching test details failed', err);
            },
          });
        }
      },
      error: (err) => {
        this.showToast('danger', 'ERROR', 'Failed to check test existence');
        console.error('Checking test existence failed', err);
      },
    });
  }




  openTestEditor() {
    const dialogRef = this.dialogService.open(TestRecruterComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });

    dialogRef.onClose.subscribe(result => {
      console.log('Dialog was closed');
      // Here you can handle any updates based on the result if needed
    });
  }

// Function to open the PDF in a modal
  viewPdf(pdfUrl: string): void {
    if (!pdfUrl) {
      console.error('PDF URL is undefined');
      return;
    }
    this.testService.downloadPdf(pdfUrl).subscribe(
      (pdfBlob) => {
        const file = new Blob([pdfBlob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      (error) => {
        console.error('Failed to download PDF', error);
        this.showToast('danger', 'ERROR', 'Failed to download PDF');
      },
    );
  }

  toggleTestAccepted(test: any): void {
    test.testAccepted = !test.testAccepted;
    this.testService.updateTest(test.candidatId, test.type , test).subscribe({
      next: () => {
        this.showToast('success', 'SUCCESS', 'Test updated successfully');
      },
      error: (err) => {
        this.showToast('danger', 'ERROR', 'Failed to update test');
        console.error('Failed to update test', err);
      },
    });
  }


}
