import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Company} from '../../../auth/model/Company';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {

  url = environment.administrationUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getCompanyByMatriculate(matriculate: string): Observable<Company> {
    return this.http.get<Company>(`${this.url}company/${matriculate}`);
  }

}


