import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Pay} from '../../../models/Pay';
import {PayService} from '../../../services/payService/pay.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from '@nebular/theme';



@Component({
  selector: 'ngx-pay-sheet-form',
  templateUrl: './pay-sheet-form.component.html',
  styleUrls: ['./pay-sheet-form.component.scss'],
})
export class PaySheetFormComponent implements OnInit {
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  loading = false;
  payForm: FormGroup;
  pay: Pay = new Pay();
  id: string;
  constructor(private ps: PayService, private route: ActivatedRoute, private toastrService: NbToastrService, private router: Router) {
    this.payForm = new FormGroup({
      primeConventionnelle : new FormControl('', [Validators.required, this.nonNegativeValidator]),
      primeNonConventionnelle: new FormControl('', this.nonNegativeValidator),
    });
  }

  ngOnInit(): void {

    this.id = this.route.snapshot.params['id'];

  }

  calculate() {
    if (this.payForm.get('primeConventionnelle').value < 0 || this.payForm.get('primeNonConventionnelle').value < 0) {
      this.showToast('danger', 'ERROR', 'Negative values are not allowed');
      return; // Abort the submission if there are negative values
    }

    if (this.payForm.valid) {
      this.loading = true; // Start loading/spinner effect
      this.ps.addPay(this.pay, this.id).subscribe((res) => {
        this.showToast('success', 'SUCCESS', 'Operation Achieved Successfully');
        this.loading = false; // Stop loading/spinner effect
        this.router.navigate(['/hr/pay/grid4payroll']);
      }, (err) => {
        console.log(err);
        this.loading = false; // Stop loading/spinner effect
        this.showToast('danger', 'ERROR', 'Failed to process payment');
      });
    } else {
      this.showToast('danger', 'ERROR', 'Form data is invalid');
    }
  }


  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? `. ${title}` : '';
    this.toastrService.show(
      body,
      `Leave Request ${titleContent}`,
      config);
  }
  private nonNegativeValidator(control: FormControl) {
    const value = control.value;
    return value < 0 ? { 'nonNegative': 'Value cannot be negative' } : null;
  }

}
