<div class="row">
    <div class="col-md-4">
        <h3>Room : {{room}}</h3>
    </div>
</div>
<div class="row">
    
    <div class="col-lg-8">
        <div id="jitsi-iframe"></div>
    </div>
    <div class="col-lg-4 ">
        <h4>Applications</h4>
        <div class="col-lg-12 mt-3" *ngFor="let app of applications; let i = index">
            <div class="row mt-2">
                <div class="col-md-2">
                    
                    <img class="mt-2" src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745" width="55px" height="55px" alt="">

                </div>
                <div class="col-md-6">
                        <p class="mt-1" ngxCopyPaste #cp="copy">{{app.email}} </p>
                        <nb-tag class="mb-4" status="info" [text]="app.status" size="tiny"></nb-tag>
                        <!-- <div class="row">
                            <div class="col-md-5" >
                                <a [routerLink]="['/hr/recruitment/application/validate/',app.id]">
                                <nb-toggle [hidden]="app?.status === 'VALIDATE' " [disabled]="clicked_" (change)="validateStep(); clicked_ = true" status="success">
                                 Validate
                                </nb-toggle>
                                </a>
                            
                            </div>
                            <div class="col-md-1"></div>
                            <div class="col-md-5">
                                <nb-toggle [hidden]="app?.status === 'REJECTED' " [disabled]="clicked_" (change)="rejectStep(); clicked_ = true" status="danger">
                                   Reject
                                </nb-toggle>
                            </div>
                        </div> -->
                

                </div>
                <div class="col-md-4 mt-3">
                    <div class="row">
                        <button (click)="cp.copy() ; getCopyPaste()" shape="round" nbButton status="info" size="small">
                            <nb-icon icon="copy-outline"></nb-icon>
                        </button>
                        &nbsp;
                        <button (click)="validateStep(app,app.id)" shape="round" nbButton status="success" size="small">
                            <nb-icon icon="checkmark-outline"></nb-icon>
                        </button>
                        &nbsp;
                        <button (click)="rejectStep(app, app.id)" shape="round" nbButton status="danger" size="small">
                            <nb-icon icon="close-outline"></nb-icon>
                        </button>
                       
                </div>
                 
                </div>
            </div>
        </div>
    </div>
</div>


<!--For Custom Controls-->
<!-- <div class="item-center">
    <span>Custom Controls</span>
</div> -->
<!-- <div class="item-center"> 
    <i (click)="executeCommand('toggleAudio')" class="fas fa-2x grey-color" [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'" aria-hidden="true" title="Mute / Unmute"></i>
    <i (click)="executeCommand('hangup')" class="fas fa-phone-slash fa-2x red-color" aria-hidden="true" title="Leave"></i>
    <i (click)="executeCommand('toggleVideo')" class="fas fa-2x grey-color" [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'" aria-hidden="true" title="Start / Stop camera"></i>
    <i (click)="executeCommand('toggleShareScreen')" class="fas fa-film fa-2x grey-color" aria-hidden="true" title="Share your screen"></i>
</div> -->