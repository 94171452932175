import {Employee} from './Employee';
export class Skills {
    id: string ;
    skillName: string ;
    experience: number;
    score: number;
    isArchived: string;
    employee: Employee;
    user: string;
}
