import { AfterViewInit, Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NbToastrConfig, NbComponentStatus, NbGlobalPosition, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { Application } from '../../../../models/Application';
import { ApplicationServiceService } from '../../../../services/recruitment/applicationService/application-service.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ngx-grid-applications',
  templateUrl: './grid-applications.component.html',
  styleUrls: ['./grid-applications.component.scss'],
})
export class GridApplicationsComponent implements OnInit, AfterViewInit, OnChanges {

  config: NbToastrConfig;
  title = 'Application';
  content = 'Operation achieved';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;

  applications: Application[] = [];
  newApplications = [];
  allApplications: Application[] = []; // Store all applications to avoid refetching unnecessarily
  searchbyfields: string = '';
  pageSize = 4;
  current = 1;
  selectedFile: File = null;
  loading: boolean = false;
  pollingInterval = 5000;

  constructor(
    private as: ApplicationServiceService,
    private toastrService: NbToastrService,
    private router: Router,
    private http: HttpClient,
  ) {}

  // Détecte les changements après une modification de la liste d'applications (si nécessaire)
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['applications'] && !changes['applications'].isFirstChange()) {
      this.filterApplications();
    }
  }

  // Initialise la vue
  ngOnInit(): void {
    this.getApplicationsWithScores();
    this.loadApplications(); // Charger les applications au démarrage
  }
  getApplicationsWithScores() {
    this.as.findAllWithScores().subscribe({
      next: (data) => {
        this.applications = data.filter(app => app.archived === false).reverse();
        // this.showToast('success', 'SUCCESS', 'Data loaded successfully');
      },
      error: (err) => {
        console.error('Error retrieving data', err);
        this.showToast('danger', 'ERROR', 'Error while retrieving data');
      },
    });
  }
  // Chargement des applications
  loadApplications() {
    this.loading = false;
    this.as.findAll()
       // Stop loading
      .subscribe({
        next: (applications) => {
          this.allApplications = applications;
          this.newApplications = [...this.allApplications.filter(app => app.archived === false)]; // Stocke les nouvelles applications
          this.filterApplications(); // Applique le filtre
        },
        error: (err) => {
          console.error('Error loading data', err);
          this.showToast('danger', 'ERROR', 'Error while retrieving data');
        },
      });
  }
  getApplications() {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter(app => app.archived === false).reverse();
      // this.showToast('success', 'SUCESS', 'Data Loaded Successfuly');
    }, (err) => {
      console.log(err);
      this.showToast('danger', 'DANGER', 'Error While Retrieving Data');

    });
  }
  // Filtre les applications
  filterApplications() {
    this.applications = this.allApplications.filter(app => app.archived === false).reverse();
  }

  // Sélection du fichier
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  // Upload du fichier
  onUpload() {
    if (!this.selectedFile) {
      this.showToast('warning', 'No File Selected', 'Please choose a file before uploading');
      return;
    }

    const fileName = this.selectedFile.name;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    if (fileExtension !== 'csv') {
      this.showToast('warning', 'Invalid File Type', 'Please upload a .csv file');
      return;
    }

    this.loading = true;

    this.as.uploadFile(this.selectedFile).subscribe({
      next: () => {
        this.showToast('success', 'SUCCESS', 'File Uploaded Successfully');

        setTimeout(() => {
          this.loading = true;
          this.showToast('success', 'SUCCESS', 'New data uploaded successfully');
          this.reloadApplicationsAfterUpload();
          this.selectedFile  = null;
          this.loading = false;

        //  window.location.reload();
        }, 6000);

      },
      error: (err) => {
        console.error('Error uploading file', err);
        this.showToast('danger', 'ERROR', 'Error while uploading file');
        // this.loading = false;
      },
      complete: () => {
        this.reloadApplicationsAfterUpload();
      },
    });
  }

  // Recharge les applications après l'upload du fichier
  reloadApplicationsAfterUpload() {
    this.loadApplications(); // Recharge les applications après l'upload
    this.newApplications = [...this.newApplications]; // Mise à jour des nouvelles applications
    this.loading = true;
  }

  // Méthode pour ouvrir la boîte de dialogue de sélection de fichier
  triggerFileInput() {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  }

  // Appelé après l'initialisation de la vue
  ngAfterViewInit(): void {
    this.loadApplications(); // Assure que les applications sont chargées après l'initialisation de la vue
  }

  // Toast pour afficher des notifications
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? ` ${title}` : '';
    this.toastrService.show(body, ` ${titleContent}`, config);
  }

  // Filtrer les applications par statut
// Filtrer les applications par statut
  getStatusSelected(event) {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter((e) => e.status === event).reverse().filter(app => app.archived === false);
    }, (err) => {
      console.log(err);

    });
  }


  // Filtrer les applications par poste
  getJobSelected(event) {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter((e) => e.refJob === event);
    }, (err) => {
      console.log(err);
    });
  }

  // Archiver une application
  archiveApplication(id: string) {
    this.as.archiveApplication(id).subscribe({
      next: (res) => {
        this.showToast('success', 'SUCCESS', 'Application Archived Successfully');
        this.loadApplications(); // Recharge les applications après archivage
      },
      error: (err) => {
        console.error(err);
        this.showToast('danger', 'ERROR', 'Failed to Archive Application');
      },
    });
  }
}
