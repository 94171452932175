import {Employee} from './Employee';
export class Evaluation {

    id: string;
    employee: Employee;
    evaluationType: string;
    user: string;
    limitDate: Date;
    attachmentEvaluation: string;
    status: string;
    isArchived: string;
}
