import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable, of, throwError} from 'rxjs';
import {GlobalBudget} from '../../models/GlobalBudget';
import {catchError, mergeMap} from 'rxjs/operators';

const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }),
  'responseType': 'text as json',
};
@Injectable({
  providedIn: 'root',
})
export class GlobalBudgetServiceService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) { }

  getGlobalBudget(id: string): Observable<GlobalBudget> {
    return this.httpClient.get<GlobalBudget>(`${environment.employees_hcm}global_budget/${id}`);
  }
  findAllGlobalBudget(): Observable<[GlobalBudget]> {
    return this.httpClient.get<[GlobalBudget]>(`${environment.employees_hcm}global_budget/all`);
  }
// In GlobalBudgetServiceService
  findGlobalBudget(): Observable<GlobalBudget> {
    return this.httpClient.get<GlobalBudget>(`${environment.employees_hcm}global_budget/all`);
  }
  createGlobalBudget(budget: GlobalBudget): Observable<GlobalBudget> {
    return this.httpClient.post<GlobalBudget>(`${environment.employees_hcm}global_budget/create`, budget);
  }

  updateGlobalBudget(id: string, budget: GlobalBudget): Observable<GlobalBudget> {
    return this.httpClient.put<GlobalBudget>(`${environment.employees_hcm}global_budget/${id}`, budget);
  }

}
