import {Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef, NbDialogService, NbToastrService, NbCardModule} from '@nebular/theme';
import { ChangeDetectorRef } from '@angular/core';

import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ApplicationServiceService} from '../../../../services/recruitment/applicationService/application-service.service';
import {TestService} from '../../../../services/testServices/test.service';
import {
  ShowcaseDialogComponent} from '../../../../../../pages/modal-overlays/dialog/showcase-dialog/showcase-dialog.component';
import {Test} from '../../../../models/Test';


@Component({
  selector: 'ngx-test-recruter',
  templateUrl: './test-recruter.component.html',
  styleUrls: ['./test-recruter.component.scss'],
})


export class TestRecruterComponent implements OnInit {
  testForm: FormGroup;
  currentTestType: string = '';
  timeLeft: number = 1200;
  interval: any;
  isTestSubmitted: boolean = false;  // Ajout d'une nouvelle variable d'état
  private navigationSubscription: Subscription;
  private dialogRef: NbDialogRef<any>;
  candidatId: string;

  @ViewChild('exitDialog', {static: true}) exitDialog: TemplateRef<any>;

  @ViewChild('minutes', {static: true}) minutes: ElementRef;
  @ViewChild('seconds', {static: true}) seconds: ElementRef;
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private toastrService: NbToastrService,
    private appService: ApplicationServiceService,
    private dialogService: NbDialogService,
    private router: Router,
    private route: ActivatedRoute,
    private testService: TestService,
    private dialog: NbDialogRef<TestRecruterComponent>,
  ) {
  }

  ngOnInit(): void {
    this.addClickListener();
    this.initForm();
  }
  formatTime(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours < 10 ? '0' + hours : hours ;  // Converts "0" to "12"
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  }
  addClickListener() {
    document.addEventListener('click', (event) => {
      const withinPicker = (event.target as HTMLElement).closest('.nb-timepicker-container');
      if (!withinPicker) {
        // Rétablir les styles ou faire d'autres actions quand on clique en dehors
        const container = document.querySelector('.container-qui-change') as HTMLElement;
        if (container) {
          container.style.backgroundColor = '#desiredColor'; // Remplacez '#desiredColor' par la couleur désirée
        }
      }
    });
  }

  initForm() {
    this.testForm = this.fb.group({
      type: ['', Validators.required],
      nomTest: ['', Validators.required],
      dureeTest: ['', Validators.required],
      questions: this.fb.array([]),
    });
  }

  addQuestion() {
    const questionGroup = this.fb.group({
      questionText: ['', Validators.required],
      responses: this.fb.array([]),
      correctResponseIndex: [-1, Validators.required], // Ensure the correctResponseIndex is initialized
    });

    this.questions.push(questionGroup);
  }
  removeQuestion(index: number) {
    this.questions.removeAt(index);
  }

  addResponse(questionIndex: number) {
    const responses = this.getResponseArray(questionIndex);
    const responseGroup = this.fb.group({
      responseText: ['', Validators.required],
      correct: false,
    });

    responses.push(responseGroup);
  }

  removeResponse(questionIndex: number, responseIndex: number) {
    const responses = this.getResponseArray(questionIndex);
    responses.removeAt(responseIndex);
  }


  setCorrectResponse(questionIndex: number, responseIndex: number) {
    const responses = this.getResponseArray(questionIndex);
    const questionFormGroup = this.questions.at(questionIndex) as FormGroup;

    // Reset all responses 'correct' to false and then set the chosen one to true
    responses.controls.forEach((control, index) => {
      const correct = responseIndex === index;
      control.get('correct').setValue(correct, { emitEvent: false });
    });

    // Set the correctResponseIndex in the question form group
    questionFormGroup.patchValue({
      correctResponseIndex: responseIndex,
    });
  }





  getResponseArray(questionIndex: number): FormArray {
    return this.questions.at(questionIndex).get('responses') as FormArray;
  }

  get questions(): FormArray {
    return this.testForm.get('questions') as FormArray;
  }


  submitTest() {
    console.log('Form Data on Submission:', this.testForm.value);
    this.testForm.markAllAsTouched();

    // First check: Are all form fields filled?
    if (!this.testForm.valid) {
      this.toastrService.show('Please fill in all required fields.', 'Form Incomplete', { status: 'danger' });
      return; // Stop further execution if the form is not valid
    }

    // Check if there is at least one question
    if (this.questions.length === 0) {
      this.toastrService.show('Please add at least one question.', 'Form Incomplete', { status: 'danger' });
      return; // Stop further execution if there are no questions
    }

    // Second check: Does each question have a correct answer?
   let allQuestionsHaveCorrectAnswer = true;
    this.questions.controls.forEach((question) => {
      const responses = question.get('responses') as FormArray;
      // Check for at least one correct answer
      if (!responses.controls.some(response => response.get('correct').value)) {
        allQuestionsHaveCorrectAnswer = false;
      }
    });

    if (!allQuestionsHaveCorrectAnswer) {
      this.toastrService.show('Every question must have at least one correct answer selected.', 'Form Incomplete', { status: 'danger' });
      return; // Stop further execution if not every question has a correct answer
    }

    // Third check: Check if a test of the same type already exists
    this.testService.checkTestExistenceByType(this.testForm.get('type').value).subscribe({
      next: (exists) => {
        if (exists) {
          this.toastrService.show('A test of this type already exists.', 'Duplicate Test', { status: 'danger' });
          return; // Stop further execution if a test of the same type exists
        }

    // If all checks pass, submit the form
    const testData = this.testForm.value as Test;
        testData.dureeTest = this.formatTime(new Date(testData.dureeTest));
    this.testService.createTest(testData).subscribe({
      next: () => {
        this.toastrService.show('Test submitted successfully', 'Success', { status: 'success' });
         setTimeout(() => window.location.reload(), 1000); // Refresh after success
      },
      error: err => {
        this.toastrService.show('Failed to submit test', 'Error', { status: 'danger' });
        console.error('Submission failed', err);
      },
    });
  },
  error: err => {
  this.toastrService.show('Failed to check test existence', 'Error', { status: 'danger' });
console.error('Existence check failed', err);
},
});
}



  closeDialog() {
      window.location.reload();
  }
}
