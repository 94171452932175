import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import { Application } from '../../../models/Application';
import {Test} from '../../../models/Test';

@Injectable({
  providedIn: 'root',
})
export class ApplicationServiceService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpclient: HttpClient) {
    this.testTypeSource.subscribe(value => {
      console.log('Current Test Type in Service: ', value);
    });
  }

  private testTypeSource = new BehaviorSubject<string>(''); // default value
  currentTestType = this.testTypeSource.asObservable();
  private testDetailsSource = new BehaviorSubject<Test>(null);

  currentTestDetails = this.testDetailsSource.asObservable();

  getApplicationWithScores(): Observable<any> {
    return this.httpclient.get<Application[]>(`${environment.recruitment_hcm}application/applications-with-scores`);
  }
  findAll(): Observable<Application[]> {
    return this.httpclient.get<Application[]>(`${environment.recruitment_hcm}application/list-applications`);
  }

  findAllBYScreening(): Observable<Application[]> {
    return this.httpclient.get<Application[]>(`${environment.recruitment_hcm}application/screening-applications`);
  }

  getApplication(id: string): Observable<Application> {
    return this.httpclient.get<Application>(`${environment.recruitment_hcm}application/app/${id}`, this.httpOptions);
  }

  screen(application: Application, id: string): Observable<Application> {
    const path = `${environment.recruitment_hcm}application/screen/` + id ;
    // @ts-ignore
    return this.httpclient.put<Application>(path , application , this.httpOptions);
  }

  validate(application: Application, id: string): Observable<Application> {
    const path = `${environment.recruitment_hcm}application/validate/` + id ;
    // @ts-ignore
    return this.httpclient.put<Application>(path , application , this.httpOptions);
  }

  reject(application: Application, id: string): Observable<Application> {
    const path = `${environment.recruitment_hcm}application/reject/` + id ;
    // @ts-ignore
    return this.httpclient.put<Application>(path , application , this.httpOptions);
  }

  // Method to send test to an application by ID
  sendTest(id: string , type: string): Observable<string> {
    const url = `${environment.recruitment_hcm}application/send-test/${type}/${id}`;
    return this.httpclient.post<string>(url, {}, this.httpOptions);
  }
  getCurrentTestType(): string {
    return this.testTypeSource.getValue();
  }
  changeTestType(newType: string) {
    console.log('application service : ', newType); // This should log when type changes
    this.testTypeSource.next(newType);
  }

  findAllWithScores(): Observable<Application[]> {
    return this.httpclient.get<Application[]>(`${environment.recruitment_hcm}application/with-scores`);
  }

  getFiles(): Observable<string[]> {
    return this.httpclient.get<string[]>(`${environment.recruitment_hcm}application/api/files`);
  }
  downloadFile(filename: string): Observable<Blob> {
    return this.httpclient.get(`${environment.recruitment_hcm}application/api/files/download/${filename}`, { responseType: 'blob'});
  }

  // application-service.service.ts
  archiveApplication(id: string): Observable<Application> {
    return this.httpclient.post<Application>(`${environment.recruitment_hcm}application/archive/${id}`, {});
  }
  restoreApplication(id: string): Observable<Application> {
    return this.httpclient.post<Application>(`${environment.recruitment_hcm}application/restore/${id}`, {});
  }

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpclient.post<any>(`${environment.recruitment_hcm}application/upload`, formData, { responseType: 'text' as 'json' });
  }
}
