import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AuthService} from '../../service/auth.service';
import {Router} from '@angular/router';
import { SearchCountryField } from 'ngx-intl-tel-input';
import { CountryISO } from 'ngx-intl-tel-input';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from '@nebular/theme';
import {environment} from '../../../../../environments/environment';
import {ExternApisService} from '../../../administration-management/service/extern-apis/extern-apis.service';


@Component({
  selector: 'ngx-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  forgetPWDForm: FormGroup;
  config: NbToastrConfig;
  current: number = 1;
  search: string = '';
  index = 1;
  resetOption: string = 'email';
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  status: NbComponentStatus = 'primary';
  positions = NbGlobalPhysicalPosition;
  captchaResponse: string = '';
  recaptchaSecret: string = environment.captchaKey;
  success: boolean;
  constructor(

    private fb: FormBuilder,
    private externApisService: ExternApisService,
    private authService: AuthService,
    private router: Router,
    private toastrService: NbToastrService,

  ) {}

  searchCountryFields: SearchCountryField[] = [SearchCountryField.Iso2, SearchCountryField.Name];
  defaultCountryISO: CountryISO = CountryISO.Tunisia;
  showToast(message, position, status) {
    this.toastrService.show(status || 'Success', message, { position, status });
  }
  // fixed

  ngOnInit(): void {
    this.forgetPWDForm = this.fb.group({
      email: ['', [Validators.required, Validators.email , Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}$/u)]],
        phone: ['', []],
        resetOption: ['email', Validators.required],
    });


    // Écoutez les changements d'options et appliquez les validateurs appropriés
    this.forgetPWDForm.get('resetOption').valueChanges.subscribe((option) => {
      this.forgetPWDForm.get('email').reset();
      this.forgetPWDForm.get('phone').reset();
      if (option === 'email') {
        this.forgetPWDForm.get('email').setValidators([Validators.required, Validators.email]);
        this.forgetPWDForm.get('phone').clearValidators();
      } else if (option === 'phone') {
        this.forgetPWDForm.get('phone').setValidators(Validators.required);
        this.forgetPWDForm.get('email').clearValidators();
      }
      this.forgetPWDForm.get('email').updateValueAndValidity();
      this.forgetPWDForm.get('phone').updateValueAndValidity();
    });
  }

  onChange(event: any) {
    this.resetOption = event.target.value;
    console.log('Option changed to:', this.resetOption);
  }

// Fonction pour vérifier si le formulaire est valide pour activer le bouton
  isFormValid(): boolean {
    const option = this.forgetPWDForm.get('resetOption').value;
    const emailValid = this.forgetPWDForm.get('email').valid;
    const phoneValid = this.forgetPWDForm.get('phone').valid;
    const captchaValid = !!this.captchaResponse; // Vérifiez si le captcha a été résolu

    return (option === 'email' ? emailValid : phoneValid) && captchaValid;
  }
  get email() {
    return this.forgetPWDForm.get('email');
  }
  get recaptcha() {
    return this.forgetPWDForm.get('recaptcha');
  }
  onCaptchaResolved(response: string) {
    this.captchaResponse = response;
  }
  forgetPWD() {
    const data = this.forgetPWDForm.value;
    this.authService.forgetPWD(data).subscribe({
      next: () => {
        this.showToast('Please Verify your e-mail, to reset your password!!', this.positions.TOP_RIGHT, 'success');
      },
      error: (err) => {
        if (err.status === 404) {
          this.showToast('Email does not exist in our records, please check', this.positions.TOP_RIGHT, 'danger');
        } else {
          this.showToast('Please Verify your e-mail to reset your password.', this.positions.TOP_RIGHT, 'success');
          setTimeout(() => {
            this.router.navigate(['/auth/login']);
          }, 2000);
        }
      },
    });
  }
  sendSMStwilio() {
    this.externApisService.getApiStatusByName('Twilio').subscribe({
      next: (apiStatus) => {
        if (!apiStatus.enabled) {
          // Si Twilio est désactivé, affichez un toast et ne continuez pas.
          this.showToast('Twilio External API is disabled, please contact administration.', this.positions.TOP_RIGHT, 'warning');
        } else {
          const phoneNumber = this.forgetPWDForm.get('phone')?.value.internationalNumber; // Adjust based on how your phone number field is set up
          this.authService.sendSMStwilio(phoneNumber).subscribe({
            next: (response) => {
              if (response.status === 'DELIVERED') {
                this.showToast('Please Verify your Phone to reset your password.', this.positions.TOP_RIGHT, 'success');
                setTimeout(() => {
                  this.router.navigate(['/auth/verif_code', response.token], {queryParams: {method: this.resetOption}});
                }, 2000);
              }
              if (response.status === 'FAILED') {
                // this.showToast('Invalid Phone Number, there is no user registered with this Phone Number', this.positions.TOP_RIGHT, 'danger');
                this.showToast('Please Verify your Phone to reset your password.', this.positions.TOP_RIGHT, 'success');
              }
            },
          });
        }
      },
    });
  }


  cancelForget() {
    this.router.navigate(['/auth/login']);
   }
}
