<!-- <div class="row">
    <div class="col-md-12">
      <nb-card>
        <nb-card-body>
          <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
            <h2 class="title">404 Page Not Found</h2>
            <small class="sub-title">The page you were looking for doesn't exist</small>
            <button nbButton fullWidth (click)="goToHome()" type="button" class="home-button">
              Take me home
            </button>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div> -->
  <nb-card>
  <section  class="mb-5 bg-img cover-background" >
    <div class="container-fluid d-flex flex-column">
        <div class="row align-items-center justify-content-center min-vh-100">
            <div class="col-md-9 col-lg-6">
            
                <div style="margin-bottom: 200px;" class="text-center error-page">
                  <h1 class="mb-0">404</h1>
                  <h2 class="mb-4">Sorry, something went wrong!</h2>
                  <p class="w-sm-80 mx-auto mb-4">This page is incidentally inaccessible because of support. We will back very before long much obliged for your understanding</p>
                  <div>
                      <a nbButton status="primary" size="large" href="#" class=" me-sm-2 mb-2 mb-sm-0">Return Home</a>
                  </div>
              </div>
            
               
            </div>
        </div>
    </div>
</section>
</nb-card>