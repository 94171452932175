import {Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef, NbDialogService, NbToastrService, NbCardModule} from '@nebular/theme';
import { ChangeDetectorRef } from '@angular/core';
import { ShowcaseDialogComponent } from '../../pages/modal-overlays/dialog/showcase-dialog/showcase-dialog.component';
import {
  ApplicationServiceService} from '../humain-capital-management/services/recruitment/applicationService/application-service.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {Test} from '../humain-capital-management/models/Test';
import {TestService} from '../humain-capital-management/services/testServices/test.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {resolve} from '@angular/compiler-cli';
@Component({
  selector: 'ngx-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})

export class TestComponent implements OnInit, OnDestroy {
  testForm: FormGroup;
  currentTestType: string = '';
  timeLeft: number = 0;
  interval: any;
  isTestSubmitted: boolean = false;  // Ajout d'une nouvelle variable d'état
  private navigationSubscription: Subscription;
  private dialogRef: NbDialogRef<any>;
  candidatId: string;
  testDetails: Test;

  @ViewChild('exitDialog', {static: true}) exitDialog: TemplateRef<any>;

  @ViewChild('minutes', {static: true}) minutes: ElementRef;
  @ViewChild('seconds', {static: true}) seconds: ElementRef;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private toastrService: NbToastrService,
    private appService: ApplicationServiceService,
    private dialogService: NbDialogService,
    private router: Router,
    private route: ActivatedRoute,
    private testService: TestService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    // Get candidate ID from URL
    this.route.paramMap.subscribe(params => {
      this.candidatId = params.get('id');
      const testType = params['type']; // Ensure the parameter name matches
      if (testType) {
        console.log('Component: Received test type', testType);  // Confirm this logs
        this.currentTestType = testType;
        this.loadTest(testType);
      }
    });
    // this.checkTestStatus();
    // this.startTest();
    // Fetch the test type from the route parameters
    this.route.paramMap.subscribe(params => {
      const type = params.get('type'); // Adjust the parameter name based on your routing setup
      if (type) {
        console.log('Component: Received test type', type);  // Confirm this logs
        this.loadTest(type);
        console.log('Component: Received test type', type);  // Confirm this logs
        this.currentTestType = type;
      }
    });
    this.startTimer();
  }

  parseDurationToSeconds(duration: string): number {
    const [minutes, seconds] = duration.split(':').map(Number);
    return minutes * 60 + seconds;
  }

  initForm() {
    this.testForm = this.fb.group({
      nomTest: ['', Validators.required],
      questions: this.fb.array([]),
    });
  }

  loadTest(type: string) {
    console.log('test component.ts:', type);
    this.testService.getTestDetailsByType(type).subscribe({
      next: (test) => {
        this.testDetails = test;
        console.log('Loaded test details:', this.testDetails);
        if (this.testDetails.dureeTest) {
          this.timeLeft = this.parseDurationToSeconds(this.testDetails.dureeTest);
        }
        this.buildFormWithTestDetails(); // Initialize the form with the loaded test details
      },
      error: (error) => {
        console.error('Failed to load test details:', error);
      },
    });
  }

  buildFormWithTestDetails() {
    const questionArray = this.testForm.get('questions') as FormArray;
    questionArray.clear();

    this.testDetails.questions.forEach(question => {
      questionArray.push(this.fb.group({
        questionText: [question.questionText, Validators.required],
        responses: this.fb.array(question.responses.map(response =>
          this.fb.group({
            responseText: [response.responseText, Validators.required],
            correct: [response.correct],
          })),
        ),
        responseCandidat: ['', Validators.required],
      }));
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    localStorage.removeItem('testStatus');
  }


  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        const minutes = Math.floor(this.timeLeft / 60);
        const seconds = this.timeLeft % 60;
        this.minutes.nativeElement.innerText = minutes.toString().padStart(2, '0');
        this.seconds.nativeElement.innerText = seconds.toString().padStart(2, '0');
      } else {
        this.timeExpired();
      }
    }, 1000);
  }

  timeExpired() {
    this.stopTimer();
    this.dialogService.open(ShowcaseDialogComponent, {
      context: {
        title: 'Time over!',
        message: 'Your allotted time for this test has ended. Thank you for participating.',
      },
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });

    this.testService.canUpdateTest(this.candidatId, this.currentTestType).subscribe({
      next: (exists) => {
        if (exists) {
          // If the test does not exist, create the test details and submit
          const testDetails: Test = {
            ...this.testForm.value,
            type: this.currentTestType,
            candidatId: this.candidatId,
            isTestPassed: true,
            dureeTest: `${this.minutes.nativeElement.innerText}:${this.seconds.nativeElement.innerText}`,
            questions: this.testForm.value.questions.map((q, index) => ({
              ...q,
              responses: this.testDetails.questions[index].responses,

            })),
            score: this.calculateScore(),
          };

          this.testService.updateTestDetails(testDetails);

          this.testService.updateTest(this.candidatId, this.currentTestType, testDetails).subscribe({
            next: () => {
              this.toastrService.show('your answers have been recorded. Thank you for participating.', 'Success', {status: 'success'});
            },
            error: (err) => {
              this.toastrService.show('Failed to submit test', 'Error', {status: 'danger'});
            },
          });
        } else {
          // If the test already exists, show a dialog and do not submit
          this.dialogService.open(ShowcaseDialogComponent, {
            context: {
              title: 'Test Submission Not Allowed',
              message: 'You have already participated in this test. You cannot submit the test again.',
            },
            closeOnBackdropClick: false,
            closeOnEsc: false,
          });
        }
      },
      error: (err) => {
        this.toastrService.show('Failed to check test existence', 'Error', {status: 'danger'});
        console.error('Error checking test existence:', err);
      },
    });
  }


  submitTest() {
    // Check if the test has already been submitted
    this.testService.canUpdateTest(this.candidatId, this.currentTestType).subscribe({
      next: (exists) => {
        if (exists) {
          // If the test already exists, show a dialog and do not submit
          // Convert score from string to integer
          const score = parseInt(this.calculateScore(), 10);
          const testAccepted = score >= 80;
          const testDetails: Test = {
            ...this.testForm.value,
            type: this.currentTestType,
            nomTest: this.testDetails.nomTest,
            candidatId: this.candidatId,
            isTestPassed: true,
            pdfUrl: this.testDetails.pdfUrl,
            dureeTest: `${this.minutes.nativeElement.innerText}:${this.seconds.nativeElement.innerText}`,
            questions: this.testForm.value.questions.map((q, index) => ({
              ...q,
              responses: this.testDetails.questions[index].responses,

            })),
            score: this.calculateScore(),
            testAccepted: testAccepted,
          };

          // Update the shared service with new test details
          this.testService.updateTestDetails(testDetails);

          // Submit the test details
          this.testService.updateTest(this.candidatId, this.currentTestType, testDetails).subscribe({
            next: () => {
              this.stopTimer();

              this.generatePDF().then(pdfBlob => {
                this.uploadPdfAndSubmitDetails(pdfBlob);
              }).catch(error => {
                console.error('PDF Generation Error:', error);
                this.toastrService.show('Failed to generate PDF', 'Error', { status: 'danger' });
              });

              this.dialogService.open(ShowcaseDialogComponent, {
                context: {
                  title: 'Test Submitted Successfully',
                  message: 'Thank you for completing the test. Your responses have been successfully submitted. We will notify you of the next steps after your test has been evaluated.',
                },
                closeOnBackdropClick: false,
                closeOnEsc: false,
              });
              this.toastrService.show('Test submitted successfully', 'Success', {status: 'success'});
            },
            error: (err) => {
              this.toastrService.show('Failed to submit test', 'Error', {status: 'danger'});
            },
          });
        } else {
          this.dialogService.open(ShowcaseDialogComponent, {
            context: {
              title: 'Test Submission Not Allowed',
              message: 'You have already participated in this test. You cannot submit the test again.',
            },
            closeOnBackdropClick: false,
            closeOnEsc: false,
          });
          // If the test does not exist, create the test details and submit
        }
      },
      error: (err) => {
        this.toastrService.show('Failed to check test existence', 'Error', {status: 'danger'});
        console.error('Error checking test existence:', err);
      },
    });
  }


  setQuestion(key: string, value: string) {
    this.testForm.get(key).setValue(value);
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.testEnded();
    }
  }

  testEnded() {
    this.stopTimer();
    this.dialogService.open(ShowcaseDialogComponent, {
      context: {
        title: 'End of test session',
        message: 'The test session was interrupted because you navigated away from the test page. Thank you for your participation.',
      },
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });

    this.testService.canUpdateTest(this.candidatId, this.currentTestType).subscribe({
      next: (exists) => {
        if (exists) {
          // If the test does not exist, create the test details and submit
          const testDetails: Test = {
            ...this.testForm.value,
            type: this.currentTestType,
            candidatId: this.candidatId,
            isTestPassed: true,
            dureeTest: `${this.minutes.nativeElement.innerText}:${this.seconds.nativeElement.innerText}`,
            questions: this.testForm.value.questions.map((q, index) => ({
              ...q,
              responses: this.testDetails.questions[index].responses,

            })),
            score: this.calculateScore(),
          };

          this.testService.updateTestDetails(testDetails);

          this.testService.updateTest(this.candidatId, this.currentTestType, testDetails).subscribe({
            next: () => {
              this.generatePDF();
              this.toastrService.show('Test submitted successfully', 'Success', {status: 'success'});
            },
            error: (err) => {
              this.toastrService.show('Failed to submit test', 'Error', {status: 'danger'});
            },
          });
        } else {
          // If the test already exists, show a dialog and do not submit
          this.dialogService.open(ShowcaseDialogComponent, {
            context: {
              title: 'Test Submission Not Allowed',
              message: 'You have already participated in this test. You cannot submit the test again.',
            },
            closeOnBackdropClick: false,
            closeOnEsc: false,
          });
        }
      },
      error: (err) => {
        this.toastrService.show('Failed to check test existence', 'Error', {status: 'danger'});
        console.error('Error checking test existence:', err);
      },
    });
  }


  stopTimer() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  calculateScore() {
    const questions = this.testForm.get('questions') as FormArray;
    let correctAnswersCount = 0;

    questions.controls.forEach((question, index) => {
      const correctResponseIndex = this.testDetails.questions[index].correctResponseIndex;
      const candidateResponse = question.get('responseCandidat').value;

      // Obtenez la réponse correcte en utilisant correctResponseIndex
      const correctResponseText = this.testDetails.questions[index].responses[correctResponseIndex].responseText;

      // Vérifiez si la réponse du candidat correspond à la réponse correcte
      if (candidateResponse === correctResponseText) {
        correctAnswersCount++;
      }
    });

    const score = (correctAnswersCount / questions.controls.length) * 100;
    return Math.round(score) + '%';  // Retourne le score en pourcentage sans virgule
  }


  generatePDF(): Promise<Blob> {
    return new Promise((outerResolve, outerReject) => {
    const DATA = document.getElementById('htmlData');
    const title = this.currentTestType + ' Knowledge Test';
    const imageUrl = '/assets/images/logo/Manajero-logo.png'; // Update the path if necessary

    // Load the image
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          createPDF(base64data); // Call function to create PDF after the image is loaded
        };
      });

      const type = this.currentTestType;
      const candidateId = this.candidatId; // You can use any unique identifier like candidate name
      const pdfFilename = `TestResponses_${type}_${candidateId}.pdf`;

    function createPDF(imgDataLogo) {
      html2canvas(DATA, {
        backgroundColor: 'rgb(0, 14, 44)',
        scale: 1,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');

        pdf.setFillColor(0, 14, 44);
        pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), 'F');
        pdf.setTextColor(255, 255, 255);
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'bold');

        const titleWidth = pdf.getStringUnitWidth(title) * pdf.getFontSize() / pdf.internal.scaleFactor;
        const titleX = (pdf.internal.pageSize.getWidth() - titleWidth) / 2;
        pdf.text(title, titleX, 20);

        // Add the logo to the PDF
        pdf.addImage(imgDataLogo, 'PNG', 5, 3, 20, 20); // Position and size of the logo

        const contentWidth = pdf.internal.pageSize.getWidth() - 40;
        const contentHeight = canvas.height * (contentWidth / canvas.width);

        if (contentHeight + 40 > pdf.internal.pageSize.getHeight()) {
          const startY = 50; // Adjust for content below the logo
          pdf.addImage(imgData, 'PNG', 20, startY, contentWidth, contentHeight);
        } else {
          pdf.addImage(imgData, 'PNG', 20, 40, contentWidth, contentHeight);
        }
        pdf.save(pdfFilename);

        const dataURI = pdf.output('datauristring');

        // Convert dataURI to Blob
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        outerResolve(new Blob([ia], { type: mimeString }));
      }).catch(err => outerReject(err));
    } // End of createPDF function
  }); // End of Promise
  } // End of generatePDF function



  uploadPdfAndSubmitDetails(pdfBlob: Blob) {
    const type = this.currentTestType;
    const candidateId = this.candidatId; // You can use any unique identifier like candidate name
    const pdfFilename = `TestResponses_${type}_${candidateId}.pdf`;

    const file = new File([pdfBlob], pdfFilename, { type: 'application/pdf' });
    this.testService.uploadPdf(this.candidatId, this.currentTestType, file).subscribe(
      pdf => {
        this.updateTestDetails(pdf);
        this.toastrService.show('Test submitted successfully', 'Success', { status: 'success' });
      },
      error => {
        console.error('Error uploading PDF:', error);
      },
    );
  }


  updateTestDetails(pdf: string) {
    const testDetails = {
      ...this.testForm.value,
      pdf: this.testDetails.pdfUrl,
    };

    this.testService.updateTest(this.candidatId, this.currentTestType, testDetails).subscribe(() => {
    });
  }










  /*startTest() {
    const testInterrupted = localStorage.getItem('testStatus');
    if (testInterrupted) {
      this.endTest();
    } else {
      localStorage.setItem('testStatus', 'started');
      // Initialiser le timer du test ou d'autres logiques ici
    }
  }

  endTest() {
    this.dialogService.open(ShowcaseDialogComponent, {
      context: {
        title: 'Fin de la session de test',
        message: 'Votre session de test a été terminée car vous avez navigué hors de la page ou rafraîchi la page. Pour des raisons de sécurité, vous ne pouvez pas reprendre le test.',
      },
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }

  checkTestStatus() {
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('testStatus', 'interrupted');
    });
  }*/
}
