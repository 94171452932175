<div class="row">
  <div class="col-md-3 col-sm-12">
    <h3>
      Archived Documents
    </h3>
  </div>
  <div class="col-md-5">
    <input class="" fullWidth [(ngModel)]="searchbyfields"  nbTooltip="Search Document" nbTooltipStatus="primary"  type="text"  fieldSize="large" nbInput placeholder="Search Document">
  </div>
  <div class="col-md-4 col-sm-8 mt-1">
    <br>
  </div>



</div>
<br>


<div class="col-md-12">
  <div class="row justify-content-center">
  <div class="col-md-9">

    <div class="row" *ngIf="!files || files.length === 0">
      <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
    </div>
    <div  [hidden]="files.length === 0" class="row" *ngIf="(files | filter : searchbyfields).length === 0">
      <nb-alert outline="danger" class="w-100 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
    </div>

    <div class="row">



      <table class="table mb-0">
        <thead class="bg-light">
        <tr>
          <th>Document</th>
          <th>Date</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let file of files | filter: searchbyfields | paginate: { itemsPerPage: 10, currentPage: p }">
          <td><p>{{ file.name }}</p></td>
          <td><p>{{ file.archivedAt | date:'dd/MM/yyyy HH:mm:ss' }}</p></td>
          <td>
            <button (click)="downloadFile(file.id, file.name, $event)" status="primary" nbButton shape="round">
              <nb-icon icon="download-outline"></nb-icon>
            </button>

          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>


    </div>
  <div class="mt-4 d-flex justify-content-end">
    <nb-card class="" style="height: 35px;margin-left: 0; border-radius: 25px;">
      <pagination-controls class="pt-1" (pageChange)="p = $event"></pagination-controls>
    </nb-card>
  </div>
  </div>

