import {Component, OnInit} from '@angular/core';
import { saveAs } from 'file-saver';
import {FileServiceService} from '../../../../services/recruitment/FileService/file-service.service';
@Component({
  selector: 'ngx-history-documents',
  templateUrl: './history-documents.component.html',
  styleUrls: ['./history-documents.component.scss'],
})
export class HistoryDocumentsComponent implements OnInit {
  searchbyfields: string = '';
  files: any[] = [];
  p: number = 1; // Current page number
  constructor(private fileService: FileServiceService) { }

  ngOnInit(): void {
    this.fileService.getFiles().subscribe(files => {
      this.files = files;
    });
  }

  downloadFile(id: string, filename: string, event: MouseEvent): void {
    event.preventDefault();
    this.fileService.downloadFile(id).subscribe(data => {
      saveAs(data, filename);
    }, error => {
      console.error('Download failed:', error);
    });
  }


}
