import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrConfig, NbToastrService } from '@nebular/theme';
import { TokenStorageService } from '../../../../auth/service/token/token.service';
import { Department } from '../../../models/Department';
import { Employee } from '../../../models/Employee';
import { Level } from '../../../models/Level';
import { DepartmentService } from '../../../services/departmentservices/department.service';
import { EmployeeService } from '../../../services/employeeServices/employee.service';
import { LevelService } from '../../../services/levelServices/level.service';
import {GlobalBudgetServiceService} from '../../../services/globalBudgetService/global-budget-service.service';

@Component({
  selector: 'ngx-create-department',
  templateUrl: './create-department.component.html',
  styleUrls: ['./create-department.component.scss'],
})
export class CreateDepartmentComponent implements OnInit {

  config: NbToastrConfig;
  title = 'Create Department';
  content = 'Operation achieved, reload your page';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  totalBudget: number = 0;

  @Input() d: Department;
  @Output() addDep = new EventEmitter<Department>();

  dept: Department = new Department();
  listDepts: Department [] = [];
  listEmps: Employee[] = [];
  // listLvls: Level [] = [];
  selectedItem: any [] = [];
  employeeSelected: string = '';
  emp: Employee = new Employee();
  // levelSelected: string = '';
  // level_: Level = new Level();
  department_form: FormGroup;
  user: any;



  constructor( private tokenStorageService: TokenStorageService,
               private ls: LevelService, private fb: FormBuilder,
               private es: EmployeeService, private ds: DepartmentService,
               private router: Router, private toastrService: NbToastrService,
               private globalBudgetService: GlobalBudgetServiceService) {
    this.department_form = this.fb.group({
      budget: ['', [Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$')]],
      departmentReference : [''],
      departmentName :  [''],
      pourcentageBudget: ['', [Validators.required, Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$')]], // Ensures only numbers, with up to two decimal places
     // employee:[''],
    });
  }

  onDepartmentFormSubmit() {
    this.department_form.markAsDirty();
  }

  ngOnInit(): void {
    this.loadBudgets();
    this.loadDataDepts();
    this.loadDataEmps();
    this.user = this.tokenStorageService.getUser();

    this.department_form.get('budget').valueChanges.subscribe(value => {
      this.onBudgetChange(value);
    });
    this.department_form.get('pourcentageBudget').valueChanges.subscribe(value => {
      this.onPercentageChange(value);
    });
  }
  loadBudgets() {
    this.globalBudgetService.findAllGlobalBudget().subscribe({
      next: (budgets) => {
        if (budgets.length > 0) {
          const budget = budgets[0];
          this.totalBudget = budget.totalBudget;
          console.log('Total Budget Loaded: ', this.totalBudget);
        }
      },
    });
  }

  onPercentageChange(value: string) {
    if (value) {
      this.department_form.get('budget').disable({ emitEvent: false });
    } else {
      this.department_form.get('budget').enable({ emitEvent: false });
    }
  }

  onBudgetChange(value: string) {
    if (value) {
      this.department_form.get('pourcentageBudget').disable({ emitEvent: false });
    } else {
      this.department_form.get('pourcentageBudget').enable({ emitEvent: false });
    }
  }

  storeDept() {
    // Récupère les valeurs entrées pour le pourcentage et le budget
    const percentage = parseFloat(this.department_form.get('pourcentageBudget').value);
    const budget = parseFloat(this.department_form.get('budget').value);
    const deptName = this.department_form.get('departmentName').value; // Assure-toi que le nom du département est dans le formulaire

    // Vérifie si un département avec le même nom existe déjà
    this.ds.departmentExists(deptName).subscribe((exists: boolean) => {
      if (exists) {
        this.showToast('danger', 'FAILURE', `Cannot add department. The name "${deptName}" is already used.`);
        return;
      }

    // First check if a valid budget was entered
    if (!isNaN(budget) && budget > 0) {
      this.dept.budget = budget;  // Directly use the budget provided by the user
      this.dept.pourcentageBudget = (budget / this.totalBudget) * 100;  // Calculate the percentage from the budget
      console.log('Total Budget Loaded: ', this.totalBudget);

    } else if (!isNaN(percentage) && percentage > 0) {
      this.dept.budget = this.totalBudget * (percentage / 100);  // Calculate the budget from the percentage
      this.dept.pourcentageBudget = percentage;  // Use the percentage provided by the user
    } else {
      // Handle cases where neither input is valid
      this.dept.budget = 0;
      this.dept.pourcentageBudget = 0;
    }

    // this.level_.id = this.levelSelected;
    // this.dept.level = this.level_;
    this.dept.user = this.user.email;

    this.ds.getTotalPercentage().subscribe(totalPercentage => {
      const currentTotalBudget = this.totalBudget * (totalPercentage / 100); // Current total budget used by all departments
      const newTotalBudget = currentTotalBudget + this.dept.budget; // New total with the addition of the current dept budget
      if (newTotalBudget > this.totalBudget) {
        // If the total budget exceeds the limit after adding this department's budget
        this.showToast('danger', 'FAILURE', `Cannot add department. Only ${this.totalBudget - currentTotalBudget} budget left.`);
      } else if (totalPercentage + percentage > 100) {
        this.showToast('danger', 'FAILURE', `Cannot add department. Only ${100 - totalPercentage}% budget left.`);
      } else if (newTotalBudget === this.totalBudget) {
        this.dept.budget = budget;
        this.ds.addDepartment(this.dept).subscribe(
          (res) => {
            this.showToast('success', 'COMPLETE', 'Department added successfully, and the total budget is now fully allocated.');
            this.department_form.reset();
            this.router.navigate(['/hr/department/list']);
          }, (err) => {
            console.error(err);
            this.showToast('danger', 'FAILURE', 'Could not create department despite budget fitting perfectly.');
          },
        );
      } else if (totalPercentage + percentage === 100) {
        this.dept.pourcentageBudget = percentage;
        this.ds.addDepartment(this.dept).subscribe(
          (res) => {
            this.showToast('success', 'COMPLETE', 'Department added successfully, and the total budget is now fully allocated.');
            this.department_form.reset();
            this.router.navigate(['/hr/department/list']);
          }, (err) => {
            console.error(err);
            this.showToast('danger', 'FAILURE', 'Could not create department despite budget fitting perfectly.');
          },
        );
      } else {
        // It's important to set the department's percentage budget also as a number

        this.ds.addDepartment(this.dept).subscribe(
          (res) => {
            this.showToast('success', 'SUCCESS', 'Created Successfully');
            this.department_form.reset();
            this.router.navigate(['/hr/department/list']);
          }, (err) => {
            console.error(err);
            this.showToast('danger', 'FAILURE', 'Could not create department');
          },
        );
      }
    });
    });
  }

  getEmployeeSelected(event) {
    this.employeeSelected = event;
  }
  /* getLevelSelected(event) {
    this.levelSelected = event;
  } */
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? ` ${title}` : '';
    this.toastrService.show(
      body,
      ` ${titleContent}`,
      config);
  }

  loadDataDepts() {
    this.ds.findAllDepts().subscribe(
      (data: Department[]) => {
        this.listDepts = data;
      } , (err) => {
          console.log(err);
      },
    );
  }

  /* loadDataLvls() {
    this.ls.findAll().subscribe(
      (data: Level[]) => {
        this.listLvls = data;
      } , (err) => {
          console.log(err);
      },
    );
  } */

  loadDataEmps() {
    this.es.findAllEmployeesAsc().subscribe(
      (data: Employee[]) => {
        this.listEmps = data.filter((e) => e.roleEmployee === 'Manager');
      } , (err) => {
          console.log(err);
      },
    );
  }
  get departmentReference() {
    return this.department_form.get('departmentReference');
  }
  get departmentName() {
    return this.department_form.get('departmentName');
  }
  get subdepartments() {
    return this.department_form.get('subdepartments');
  }

  get employee() {
    return this.department_form.get('employee');
  }

  get level() {
    return this.department_form.get('level');
  }

  toggle(event) {
    this.selectedItem.push(event);
  }




}
