import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from '@nebular/theme';
import {AuthService} from '../../service/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  formResetPWD: FormGroup;
  config: NbToastrConfig;
  current: number = 1;
  search: string = '';
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';
  token: string;
  showPassword = false;
  showcfPassword = false;
  passwordStrength: number = 0;
  passwordStrengthText: string = '';
  positions = NbGlobalPhysicalPosition;
  passwordTouched: boolean = false;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router, private toastrService: NbToastrService,
    private route: ActivatedRoute,
  ) {
  }

  get password() {
    return this.formResetPWD.get('password');
  }

  get cfPassword() {
    return this.formResetPWD.get('cfPassword');
  }

  ngOnInit(): void {
    const formcontrols = {
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/),
      ]),
      cfPassword: new FormControl('', [Validators.required]),
    };
    this.formResetPWD = this.fb.group(formcontrols, {
      validator: this.ConfirmedValidator('password', 'cfPassword'),
    });

    this.route.params.subscribe((params) => {
      this.token = params['token'];
    });
    this.password.valueChanges.subscribe(value => {
      this.calculatePasswordStrength(value);
    });

    console.log(this.token);
  }


  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  resetPWD() {
    const data = this.formResetPWD.value;
    console.log(data);
    this.authService.resetPWD(data, this.token).subscribe({
      next: () => {
        this.showToast('success', 'Success',
          'Password Updated Successfully');
        setTimeout(() => {
          this.router.navigate(['/auth/login']);
        }, 2000); // Ajustez le délai selon vos besoins
      },
    });
  }
  cancelReset() {
    this.router.navigate(['/auth/login']);
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    console.log(this.showPassword);
  }

  toggleShowCfPassword() {
    this.showcfPassword = !this.showcfPassword;
    console.log(this.showcfPassword);
  }

  calculatePasswordStrength(password: string) {
    this.passwordTouched = true;
    if (!password) {
      this.passwordStrength = 0;
      this.passwordStrengthText = 'Password is required';
      return;
    }
    let strengths = 0;
    if (/[A-Z]/.test(password)) strengths++;
    if (/[a-z]/.test(password)) strengths++;
    if (/\d/.test(password)) strengths++;
    if (/[!@#$%^&*]/.test(password)) strengths++;
    if (password.length >= 8) strengths++;

    // Mettre à jour la force du mot de passe
    this.passwordStrength = (strengths / 5) * 100;

    // Mettre à jour le texte de la force du mot de passe
    if (strengths < 3) {
      this.passwordStrengthText = 'Weak';
    } else if (strengths >= 3 && strengths < 5) {
      this.passwordStrengthText = 'Medium';
    } else if (strengths === 5) {
      this.passwordStrengthText = 'Strong';
    }
  }
  getPasswordStrengthColor(password: string) {
    this.passwordTouched = true;
    if (!password) {
      return '#000000'; // Gris par défaut ou toute autre couleur de votre choix
    }
    let strengths = 0;
    if (/[A-Z]/.test(password)) strengths++;
    if (/[a-z]/.test(password)) strengths++;
    if (/\d/.test(password)) strengths++;
    if (/[!@#$%^&*]/.test(password)) strengths++;
    if (password.length >= 8) strengths++;

    if (strengths < 3) {
      return 'red';
    } else if (strengths >= 3 && strengths < 5) {
      return 'orange';
    } else if (strengths === 5) {
      return 'green';
    }
  }
}
