import { Component, OnInit } from '@angular/core';
import {Pay} from '../../../models/Pay';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from '@nebular/theme';
import {PayService} from '../../../services/payService/pay.service';
import {Employee} from '../../../models/Employee';
import {Department} from '../../../models/Department';
import {EmployeeService} from '../../../services/employeeServices/employee.service';
import {DepartmentService} from '../../../services/departmentservices/department.service';
import { ExportService } from '../../../../../shared/exports/export.service';
import {NgxPrintElementService} from 'ngx-print-element';
import {forkJoin} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import {SubDepartment} from '../../../models/SubDepartment';
import {SubDepartmentService} from '../../../services/departmentservices/subDepartmentService/sub-department.service';

@Component({
  selector: 'ngx-pay-table',
  templateUrl: './pay-table.component.html',
  styleUrls: ['./pay-table.component.scss'],
})
export class PayTableComponent implements OnInit {

  pay: Pay;
  list: Pay[] = [];
  employees: Employee[] = [];
  departments: Department[] = [];
  current = 1;
  search: string = '';
  pageSize = 11;
  config: NbToastrConfig;
  title = 'Data Loaded Successfuly';
  content = 'Operation achieved';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  loading = false;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  listd: SubDepartment[] = [];
  myDate = new Date().toDateString();
  NotAssignedYet: string = 'Not Assigned';
  public config_ = {
    printMode: 'template-popup',
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'Payroll Data',
    templateString: `<header> <h1>PayRoll Data \n : Date ${this.myDate} </h1></header>{{printBody}}`,
    stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],
    styles: ['.table { color: black; }', '.table td { color: black; }' , '.table .printbtn {height:25px;width:25px;}'],
  };

  constructor(private exportService: ExportService, private es: EmployeeService,
          private ps: PayService, private toastrService: NbToastrService, public print: NgxPrintElementService,  private ds: SubDepartmentService ) { }

  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.list, 'list');
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? ` ${title}` : '';
    this.toastrService.show(
      body,
      ` ${titleContent}`,
      config);
  }

  ngOnInit(): void {
    this.loading = true; // Start loading

    forkJoin({
      payData: this.ps.findAll(),
      employees: this.es.findAll(),
      subDepartments: this.ds.findAll() , // Assuming findAllDepts returns Observable<Department[]>
    }).pipe(
      finalize(() => this.loading = false), // Stop loading regardless of result
    ).subscribe({
      next: (results) => {
        this.list = results.payData;
        this.employees = results.employees;
        this.listd = results.subDepartments;
        this.showToast('success', 'SUCCESS', 'All data loaded successfully');
      },
      error: (error) => {
        console.error('Failed to load data', error);
        this.showToast('danger', 'ERROR', 'Failed to load data');
      },
    });
  }
  getData() {
    this.ps.findAll().subscribe(
      (data: Pay[]) => {this.list = data;
        this.showToast('success', 'SUCESS', 'Data Loaded Successfuly');
          },
      (err) => {      this.showToast('danger', 'DANGER', 'Error While Retrieving Data'); },
    );
  }

  getEmployees() {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        this.employees = data;
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }

  /*getDepartments() {
    this.ds.findAllDepts().subscribe(
      (data: Department[]) => {
        this.departments = data;
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }*/
  filterByEmployee(e) {
    this.ps.findAll().subscribe(
      (data: Pay[]) => {
        this.list = [];
        this.list = data.filter(
          (d =>
              // @ts-ignore
              d?.contract.employee?.id === e
          ),
        );
        this.showToast('success', 'SUCCESS', 'You have launched a filtering operation');
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }

  filterBySubDepartment(e) {
    this.ps.findAll().subscribe(
      (data: Pay[]) => {
        this.list = [];
        this.list = data.filter(
          (d =>
              // @ts-ignore
              d?.contract?.employee?.subDepartment?.id === e
          ),
        );
        this.showToast('success', 'SUCCESS', 'You have launched a filtering operation');
      }, (err) => {
        this.showToast('danger', 'DANGER', err.data);
      });
  }


  getAscMonthlyCost() {
    this.list = this.list.sort(
      (a, b) =>
        a?.contract?.overallMonthlyCost - b?.contract?.overallMonthlyCost,
    );
  }

  getDescMonthlyCost() {
    this.list = this.list.sort(
      (a, b) =>
        b?.contract?.overallMonthlyCost - a?.contract?.overallMonthlyCost,
    );
  }

  getAscGain() {
    this.list = this.list.sort(
      (a, b) =>
        a?.gain - b?.gain,
    );
  }

  getDescGain() {
    this.list = this.list.sort(
      (a, b) =>
        b?.gain - a?.gain,
    );
  }

  getAscNet() {
    this.list = this.list.sort(
      (a, b) =>
        a?.netSalary - b?.netSalary,
    );
  }

  getDescNet() {
    this.list = this.list.sort(
      (a, b) =>
        b?.netSalary - a?.netSalary,
    );
  }

}
