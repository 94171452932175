import { Injectable } from '@angular/core';

import {environment} from '../../../../../environments/environment';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';

import {Notifications} from '../../models/Notifications';
import {tap} from 'rxjs/operators';




@Injectable({

  providedIn: 'root',

})

export class NotificationService {
  private notifications = new BehaviorSubject<Notifications[]>([]);
  public notifications$ = this.notifications.asObservable();
  psUrl = environment.employees_hcm;

  httpOptions = {

    headers: new HttpHeaders({

      'Content-Type': 'application/json',

    }),

  };

  constructor(private http: HttpClient) { }

  addNotification(notif: Notifications): Observable<Notifications> {

    return this.http.post<Notifications>(`${this.psUrl}api/v1/Notif/add`, notif, this.httpOptions);

  }




  getAllNotif(): Observable<Notifications[]> {

    return this.http.get<Notifications[]>(`${this.psUrl}api/v1/Notif/all`, this.httpOptions);

  }




  update(notif: Notifications): Observable<Notifications> {

    return this.http.put<Notifications>(`${this.psUrl}api/v1/Notif/update`, notif , this.httpOptions);

  }




  delete(id: String): Observable<void> {

    return this.http.delete<void>(`${this.psUrl}api/v1/Notif/delete/${id}`, this.httpOptions);

  }


  refreshNotifications() {
    this.getAllNotif().subscribe(notifications => this.notifications.next(notifications));
  }

  getById(id: String ): Observable<Notifications> {

    return this.http.get<Notifications>(`${this.psUrl}api/v1/Notif/GetById/${id}`);

  }




  deleteAll(): Observable<void> {

    return this.http.delete<void>(`${this.psUrl}api/v1/Notif/deleteall/`, { responseType: 'text' as 'json' }).pipe(tap(() => this.notifications.next([])));

  }




  // deleteAllNotifications(): Observable<any> {

  //   return this.http.delete<any>(this.apiUrl);

  // }

}

