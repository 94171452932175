<div class="notification-dialog">
  <nb-card class="notification-card">
    <nb-card-header>
      <nb-icon icon="bell-outline" class="header-icon"></nb-icon>
      <span class="header-title">Notifications</span>
    </nb-card-header>

    <nb-card-body class="notification-body">
      <nb-list>
        <nb-list-item *ngFor="let notification of notifications">

          <div class="notification-content">
            <nb-icon icon="bell-outline" class="indicator"></nb-icon>
            <div class="notification-text">
              <p class="notification-date">{{ notification.date | date: 'dd/MM/yyyy' }}</p>
              <p class="notification-title">{{ notification.title }}</p>
              <p class="notification-body">{{ notification.body }}</p>
            </div>

          </div>
        </nb-list-item>
      </nb-list>
    </nb-card-body>

    <nb-card-footer class="footer-center">
      <button nbButton status="primary" (click)="onDeleteAllConfirm()">View All</button>
    </nb-card-footer>
  </nb-card>
</div>
