<div class="row">
  <div class="col-md-3">
    <h2>Organigram</h2>
  </div>
  <div class="col-md-8"></div>
  <div class="col-md-1">
    <button (click)="openPDF()" nbTooltip="Export this organigram to pdf file" nbTooltipStatus="primary" nbButton status="danger">
      <i class="fas fa-file-pdf text-white"></i>
    </button>
  </div>
  <div class="ml-auto mr-auto" align="center">
    <div class="pg-orgchart">
      <div class="org-chart" id="htmlData">
        <ul>
          <li class="department">
            <div class="user bg-primary" style="border: 1px solid; border-color: blue;">
              <div class="row">
                <div class="col-md-12">
                  <nb-icon class="text-center text-white" icon="home-outline"></nb-icon>
                </div>
                <div class="col-md-12">
                  <p class="text-center">CEO</p>
                </div>
              </div>
              <small class="text-white">General Director</small>
            </div>
            <div class="line-vertical"></div>
            <ul>
              <li *ngFor="let department of list" [hidden]="department.isArchived ==='Yes'" class="department">
                <div (click)="navigateToDepartment(department.id)" class="user row bg-primary clickable" style="border: 1px solid; border-color: blue;">
                  <div class="row">
                    <div class="col-md-12">
                      <nb-icon class="text-center text-white" icon="home-outline"></nb-icon>
                    </div>
                    <div class="col-md-12">
                      <p class="text-center">{{department.departmentName}}</p>
                    </div>
                  </div>
                </div>

                <ul>
                  <li *ngFor="let sub of subList" [hidden]="department?.departmentName !== sub?.department?.departmentName" class="subdepartment">
                    <div (click)="navigateToSubDepartment(sub.id)" class="sub bg-primary clickable" style="border-radius: 15px;">
                      <div class="row">
                        <div class="col-md-12">
                          <p class="pt-2 mx-4">{{sub.subDepartmentName}}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
