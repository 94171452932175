<nb-layout xmlns:color="http://www.w3.org/1999/xhtml" xmlns="http://www.w3.org/1999/html">

  <nb-layout-column>

    <div class="background">
      <div class="shape"></div>
      <div class="shape"></div>
    </div>
    <form [formGroup]="formResetPWD">

      <tr>
        <img src="assets/images/logo/Manajero-logo.png" alt="" style="width: 40%; margin-left: 120px; " />
      </tr>
      <tr>
        <h4 style="color: #fcfcfc; margin-left: 80px; ">Reset password</h4>
      </tr>
      <label>Password</label>
      <div class="password-input d-flex align-items-center" style="margin-bottom: 10px;">
        <br>
        <input type="{{ showPassword ? 'text' : 'password' }}" placeholder="Enter your password" formControlName="password" (input)="calculatePasswordStrength(password.value)">
        <span>
            <svg *ngIf="!showPassword" (click)="toggleShowPassword()" xmlns="http://www.w3.org/2000/svg" width="16"
                 height="16" fill="#083570" class="bi bi-eye-fill" viewBox="0 0 16 16" style="cursor: pointer">
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
            </svg>
            <svg *ngIf="showPassword" (click)="toggleShowPassword()" xmlns="http://www.w3.org/2000/svg" width="16"
                 height="16" fill="#083570" class="bi bi-eye-slash-fill" viewBox="0 0 16 16" style="cursor: pointer">
              <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
              <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
            </svg>
          </span>
      </div>
      <div *ngIf="passwordTouched && password.value.length > 0">
        <div class="password-strength-meter">
          <div class="password-strength" [style.width]="passwordStrength + '%'" [style.backgroundColor]="getPasswordStrengthColor(password.value)"></div>
        </div>
      </div>
      <div *ngIf="passwordTouched">
        <div class="password-strength-text" [style.color]="getPasswordStrengthColor(password.value)">
          {{ passwordStrengthText }}
        </div>
      </div>

      <div *ngIf="password.touched && password.invalid" class="mt-2" >
        <p class="caption status-danger" *ngIf="password.errors?.required">
        </p>
        <p class="caption status-danger" *ngIf="password.errors?.pattern">
        </p>
      </div>

      <div class="password-input d-flex align-items-center">
        <br>
        <input type="{{ showcfPassword ? 'text' : 'password' }}" placeholder="Confirm your password" formControlName="cfPassword">
        <span>
            <svg *ngIf="!showcfPassword" (click)="toggleShowCfPassword()" xmlns="http://www.w3.org/2000/svg" width="16"
                 height="16" fill="#083570" class="bi bi-eye-fill" viewBox="0 0 16 16" style="cursor: pointer">
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
            </svg>
            <svg *ngIf="showcfPassword" (click)="toggleShowCfPassword()" xmlns="http://www.w3.org/2000/svg" width="16"
                 height="16" fill="#083570" class="bi bi-eye-slash-fill" viewBox="0 0 16 16" style="cursor: pointer">
              <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
              <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
            </svg>
          </span>
      </div>
      <div *ngIf="cfPassword.errors?.confirmedValidator" class="mt-2">
        <p class="caption status-danger password-strength-text">
          Passwords must match!
        </p>
      </div>
      <div style="display: flex; justify-content: center; gap: 15px; margin-bottom: 30px;">
        <button (click)="resetPWD();" nbButton status="primary" [disabled]="formResetPWD.invalid" hero>
          Reset password
        </button>
        <button (click)="cancelReset()" nbButton status="danger" hero>
          Cancel
        </button>
      </div>
      <div>
        <p style="color: #7bc4ff;">Best practices for creating a strong password</p>
        <ul>
          <li style="color: #1a7ccc;">Use a combination of letters (upper and lower case), numbers and special characters</li>
          <li style="color: #1a7ccc;">Password must contain at least 8 characters</li>
          <li style="color: #1a7ccc;">Avoid using easy-to-guess information</li>
          <li style="color: #1a7ccc;">Use a unique password</li>
        </ul>
      </div>
      <!--<p><li class="nav-item"><a class="nav-link" style="color: rgb(0, 255, 208);" href="https://edu.gcfglobal.org/en/internetsafety/creating-strong-passwords/1/#">Password best practice</a></li></p>-->
    </form>
  </nb-layout-column>
</nb-layout>
