<div class="row">
    <div class="col-md-3 col-sm-12">
            <h3>
                Evaluation Data
            </h3>
    </div>
    <div class="col-md-4">
        <input class="" fullWidth [(ngModel)]="searchbyfields"  nbTooltip="Search Training" nbTooltipStatus="primary"  type="text"  fieldSize="large" nbInput placeholder="Search Evaluation">
    </div>
    <div class="col-md-5 col-sm-8 mt-1">
        <a nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" [routerLink]="['/hr/evaluation/grid']" nbButton status="primary"> <nb-icon icon="grid-outline"></nb-icon>   </a>
        &nbsp;
        <a nbTooltip="Visualize data on dimensions grid 3x3" nbTooltipStatus="primary" [routerLink]="['/hr/evaluation/keypad']" nbButton status="warning"> <nb-icon icon="keypad-outline"></nb-icon>   </a>
        &nbsp;
        <a [routerLink]="['/hr/evaluation/table']" nbTooltip="Visualize data in table" nbTooltipStatus="primary" nbButton status="basic"> <nb-icon icon="list-outline"></nb-icon>   </a>
        &nbsp;       &nbsp;
      <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" (click)="exportAsXLSX()"><i class="fas fa-file-excel text-white"></i>  </button>
        &nbsp;

        <a [routerLink]="['/hr/evaluation/create']" nbTooltip="Create Evaluation" nbTooltipStatus="primary" nbButton status="info">
            <nb-icon icon="plus-outline"></nb-icon>
            Create new
        </a>

          <br>
    </div>



</div>

<div class="row" *ngIf="loading">
  <nb-alert [nbSpinner]="true" nbSpinnerSize="giant" nbSpinnerStatus="primary" class="w-100 mt-3" style="background-color: transparent !important;"></nb-alert>
</div>
<div class="row" *ngIf="!loading && (!evaluations || evaluations.length === 0)">
  <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
</div>
<div [hidden]="evaluations.length === 0" class="row" *ngIf="(evaluations | filter : searchbyfields).length === 0">
  <nb-alert outline="danger" class="w-100 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
</div>

<div class="row">
  <div class="col-md-3 mt-3">
    <div class="row">
      <div class="col-lg-9">
        <nb-form-field>
          <nb-icon nbPrefix class="mt-1" icon="funnel-outline"></nb-icon>
          <nb-select selected="0" (selectedChange)="filterByEmployee($event)"  fullWidth>
            <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Employee</nb-option>
            <nb-option value="All" (click)="getEvaluations()"> &nbsp;&nbsp;&nbsp;All</nb-option>
            <nb-option *ngFor="let employee of employees" value="{{employee.id}}">
              &nbsp;&nbsp;&nbsp; <nb-user [name]="employee.employeeName" [picture]="employee.employeeProfileImage"></nb-user>
            </nb-option>

          </nb-select>
        </nb-form-field>

      </div>
    </div>

    <div class="row mt-3">
      <div class="col-lg-9">
        <nb-form-field>
          <nb-icon nbPrefix class="mt-1" icon="funnel-outline"></nb-icon>
          <nb-select (selectedChange)="filterByType($event)" selected="0" fullWidth>
            <nb-option  disabled value="0"> &nbsp;&nbsp;&nbsp;Evaluation Type</nb-option>
            <nb-option value="All" (click)="getEvaluations()"> &nbsp;&nbsp;&nbsp;All</nb-option>
            <nb-option  value="Evaluation#1"> &nbsp;&nbsp;&nbsp; Evaluation#1</nb-option>
            <nb-option  value="Evaluation#2"> &nbsp;&nbsp;&nbsp; Evaluation#2</nb-option>
            <nb-option  value="Evaluation#3"> &nbsp;&nbsp;&nbsp; Evaluation#3</nb-option>
            <nb-option  value="Evaluation#4"> &nbsp;&nbsp;&nbsp; Evaluation#4</nb-option>


          </nb-select>
        </nb-form-field>

      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <nb-form-field>
          <nb-icon nbPrefix class="mt-1" icon="flip-outline"></nb-icon>
          <nb-select selected="0" fullWidth>
            <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Limit Date</nb-option>
            <nb-option value="All" (click)="getEvaluations()"> &nbsp;&nbsp;&nbsp;All</nb-option>
            <nb-option (click)="getEvaluationsAsc()" value="Ascending">&nbsp;&nbsp;&nbsp; Ascending </nb-option>
            <nb-option (click)="getEvaluationsDesc()" value="Descending">&nbsp;&nbsp;&nbsp; Descending </nb-option>
          </nb-select>
        </nb-form-field>

      </div>
    </div>
  </div>
  <div class="col-md-9">
    <div class="row mt-3">
    <div class="table-responsive">
      <table class="table mb-3" id="evaluations" style="width: 100%; min-width: 900px;">
        <thead class="bg-light">
        <tr>
          <th>Employee</th>
          <th>Evaluation Type</th>
          <th>Limit Date</th>
          <th>Attachment</th>
          <!--<th class="print-none">Actions</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let evaluation of evaluations | filter : searchbyfields | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: evaluations.length}">
          <td>
            <div class="d-flex align-items-center">
              <nb-user [name]="evaluation?.employee?.employeeName" onlyPicture picture="{{evaluation?.employee?.employeeProfileImage}}"></nb-user>
              <div class="ms-3">
                <p class="mb-1">{{evaluation?.employee?.employeeName}}</p>
                <p class="mb-0">{{evaluation?.employee?.employeeEmail}}</p>
              </div>
            </div>
          </td>
          <td><p class="fw-normal mb-1">{{evaluation?.evaluationType}}</p></td>
          <td><p class="fw-normal mb-1">{{evaluation?.limitDate | date:'shortDate'}}</p></td>
          <td>
            <a nbButton size="small" shape="round" status="danger" href="{{evaluation?.attachmentEvaluation}}" target="_blank">
              <nb-icon icon="printer-outline"></nb-icon> View
            </a>
          </td>
          <!--<td class="print-none">
            <button class="printbtn" shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions menu">
              <nb-icon icon="more-horizontal-outline"></nb-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item >
                <span><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
              </button>
              <button mat-menu-item >
                <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>
              </button>
            </mat-menu>
          </td>-->
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="mt-4 d-flex justify-content-end">
    <nb-card class="" style="height: 35px;margin-left: 0; border-radius: 25px;">
      <pagination-controls class="pt-1 text-center" (pageChange)="current = $event"></pagination-controls>
    </nb-card>
  </div>
</div>

</div>
