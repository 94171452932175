<div class="row">
    <div class="col-md-6 col-sm-12">
      <div class="row">
        <h2 class="more-vertical">
          Release List <nb-icon nbTooltip="Click here to reload your data" nbTooltipStatus="primary" class="ion-loop ng-star-inserted text-primary"></nb-icon> </h2>
        <input class="w-50 mx-2"  nbTooltip="Fill this field to find department" nbTooltipStatus="primary" [disabled]="releases.length === 0" type="text"  fieldSize="large" nbInput placeholder="search by fields" [(ngModel)]="searchbyfields">
      </div>

    </div>
    <div class="col-md-6 col-sm-8">
      <a nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" [routerLink]="['/hr/release/grid']" nbButton status="primary"> <nb-icon icon="grid-outline"></nb-icon>   </a>
      &nbsp;
      <a nbTooltip="Visualize data on dimensions grid 3x3" nbTooltipStatus="primary" [routerLink]="['/hr/release/keypad']" nbButton status="warning"> <nb-icon icon="keypad-outline"></nb-icon>   </a>
      &nbsp;
      <a [routerLink]="['/hr/release/table']" nbTooltip="Visualize data in table" nbTooltipStatus="primary" nbButton status="basic"> <nb-icon icon="list-outline"></nb-icon>   </a>
      &nbsp;
      <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" (click)="exportAsXLSX()" ><i class="fas fa-file-excel text-white"></i>  </button>
      &nbsp;



        <br>
  </div>

  <div class="row mt-2">
    <div class="col-md-3">
      <div class="row">
        <div class="col-lg-9">
          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
            <nb-select (selectedChange)="filterByEmployee($event)"
                       class="w-100 mt-3" selected="0">
              <nb-option  value="0" disabled>&nbsp;&nbsp;&nbsp; Employee</nb-option>
              <nb-option value="All" (click)="getReleases()"> &nbsp;&nbsp;&nbsp;All</nb-option>
              <nb-option *ngFor="let employee of employees" value="{{employee.id}}">
                &nbsp;&nbsp;&nbsp; <nb-user [name]="employee.employeeName" [picture]="employee.employeeProfileImage"></nb-user>
              </nb-option>

            </nb-select>
          </nb-form-field>
        </div>
        <div class="col-lg-9">
          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
            <nb-select selected="0" (selectedChange)="filterBySubDepartment($event)" class="w-100 mt-3">
              <nb-option  value="0" disabled>&nbsp;&nbsp;&nbsp; SubDepartment</nb-option>
              <nb-option value="All" (click)="getReleases()">&nbsp;&nbsp;&nbsp; All</nb-option>
              <nb-option *ngFor="let subdepartment of subDepartments" value="{{subdepartment.id}}">&nbsp;&nbsp;&nbsp; {{subdepartment.subDepartmentName}}</nb-option>
            </nb-select>
          </nb-form-field>
        </div>
        <div class="col-lg-9">

          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
            <nb-select   selected="0" (selectedChange)="filterByStatus($event)" class="w-100 mt-3">
              <nb-option  value="0" disabled>&nbsp;&nbsp;&nbsp; Status</nb-option>
              <nb-option  value="Pending" >&nbsp;&nbsp;&nbsp; Pending</nb-option>
              <nb-option  value="Validate" >&nbsp;&nbsp;&nbsp; Validate</nb-option>
              <nb-option  value="Rejected" >&nbsp;&nbsp;&nbsp; Rejected</nb-option>
            </nb-select>
          </nb-form-field>
        </div>
        <div class="col-lg-9">

          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
            <nb-select   selected="0" class="w-100 mt-3">
              <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Date</nb-option>
              <nb-option value="" (click)="getReleases()">&nbsp;&nbsp;&nbsp; All</nb-option>
              <nb-option value="" (click)="getReleasesAsc()">&nbsp;&nbsp;&nbsp; Ascending</nb-option>
              <nb-option value="" (click)="getReleasesDesc()">&nbsp;&nbsp;&nbsp; Descending</nb-option>
            </nb-select>
          </nb-form-field>
        </div>
        <div class="col-lg-9">
          <label class="mt-3">Items Per Page</label>
          <input class="w-100" [disabled]="releases?.length === 0" type="number" nbInput [(ngModel)]="pageSize" min="9">
        </div>

    </div>

    </div>
        <div class="col-md-9 mt-3">
            <div class="row mx-1">

              <div class="row w-100" *ngIf="loading">
                <nb-alert [nbSpinner]="true" nbSpinnerSize="giant" nbSpinnerStatus="primary" class="w-100 mt-6" style="background-color: transparent !important;"></nb-alert>
              </div>
              <div class="row w-100 mx-1" *ngIf="!loading && (!releases || releases.length === 0)">
                <nb-alert outline="danger" class="w-100 mt-3"> No Data Found.</nb-alert>
            </div>
            <div [hidden]="releases.length === 0" class="row w-100 mx-1" *ngIf="(releases | filter : searchbyfields).length === 0">
                <nb-alert outline="danger" fullWidth class="col-lg-12 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
            </div>
                <table class="table mb-0" >
                    <thead class="bg-light">
                        <th>Employee</th>
                        <th>Department</th>
                        <th>Motif</th>
                        <th>Status</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Date</th>
                        <th>Actions</th>
                    </thead>

                    <tr *ngFor="let release of releases | filter : searchbyfields | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: total}" [hidden]="release.releaseStatus==='Draft'">
                        <td>
                            <nb-user size="medium" [name]="release?.employee?.employeeName" [picture]="release?.employee?.employeeProfileImage"></nb-user>
                        </td>
                        <td>
                            <p class="mt-2">{{release?.employee?.subDepartment?.subDepartmentName}}</p>
                        </td>
                        <td>
                            <p class="mt-2"> {{release.motifRelease}}</p>
                        </td>
                        <td>

                          <p class="mt-2" *ngIf="release.releaseStatus === 'Pending'">
                            <nb-tag status="basic" [text]="release.releaseStatus"></nb-tag>
                          </p>
                          <p class="mt-2" *ngIf="release.releaseStatus === 'Validate'">
                            <nb-tag status="success" [text]="release.releaseStatus"></nb-tag>
                          </p>
                          <p class="mt-2" *ngIf="release.releaseStatus === 'Rejected'">
                            <nb-tag status="danger" [text]="release.releaseStatus"></nb-tag>
                          </p>
                      </td>
                        <td>
                            <p class="mt-2">{{release.startTime}}</p>
                        </td>
                        <td><p class="mt-2">{{release.endTime}}</p></td>
                        <td>
                            <p class="mt-2">{{release.createdAt | date : 'dd MMM YYYY'}}</p>
                        </td>
                        <td>
                            <button shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                          <nb-icon icon="more-horizontal-outline"></nb-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                          <a [routerLink]="['/hr/release/edit', release.id]" style="text-decoration:none" nbTooltip="Click here to edit data" nbTooltipStatus="primary">
                                            <button mat-menu-item>
                                              <span ><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
                                            </button>
                                          </a>
                                          <!--<a nbTooltip="Click here to archive data" nbTooltipStatus="primary">
                                            <button mat-menu-item>
                                              <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>
                                            </button>
                                          </a>-->

                                        </mat-menu>
                        </td>
                    </tr>

                </table>

            </div>
            <br>
          <div class="mt-4 d-flex justify-content-end">
            <nb-card class="" style="height: 35px;margin-left: 0; border-radius: 25px;">
              <pagination-controls class="pt-1" (pageChange)="current = $event"></pagination-controls>
            </nb-card>
          </div>

        </div>
  </div>
</div>
